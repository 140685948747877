/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { goToTop } from 'react-scrollable-anchor';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tab, Tabs, AppBar } from '@material-ui/core';
import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

// Styles
import {
  Container,
  MyAvatar,
  Name,
  Action,
  Divider,
  Section,
  Label,
  Fieldset,
} from './styles';

import { fetchProfileRequested } from '~/store/modules/profile/actions';
import SimpleModal from '~/components/Modal';
import { fetchCouponsRequested } from '~/store/modules/coupon/actions';
import { signOut } from '~/store/modules/auth/actions';
import { Centered, SimpleTable, SimpleHeader } from '~/components';
import { navigateTo } from '~/services/history';
import Loading from '~/assets/lottie/Loading';
import { fetchOrdersRequested } from '~/store/modules/order/actions';
import { toDinheiro } from '~/utils';

export default function Profile() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [openOrder, setOpenOrder] = useState(false);
  const [order, setOrder] = useState({});
  const { loading: loadingCoupon, errorCoupon, coupons } = useSelector(
    state => state.coupon
  );
  const { loading, profile } = useSelector(state => state.profile);
  const { profile: user } = useSelector(state => state.auth);
  const type = 'users';
  const { loading: loadingOrder, errorOrder, orders } = useSelector(
    state => state.order
  );

  const handleOpenOrder = () => {
    setOpenOrder(true);
  };

  const handleCloseOrder = () => {
    setOpenOrder(false);
  };

  const columns = [
    { title: 'Empresa', field: 'company_name' },
    { title: 'Produto', field: 'product_description' },
    { title: 'Código do Cupom', field: 'code' },
    { title: 'Valor', field: 'value' },
    { title: 'Status', field: 'status' },
  ];

  const columnsOrder = [
    { title: 'Numero', field: 'id' },
    { title: 'Empresa', field: 'company.fantasy' },
    { title: 'Valor', field: 'total' },
    { title: 'Status', field: 'status' },
  ];

  const RedButton = withStyles(() => ({
    root: {
      color: '#ffffff',
      backgroundColor: '#e32525',
      '&:hover': {
        backgroundColor: '#ca1616',
      },
    },
  }))(Button);

  const actions = [];

  useEffect(() => {
    dispatch(fetchProfileRequested(user.id));
    dispatch(fetchCouponsRequested(1, null, user.id, type));
    dispatch(fetchOrdersRequested(1, null, user.id, type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = 'Perfil';
    goToTop();
  }, []);

  const getInitials = value => {
    const [name, surname] = value.split(' ');

    return `${name[0]} ${surname ? surname[0] : ''}`;
  };

  const hasProfile = Object.keys(profile).length;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const actionsOrder = [
    {
      icon: 'search',
      tooltip: 'Detalhe do Pedido',
      onClick: (event, order) => {
        setOrder(order);
        handleOpenOrder();
      },
    },
  ];

  const renderDetalheOrder = () => {
    return (
      <>
        <di style={{ textAlign: 'center' }}>
          <h2>{order?.status}</h2>
          <p>Número do pedido: #{order?.id}</p>
        </di>
        <Fieldset>
          <Fieldset.legend>Cliente</Fieldset.legend>
          <Fieldset.gridContainer>
            <Fieldset.label>Nome</Fieldset.label>
            <Fieldset.label>Telefone</Fieldset.label>
            <Fieldset.label>E-mail</Fieldset.label>

            <Fieldset.text>{order?.user?.name}</Fieldset.text>
            <Fieldset.text>{order?.user?.phone}</Fieldset.text>
            <Fieldset.text>{order?.user?.email}</Fieldset.text>
          </Fieldset.gridContainer>
        </Fieldset>
        <Fieldset>
          <Fieldset.legend>Endereço</Fieldset.legend>

          <Fieldset.text
            style={{ padding: 10 }}
          >{`${order?.address?.street}, ${order?.address?.number} - ${order?.address?.neighborhood}, ${order?.address?.city} - MT`}</Fieldset.text>
        </Fieldset>
        <Fieldset>
          <Fieldset.legend>Produtos</Fieldset.legend>

          {order?.items?.map(item => (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 10,
                }}
              >
                <div>
                  <strong>Descrição</strong>
                  <p>{item.product.description}</p>
                </div>
                <div style={{ width: 100, textAlign: 'end' }}>
                  <strong>Valor</strong>
                  <p>{toDinheiro(item.total)}</p>
                </div>
              </div>
            </>
          ))}
          <div style={{ textAlign: 'end', marginTop: 14 }}>
            <strong>Total</strong>
            <p>{toDinheiro(order?.total)}</p>
          </div>
        </Fieldset>
        <Fieldset>
          <Fieldset.legend>Forma de Pagamento</Fieldset.legend>
          <Fieldset.text style={{ padding: 10 }}>
            {order?.payment?.name}
          </Fieldset.text>
        </Fieldset>
        <Fieldset>
          <Fieldset.legend>Metodo de envio</Fieldset.legend>
          <Fieldset.text style={{ padding: 10 }}>
            {order?.method_send_description}
          </Fieldset.text>
        </Fieldset>
        {order?.status_int === 4 && order?.reason_cancellation && (
          <Fieldset>
            <Fieldset.legend>Motivo do cancelamento</Fieldset.legend>
            <Fieldset.text style={{ padding: 10, color: 'red' }}>
              {order?.reason_cancellation}
            </Fieldset.text>
          </Fieldset>
        )}
      </>
    );
  };

  return (
    <>
      <SimpleHeader />

      <div style={{ display: 'flex', flexDirection: 'column', padding: 60 }}>
        {!hasProfile || loading ? (
          <>
            <Centered>
              <Loading />
            </Centered>
          </>
        ) : (
          <Container>
            <MyAvatar.Container>
              <Avatar
                name={profile.name ? getInitials(profile.name) : null}
                size={120}
                round="10px"
                color="#b1c2d9"
                style={{ marginRight: '42px' }}
              />
              <div>
                <Name.Container>
                  <Name>{profile.name}</Name>
                </Name.Container>
                {profile.profile_id !== 1 && (
                  <Label>
                    {profile.profile_id === 2 ? 'Empresa' : 'Cliente'}
                  </Label>
                )}
              </div>

              <Action>
                <Button
                  onClick={() => navigateTo(`/profile/${user.id}/edit`)}
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Editar
                </Button>
                <RedButton
                  onClick={() => dispatch(signOut())}
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Sair
                </RedButton>
              </Action>
            </MyAvatar.Container>

            <Divider />

            <Section>
              <Section.Title>Dados pessoais</Section.Title>

              <Grid container spacing={6}>
                <Grid item>
                  <Label>Nome</Label>
                  <p>{profile.name}</p>
                </Grid>
                <Grid item>
                  <Label>Email</Label>
                  <p>{profile.email}</p>
                </Grid>
                <Grid item>
                  <Label>Telefone</Label>
                  <p>{profile.phone}</p>
                </Grid>
              </Grid>
            </Section>

            <Divider />

            <AppBar position="static" color="#ffffff">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Pedidos" />
                <Tab label="Meus Cupons" />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <SimpleModal
                open={openOrder}
                title=""
                onClose={handleCloseOrder}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                width={650}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {renderDetalheOrder()}
                </div>
              </SimpleModal>

              <SimpleTable
                loading={loadingOrder}
                error={errorOrder}
                columns={columnsOrder}
                rows={orders.data}
                actions={actionsOrder}
                paginable={{
                  page: orders.current_page,
                  lastPage: orders.next_page_url,
                  onPageChange: page =>
                    dispatch(fetchOrdersRequested(page, null, user.id, type)),
                }}
                onSearchChange={term =>
                  dispatch(
                    fetchOrdersRequested(orders.page, term, user.id, type)
                  )
                }
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Section>
                <Section.Title>
                  {user.profile_id === 3 ? 'Meus Cupons' : 'Cupons'}
                </Section.Title>

                <Grid
                  container
                  direction="row"
                  spacing={5}
                  justify="flex-start"
                  style={{ marginBottom: '20px' }}
                >
                  <Grid item>
                    <Label>Total</Label>
                    {coupons.data && coupons.data[0] ? (
                      <p>{coupons.data[0].total}</p>
                    ) : (
                      <p>0</p>
                    )}
                  </Grid>
                  <Grid item>
                    <Label>Retirados</Label>
                    {coupons.data && coupons.data[0] ? (
                      <p>{coupons.data[0].ransomed}</p>
                    ) : (
                      <p>0</p>
                    )}
                  </Grid>
                  <Grid item>
                    <Label>Utilizados</Label>
                    {coupons.data && coupons.data[0] ? (
                      <p>{coupons.data[0].used}</p>
                    ) : (
                      <p>0</p>
                    )}
                  </Grid>
                </Grid>

                <SimpleTable
                  loading={loadingCoupon}
                  columns={columns}
                  error={errorCoupon}
                  rows={coupons.data}
                  actions={actions}
                  paginable={{
                    page: coupons.current_page,
                    lastPage: coupons.next_page_url,
                    onPageChange: page =>
                      dispatch(
                        fetchCouponsRequested(page, null, user.id, type)
                      ),
                  }}
                  onSearchChange={term =>
                    dispatch(
                      fetchCouponsRequested(coupons.page, term, user.id, type)
                    )
                  }
                />
              </Section>
            </TabPanel>
          </Container>
        )}
      </div>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
