/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {
  Container,
  MyAvatar,
  Name,
  Action,
  Divider,
  Section,
  Row,
  Label,
  TitleContainer,
  TitleDescription,
  TitleProduct,
  ValueContainer,
  ValueDescription,
  ValueProduct,
} from './styles';

import {
  fetchProductRequested,
  fetchProductsRequested,
} from '~/store/modules/product/actions';
import {
  fetchCouponsRequested,
  deleteCoupon,
  deleteAllCoupons,
} from '~/store/modules/coupon/actions';
import { Layout, Centered, Error, Input, SimpleTable } from '~/components';
import { navigateTo } from '~/services/history';
import api from '~/services/api';
import Loading from '~/assets/lottie/Loading';
import { limit, toDinheiro } from '~/utils';
import SimpleModal from '~/components/Modal';
import EmptyPhoto from '~/assets/images/foto-nao-disponivel.png';

export default function ProductDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { loading, error, product, products } = useSelector(
    state => state.product
  );
  const { loading: loadingCoupon, coupons, submitted } = useSelector(
    state => state.coupon
  );
  const { profile: user } = useSelector(state => state.auth);
  const [open, setOpen] = useState(false);
  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const [company, setcompany] = useState(null);
  const { register, errors, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const type = 'products';

  const RedButton = withStyles(() => ({
    root: {
      color: '#ffffff',
      backgroundColor: '#fd1313',
      '&:hover': {
        backgroundColor: '#ca1616',
      },
    },
  }))(Button);

  useEffect(() => {
    document.title = 'Dados Produtos';
    if (user.profile_id !== 1) {
      document.title = 'Dados Produto e Cupons';
      dispatch(fetchProductRequested(id));
    }

    dispatch(fetchCouponsRequested(1, null, id, type));
    dispatch(fetchProductsRequested(1, null, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, user.profile_id]);

  useEffect(() => {
    if (user.profile_id === 1) {
      api.get(`/api/companies/${id}`).then(response => {
        setcompany(response.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (submitted) {
      toast.success('Cupom removido!', { autoClose: 2000 });
      dispatch(fetchCouponsRequested(1, null, id, type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDeleteCoupons = () => {
    setOpenDeleteAll(true);
  };

  const handleCloseDeleteCoupons = () => {
    setOpenDeleteAll(false);
  };

  const handleDeleteAllCoupons = () => {
    dispatch(deleteAllCoupons(true, product.company_id, product.id));
    setOpenDeleteAll(false);
  };

  const handleDelete = async productId => {
    try {
      const response = await api.delete(`/api/products/${productId}`);

      if (response.status !== 200) {
        throw response;
      }

      if (user.profile_id !== 1) {
        navigateTo('/products');
      }

      dispatch(fetchProductsRequested(1, null, id));

      toast.success('O produto selecionado foi removido.', {
        autoClose: 2000,
      });
    } catch (error) {
      if (error.response) {
        const { errors: responseErrors } = error.response.data;

        if (responseErrors) {
          toast.error(responseErrors[0]);
        }
      }
    }
  };

  const onSubmit = async data => {
    try {
      const response = await api.post('/api/coupons', data);

      if (response.status !== 200) {
        toast.error(
          'Não foi possível gerar o número informado de cupons para este produto.'
        );
        return;
      }

      toast.success('Cupom gerado com sucesso!');
      dispatch(fetchCouponsRequested(1, null, id, type));

      setOpen(false);
    } catch (error) {
      if (error.response) {
        const { errors: responseErrors } = error.response.data;

        if (responseErrors) {
          toast.error(responseErrors[0]);
        }
      }
    }
  };

  const gerarCupom = useCallback(
    idProduct => {
      dispatch(fetchProductRequested(idProduct));
    },
    [dispatch]
  );

  const getContentToRender = () => {
    const hasProduct = Object.keys(product).length;

    if (error) {
      return (
        <Error status={500} title="Não foi possível carregar os produtos." />
      );
    }

    const columns = [
      { title: 'Código', field: 'code' },
      { title: 'Valor', field: 'value' },
      { title: 'Disponível', field: 'is_active' },
      { title: 'Retirado em:', field: 'delivery_at' },
      { title: 'Cliente', field: 'user_name' },
      { title: 'Usado em:', field: 'used_at' },
    ];

    const actions = [
      {
        icon: 'delete_forever',
        tooltip: 'Excluir',
        onClick: (event, coupon) => {
          dispatch(deleteCoupon(coupon.id));
        },
      },
    ];

    const columnsProducts = [
      { title: 'Descrição', field: 'description' },
      { title: 'Valor', field: 'value' },
    ];

    const actionsProducts = [
      {
        icon: 'delete_forever',
        tooltip: 'Remover',
        onClick: (event, prod) => {
          handleDelete(prod.id);
        },
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        onClick: (event, prod) => {
          navigateTo(`/products/${prod.id}/edit`);
        },
      },
      {
        icon: 'local_offer',
        tooltip: 'Gerar Cupons',
        onClick: (event, prod) => {
          gerarCupom(prod.id);
          handleOpen();
        },
      },
    ];

    return (
      <>
        <SimpleModal
          open={openDeleteAll}
          title="Deletar Todos Cupons"
          onClose={handleCloseDeleteCoupons}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <span>
            Atenção! Esta ação removerá permanetemente todos os cupons
            disponíveis para este produto, deseja continuar?
          </span>
          <Row>
            <RedButton
              type="button"
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              style={{ margin: 5 }}
              onClick={() => {
                handleDeleteAllCoupons();
              }}
            >
              Sim
            </RedButton>
            <Button
              type="button"
              variant="contained"
              color="default"
              disableElevation
              fullWidth
              style={{ margin: 5 }}
              onClick={() => {
                handleCloseDeleteCoupons();
              }}
            >
              Não
            </Button>
          </Row>
        </SimpleModal>
        <SimpleModal
          open={open}
          title="Gerar Cupom"
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            {product.id ? (
              <>
                <input
                  type="hidden"
                  ref={register}
                  name="company_id"
                  value={product.company_id}
                />

                <input
                  type="hidden"
                  ref={register}
                  name="product_id"
                  value={product.id}
                />

                <TitleContainer>
                  <TitleDescription>Descrição:</TitleDescription>
                  <TitleProduct>{limit(80)(product.description)}</TitleProduct>
                </TitleContainer>

                <ValueContainer>
                  <ValueDescription>
                    Valor do Produto (sem desconto):
                  </ValueDescription>
                  <ValueProduct>{toDinheiro(product.value)}</ValueProduct>
                </ValueContainer>

                <Input
                  inputRef={register({
                    required: 'Este campo é obrigatório',
                  })}
                  type="text"
                  name="value"
                  autoFocus
                  label="Valor do desconto (cupom)"
                  placeholder="Valor do desconto (cupom)"
                  error={Boolean(errors.value)}
                  helperText={errors.value?.message}
                  onChange={({ target }) => {
                    let valor = target.value;

                    valor = valor.replace(/\D/, '');
                    valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
                    valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');

                    target.value = valor;
                  }}
                  withDefaultStyle
                />
                <Input
                  inputRef={register({
                    required: 'Este campo é obrigatório',
                  })}
                  type="text"
                  name="quantity"
                  label="Quantidade"
                  placeholder="Quantidade de cupom"
                  defaultValue={1}
                  error={Boolean(errors.quantity)}
                  helperText={errors.quantity?.message}
                  withDefaultStyle
                  onChange={({ target }) => {
                    target.value = target.value.replace(/\D/g, '');
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Gerando...' : 'Gerar cupom'}
                </Button>
              </>
            ) : (
              <Centered avoidFullHeight>
                <Loading />
              </Centered>
            )}
          </form>
        </SimpleModal>
        {user.profile_id !== 1 ? (
          <Container>
            {!hasProduct || loading ? (
              <Centered>
                <Loading />
              </Centered>
            ) : (
              <>
                <MyAvatar.Container>
                  {/* adicionar a foto do produto aqui */}
                  <img
                    style={{
                      backgroundSize: 'cover',
                      maxWidth: 120,
                      width: 'auto',
                      marginRight: 5,
                    }}
                    src={
                      product.id && product.product_url
                        ? product.product_url
                        : EmptyPhoto
                    }
                    alt="Imagem do Produto"
                  />
                  <div style={{ marginLeft: 30 }}>
                    <Name.Container>
                      <Name>{limit(30)(product.description)}</Name>
                    </Name.Container>
                    <Label>{toDinheiro(product.value)}</Label>
                  </div>
                </MyAvatar.Container>
                <Action>
                  <Button
                    onClick={() => navigateTo(`/products/${product.id}/edit`)}
                    variant="contained"
                    disableElevation
                  >
                    Editar
                  </Button>

                  <RedButton
                    onClick={() => handleDelete(product.id)}
                    variant="contained"
                    color="danger"
                    disableElevation
                  >
                    Remover
                  </RedButton>

                  <Button
                    onClick={() => {}}
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={() => handleOpen()}
                    disableElevation
                  >
                    Gerar Cupons
                  </Button>
                </Action>

                <Divider />

                <Section>
                  <Section.Title>Dados dos Cupons</Section.Title>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container spacing={5} alignItems="center">
                        <Grid item>
                          <Label>Total</Label>
                          {coupons.data && coupons.data[0] ? (
                            <p>{coupons.data[0].total}</p>
                          ) : (
                            <p>0</p>
                          )}
                        </Grid>
                        <Grid item>
                          <Label>Disponíveis</Label>
                          {coupons.data && coupons.data[0] ? (
                            <p>{coupons.data[0].available}</p>
                          ) : (
                            <p>0</p>
                          )}
                        </Grid>
                        <Grid item>
                          <Label>Retirados</Label>
                          {coupons.data && coupons.data[0] ? (
                            <p>{coupons.data[0].ransomed}</p>
                          ) : (
                            <p>0</p>
                          )}
                        </Grid>
                        <Grid item>
                          <Label>Utilizados</Label>
                          {coupons.data && coupons.data[0] ? (
                            <p>{coupons.data[0].used}</p>
                          ) : (
                            <p>0</p>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      {user.profile_id === 2 &&
                      coupons.data &&
                      coupons.data[0] &&
                      coupons.data[0].available > 0 ? (
                        <div>
                          <MyAvatar.Container>
                            <RedButton
                              size="large"
                              variant="contained"
                              color="primary"
                              onClick={() => handleOpenDeleteCoupons()}
                              disableElevation
                            >
                              Remover Cupons
                            </RedButton>
                          </MyAvatar.Container>
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <SimpleTable
                    loading={loadingCoupon}
                    columns={columns}
                    rows={coupons.data}
                    actions={actions}
                    pageSize={coupons.per_page}
                    paginable={{
                      page: coupons.current_page,
                      lastPage: coupons.next_page_url,
                      onPageChange: page =>
                        dispatch(fetchCouponsRequested(page, null, id, type)),
                    }}
                    onSearchChange={term =>
                      dispatch(
                        fetchCouponsRequested(coupons.page, term, id, type)
                      )
                    }
                  />
                </Section>
              </>
            )}
          </Container>
        ) : (
          <>
            {!company ? (
              <Centered>
                <Loading />
              </Centered>
            ) : (
              <Container>
                <MyAvatar.Container>
                  <div>
                    <Name.Container>
                      <Name>{company.fantasy}</Name>
                    </Name.Container>
                    <Label>{company.email}</Label>
                    <Label>
                      Tel. {company.phone} | Whats. {company.whatsapp}{' '}
                    </Label>
                    <Label>
                      {company.address}, {company.address_number},{' '}
                      {company.neighborhood}, {company.cep}
                    </Label>
                  </div>
                </MyAvatar.Container>

                <Divider />

                <div>
                  <Section>
                    <Section.Title>
                      Lista de produtos
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disableElevation
                        style={{ margin: 5 }}
                        onClick={() => {
                          navigateTo(`/products/company/${company.id}/create`);
                        }}
                      >
                        NOVO PRODUTO
                      </Button>
                    </Section.Title>
                  </Section>

                  <SimpleTable
                    loading={loading}
                    error={error}
                    columns={columnsProducts}
                    rows={products.data}
                    actions={actionsProducts}
                    pageSize={products.per_page}
                    paginable={{
                      page: products.current_page,
                      lastPage: products.next_page_url,
                      onPageChange: page => {
                        dispatch(fetchProductsRequested(page, null, id));
                      },
                    }}
                    onSearchChange={term => {
                      dispatch(fetchProductsRequested(products.page, term, id));
                    }}
                  />
                </div>
              </Container>
            )}
          </>
        )}
      </>
    );
  };

  return <Layout>{getContentToRender()}</Layout>;
}
