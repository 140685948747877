import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistConfig = {
    key: 'liquidaaqui',
    storage,
    whitelist: ['auth', 'setting'],
  };

  return persistReducer(persistConfig, reducers);
};
