import { all, takeLatest, call, put } from 'redux-saga/effects';

import { FETCH_COMPANIES_REQUESTED, FETCH_COMPANY_REQUESTED } from './types';
import {
  fetchCompaniesSucceded,
  fetchCompaniesFailed,
  fetchCompanySucceded,
  fetchCompanyFailed,
} from './actions';

import api from '~/services/api';
import { diffForHumans } from '~/utils';

function* fetchCompanies({ payload }) {
  const { page, term } = payload;
  const target = `/api/companies?page=${page}${term ? `&search=${term}` : ''}`;

  try {
    const { data, ...response } = yield call(api.get, target);

    if (response.status !== 200) {
      throw response;
    }

    const companies = {
      ...data,
      data: data.data.map(company => ({
        ...company,
        created_at: diffForHumans(company.created_at),
        is_active: company.is_active ? 'Sim' : 'Não',
      })),
    };
    yield put(fetchCompaniesSucceded(companies));
  } catch (error) {
    yield put(fetchCompaniesFailed(error));
  }
}

/**
 * Manipula a ação de buscar o perfil do usuário.
 *
 * @param {Object} payload
 */
function* fetchCompany({ payload }) {
  try {
    const response = yield call(api.get, `/api/companies/${payload.id}`);

    if (response.status !== 200) {
      throw response;
    }

    yield put(fetchCompanySucceded(response.data));
  } catch (error) {
    yield put(fetchCompanyFailed(error));
  }
}

export default all([
  takeLatest(FETCH_COMPANIES_REQUESTED, fetchCompanies),
  takeLatest(FETCH_COMPANY_REQUESTED, fetchCompany),
]);
