/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import { Input, Select } from '~/components';
import SimpleModal from '~/components/Modal';
import { cepMask } from '~/utils';
import api from '~/services/api';
import { InputCheckbox } from '~/pages/Products/Form/styles';

export function MyAddress({ open, close, responseData }) {
  const { register, handleSubmit } = useForm();
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');

  const [maskCep, setMaskCep] = useState();

  function pesquisaCep(value) {
    const cep = value.replace(/\D/g, '');
    if (cep !== '') {
      const validacep = /^[0-9]{8}$/;
      if (validacep.test(cep)) {
        axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(response => {
          const { data } = response;
          if (data.erro) {
            return false;
          }
          setAddress(data.logradouro);
          setNeighborhood(data.bairro);
        });
      }
    }
  }
  async function onSubmit(inputs) {
    const { data } = await api.post('/api/addresses', inputs);
    if (data.success) {
      close(true);
      responseData(data);
    }
  }

  return (
    <SimpleModal
      open={open}
      title="Adicionar um novo endereço"
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      width={650}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Input
            inputRef={register({ required: 'Este campo é obrigatório' })}
            style={{ width: '95%' }}
            label="CEP"
            type="text"
            value={maskCep}
            name="zip_code"
            columns={{ md: 6 }}
            required
            onBlur={({ target }) => pesquisaCep(target.value)}
            onChange={({ target }) => {
              setMaskCep(cepMask(target.value));
            }}
          />
          <Input
            inputRef={register({ required: 'Este campo é obrigatório' })}
            style={{ width: '95%' }}
            label="Rua"
            type="text"
            name="street"
            value={address}
            onChange={({ target }) => {
              setAddress(target.value);
            }}
            required
            columns={{ md: 6 }}
          />

          <Input
            inputRef={register({ required: 'Este campo é obrigatório' })}
            style={{ width: '95%' }}
            label="Numero"
            type="text"
            name="number"
            columns={{ md: 6 }}
            required
          />
          <Input
            inputRef={register({ required: 'Este campo é obrigatório' })}
            style={{ width: '95%' }}
            label="Bairro"
            type="text"
            name="neighborhood"
            value={neighborhood}
            onChange={({ target }) => {
              setNeighborhood(target.value);
            }}
            columns={{ md: 6 }}
            required
          />
          <Select
            ref={register}
            style={{ width: '95%' }}
            name="state_id"
            label="Selecione o estado..."
            value={51}
            options={[
              {
                value: 51,
                name: 'Mato Grosso',
              },
            ]}
            disabled
            columns={{ md: 6, xs: 12, lg: 6 }}
          />
          <Select
            ref={register}
            style={{ width: '95%' }}
            name="city_id"
            label="Selecione cidade..."
            value={5292}
            options={[
              {
                value: 5292,
                name: 'Rondonópolis',
              },
            ]}
            disabled
            columns={{ md: 6, xs: 12, lg: 6 }}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <InputCheckbox
              id="checkbox1"
              type="checkbox"
              className="checkbox"
              ref={register}
              name="default"
            />
            <label htmlFor="checkbox1">Endereço Principal</label>
          </div>

          <Button
            type="submit"
            variant="contained"
            disableElevation
            fullWidth
            style={{
              marginRight: 10,
              backgroundColor: '#4BD37B',
              color: 'white',
              marginTop: 10,
            }}
          >
            Adicionar
          </Button>
        </Grid>
      </form>
    </SimpleModal>
  );
}
