// Buscar os produtos.
export const FETCH_COUPONS_REQUESTED = 'FETCH_COUPONS_REQUESTED';
export const FETCH_COUPONS_SUCCEDED = 'FETCH_COUPONS_SUCCEDED';
export const FETCH_COUPONS_FAILED = 'FETCH_COUPONS_FAILED';
export const DELETE_COUPON = 'DELETE_COUPON';
export const DELETE_COUPON_SUCCEDED = 'DELETE_COUPON_SUCCEDED';
export const DELETE_COUPON_FAILED = 'DELETE_COUPON_FAILED';
export const DELETE_ALL_COUPONS = 'DELETE_COUPON';
export const DELETE_ALL_COUPONS_SUCCEDED = 'DELETE_COUPON_SUCCEDED';
export const DELETE_ALL_COUPONS_FAILED = 'DELETE_COUPON_FAILED';

export const CLEAR_COUPON = 'CLEAR_COUPON';
