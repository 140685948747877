import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ResourcesPageWithTable from '~/layouts/ResourcesPageWithTable';
import { fetchProductsRequested } from '~/store/modules/product/actions';
import { fetchCompaniesRequested } from '~/store/modules/company/actions';
import { navigateTo } from '~/services/history';

export default function ProductsList() {
  const dispatch = useDispatch();

  const [companyState, productState] = useSelector(state => [
    state.company,
    state.product,
  ]);

  const { profile: user } = useSelector(state => state.auth);

  useEffect(() => {
    function loadProducts() {
      if (user.company_id) {
        dispatch(fetchProductsRequested(1, null, user.company_id));
      } else {
        dispatch(fetchCompaniesRequested());
      }
    }
    loadProducts();
  }, [dispatch, user.company_id]);

  const columns = [
    { title: 'Empresa', field: 'fantasy' },
    { title: 'Quantidade', field: 'products.length' },
  ];

  const products = [
    { title: 'Descricao', field: 'description' },
    { title: 'Valor', field: 'value' },
  ];

  const actions = [
    {
      icon: 'arrow_right',
      tooltip: 'Detalhe do produto',
      onClick: (event, product) =>
        user.role === 'empresas'
          ? navigateTo(`/products/${product.id}`)
          : navigateTo(`products/company/${product.id}`),
    },
  ];

  return (
    <>
      {!user.company_id ? (
        <ResourcesPageWithTable
          title="Produtos > Empresas"
          loading={companyState.loading}
          error={companyState.error}
          columns={columns}
          rows={companyState.companies.data}
          actions={actions}
          searchable
          pageSize={companyState.companies.per_page}
          paginable={{
            page: companyState.companies.current_page,
            lastPage: companyState.companies.next_page_url,
            onPageChange: page => dispatch(fetchCompaniesRequested(page)),
          }}
          onSearchChange={term =>
            dispatch(fetchCompaniesRequested(companyState.companies.page, term))
          }
        />
      ) : (
        <ResourcesPageWithTable
          title="Produtos"
          header={{
            action: {
              label: 'Novo Produto',
              props: {
                onClick: () => navigateTo('/products/create'),
                style: {
                  backgroundColor: '#5BB60A',
                  color: 'white',
                },
              },
            },
          }}
          loading={productState.loading}
          error={productState.error}
          columns={products}
          rows={productState.products.data}
          actions={actions}
          searchable
          pageSize={productState.products.per_page}
          paginable={{
            page: productState.products.current_page,
            lastPage: productState.products.next_page_url,
            onPageChange: page =>
              dispatch(fetchProductsRequested(page, null, user.company_id)),
          }}
          onSearchChange={term =>
            dispatch(
              fetchProductsRequested(
                productState.products.page,
                term,
                user.company_id
              )
            )
          }
        />
      )}
    </>
  );
}
