/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-inner-declarations */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { Img } from './styles';

import { Input, Select, Layout } from '~/components';
import ResourcePageWithForm from '~/layouts/ResourcePageWithForm';
import { Centered } from '~/pages/Participant/components/styles';
import Loading from '~/assets/lottie/Loading';
import api from '~/services/api';

export function BannerForm() {
  const { id } = useParams();
  const [bannerImg, setBannerImg] = useState('');
  const [bannerBackgroundImg, setBannerBackgroundImg] = useState('');
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState({});

  useEffect(() => {
    if (id) {
      async function fetchBanner() {
        setLoading(true);
        try {
          const { data } = await api.get(`/api/banners/${id}`);
          setBanner(data);
          setBannerImg(data?.banner_url);
          setBannerBackgroundImg(data?.background_url);
        } catch {
        } finally {
          setLoading(false);
        }
      }

      fetchBanner();
    }
  }, [id]);

  function imgBanner(e) {
    const file = e.target.files[0];
    if (e.target.name === 'banner' && !file) {
      setBannerImg('');
    }

    if (e.target.name === 'background' && !file) {
      setBannerBackgroundImg('');
    }

    switch (e.target.name) {
      case 'banner':
        setBannerImg(URL.createObjectURL(file));
        break;
      case 'background':
        setBannerBackgroundImg(URL.createObjectURL(file));
        break;
    }
  }

  function handleData(data) {
    const formData = new FormData();
    Object.entries(data).map(([key, value]) => {
      if (key === 'banner') {
        formData.append(key, value[0]);
      } else if (key === 'background') {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });

    if (id) {
      formData.append('_method', 'put');
    }

    if (!data.banner.length) {
      formData.delete('banner');
    }

    if (!data.background.length) {
      formData.delete('background');
    }

    return formData;
  }

  if (loading) {
    return (
      <Layout>
        <Centered>
          <Loading />
        </Centered>
      </Layout>
    );
  }

  const options = [
    { name: 'Ativo', value: 1 },
    { name: 'Inativo', value: 0 },
  ];

  const inputs = register => [
    <Input
      inputRef={register}
      label="Nome"
      type="text"
      name="title"
      required
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label="Link"
      type="text"
      name="link"
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      type="file"
      name="banner"
      label="Banner (1536x530)"
      required={!bannerImg}
      inputProps={{ accept: 'image/*' }}
      columns={{ md: 6, xs: 12, lg: 6 }}
      onChange={e => {
        imgBanner(e);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />,
    <Input
      inputRef={register}
      type="file"
      name="background"
      label="Background (1904x728)"
      inputProps={{ accept: 'image/*' }}
      columns={{ md: 6, xs: 12, lg: 6 }}
      onChange={e => {
        imgBanner(e);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />,
    <Grid item md={6} xs={12} lg={6}>
      <Img src={bannerImg} />
    </Grid>,
    <Grid item md={6} xs={12} lg={6}>
      <Img src={bannerBackgroundImg} />
    </Grid>,
    <Input
      inputRef={register}
      label="Ordenação"
      type="number"
      name="order_by"
      columns={{ md: 6 }}
    />,
    <Select
      name="active"
      ref={register}
      required
      options={options}
      value={banner.id ? (banner.active === true ? '1' : '0') : '1'}
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
  ];

  document.title = banner.id ? 'Editando Banner' : 'Novo Banner';

  return (
    <ResourcePageWithForm
      title={banner.id ? 'Editando Banner' : 'Novo Banner'}
      target={banner.id ? `/api/banners/${banner.id}` : '/api/banners'}
      inputs={inputs}
      method="post"
      beforeSubmit={handleData}
      submitButtonLabel={banner.id ? 'Atualizar' : 'Salvar'}
      location="/settings"
      onSuccess={() =>
        toast.success(
          banner.id
            ? 'Registro atualizado com sucesso.'
            : 'Registro realizado com sucesso.',
          { autoClose: 2000 }
        )
      }
      onError={() => {
        toast.error('Não foi possivel realizar registro, tenta novamente.');
      }}
      formInitialState={{
        defaultValues: {
          title: banner.title,
          link: banner.link,
          order_by: banner.order_by ?? 1,
        },
      }}
    />
  );
}
