// Buscar os perfis das empresas.
export const FETCH_COMPANIES_REQUESTED = 'FETCH_COMPANIES_REQUESTED';
export const FETCH_COMPANIES_SUCCEDED = 'FETCH_COMPANIES_SUCCEDED';
export const FETCH_COMPANIES_FAILED = 'FETCH_COMPANIES_FAILED';

// Buscar o perfil da empresa.
export const FETCH_COMPANY_REQUESTED = 'FETCH_COMPANY_REQUESTED';
export const FETCH_COMPANY_SUCCEDED = 'FETCH_COMPANY_SUCCEDED';
export const FETCH_COMPANY_FAILED = 'FETCH_COMPANY_FAILED';

// Remover produto da empresa.
export const REMOVE_PRODUCT_REQUEST = 'REMOVE_PRODUCT_REQUEST';
export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT_FAILED = 'REMOVE_PRODUCT_FAILED';

export const CLEAR_COMPANY = 'CLEAR_COMPANY';
