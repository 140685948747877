import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Icon from 'react-feather';

import Header from '../Header';
import { Image, ListItemContainer, LinkWithIcon, ListStyle } from './styles';

import { Colors } from '~/themes';

const drawerWidth = 265;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function ResponsiveDrawer({ container, menuItems, children }) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = () => {
    const renderItem = (item, index) => {
      const currentPathname = document.location.pathname;
      const isActive = currentPathname.includes(item.link);
      const ItemIcon = Icon[item.icon];

      return item.hidden !== 3 ? (
        <ListItemContainer position="bottom" key={index}>
          {item.hidden !== 2 ||
          (item.text !== 'Usuários' && item.text !== 'Configurações') ? (
            <LinkWithIcon to={item.link} onClick={item.action || null}>
              <ListItem button style={{ alignSelf: 'flex-end' }}>
                {/* The menu item icon */}
                {ItemIcon && (
                  <ItemIcon
                    color={
                      isActive
                        ? Colors.primary.backgroundColor
                        : Colors.muted.backgroundColor
                    }
                    style={{
                      marginLeft: 18,
                      marginRight: 26,
                    }}
                  />
                )}

                {/* The menu item text */}
                <ListItemText
                  style={{
                    color: isActive ? 'black' : Colors.muted.backgroundColor,
                  }}
                >
                  {item.text}
                </ListItemText>
              </ListItem>
            </LinkWithIcon>
          ) : null}
        </ListItemContainer>
      ) : null;
    };

    return (
      <>
        <div className={classes.toolbar} />

        <ListStyle>{menuItems.map(renderItem)}</ListStyle>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Image src={require('~/assets/images/logo_cdl.webp')} alt="" />

            {drawer()}
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <a href="/">
              <Image
                src={require('~/assets/images/logo_cdl.webp')}
                alt="LIQUIDAQUI"
              />
            </a>

            {drawer()}
          </Drawer>
        </Hidden>
      </nav>

      {/* The content */}
      <main className={classes.content}>
        <Header
          title={document.title}
          menuIcon={
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <Icon.Menu />
            </IconButton>
          }
        />

        <div style={{ display: 'flex', flexDirection: 'column', padding: 15 }}>
          {children}
        </div>
      </main>
    </div>
  );
}
