/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, isValidElement } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Grid, Button } from '@material-ui/core';

import { Container, Title } from './styles';

import api from '~/services/api';
import { Centered, Input, Layout, SimpleHeader } from '~/components';
import { fetchProfileRequested } from '~/store/modules/profile/actions';
import { cpfMask, telefoneMask } from '~/utils';
import Loading from '~/assets/lottie/Loading';
import { navigateTo } from '~/services/history';

export default function ProfileForm() {
  document.title = 'Editando perfil';
  const { id } = useParams();
  const dispatch = useDispatch();
  const { profile: user, loading } = useSelector(state => state.auth);
  const [maskCPF, setMaskCPF] = useState();
  const [maskTelefone, setMaskTelefone] = useState();

  const formInitialState = {
    defaultValues: {
      name: user?.name,
      cpf: user?.cpf,
      email: user?.email,
      phone: user?.phone,
      company_id: user?.company_id,
      profile_id: user?.profile_id,
    },
  };
  const { register, handleSubmit } = useForm(formInitialState);

  useEffect(() => {
    if (id) {
      dispatch(fetchProfileRequested(user.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Layout>
        <Centered>
          <Loading />
        </Centered>
      </Layout>
    );
  }

  const inputs = register => [
    <Input inputRef={register} label="Nome" type="text" name="name" required />,
    <Input
      inputRef={register}
      label="CPF"
      type="text"
      name="cpf"
      required
      value={maskCPF}
      onChange={({ target }) => {
        setMaskCPF(cpfMask(target.value));
      }}
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label="Telefone"
      type="text"
      name="phone"
      value={maskTelefone}
      onChange={({ target }) => {
        setMaskTelefone(telefoneMask(target.value));
      }}
      columns={{ xs: 12, md: 6 }}
    />,
    <Input
      inputRef={register}
      label="E-mail"
      type="email"
      name="email"
      required
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label={id ? 'Alterar Senha' : 'Senha'}
      type="password"
      name="password"
      // hidden={Boolean(user.id)}
      columns={{ md: 6 }}
      required={!id}
    />,
  ];

  const onSubmit = async data => {
    const target = `/api/users/${user.id}`;
    try {
      const response = await api.put(target, data);

      if (response.status !== 200) {
        throw response;
      }

      toast.success(
        id ? 'Registro atualizado com sucesso.' : 'Usuário criado com sucesso.',
        { autoClose: 2000 }
      );
      navigateTo('/profile');
    } catch (error) {
      toast.error('Não foi possível criar o usuário.', { autoClose: 3000 });
    }
  };

  const renderInput = FieldToRender => {
    if (isValidElement(FieldToRender)) {
      return FieldToRender;
    }
    return false;
  };

  return (
    <>
      <SimpleHeader />

      <div style={{ display: 'flex', flexDirection: 'column', padding: 60 }}>
        <Container>
          <Title>Editando perfil</Title>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              {inputs(register).map(renderInput)}

              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Salvar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    </>
  );
}
