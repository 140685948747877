import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';

export const Image = styled.img`
  display: block;
  max-width: 125px;
  height: auto;
  margin-top: 42px;
  margin-left: auto;
  margin-right: auto;
`;

export const ListItemContainer = styled.div`
  margin-bottom: 10px;

  ${props =>
    props.position === 'bottom' &&
    css`
      &:last-child {
        margin-top: auto;
      }
    `}
`;

export const LinkWithIcon = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:active {
    color: black;
  }
`;

export const ListStyle = styled(List)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
