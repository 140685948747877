import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import Avatar from 'react-avatar';

// Styles
import {
  Container,
  MyAvatar,
  Name,
  Action,
  Divider,
  Section,
  Row,
  Group,
  Label,
  Chip,
} from './styles';

import { fetchCompanyRequested } from '~/store/modules/company/actions';
import { Layout, Centered, Error } from '~/components';
import { navigateTo } from '~/services/history';
import api from '~/services/api';
import Loading from '~/assets/lottie/Loading';

export default function CompanyDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, error, company } = useSelector(state => state.company);
  const { profile: user } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = 'Empresas';
    dispatch(fetchCompanyRequested(id));
  }, [dispatch, id]);

  const handleDelete = async () => {
    try {
      const response = await api.delete(`/api/companies/${id}`);

      if (response.status !== 200) {
        throw response;
      }

      toast.success('Registro removido!', { autoClose: 3000 });

      navigateTo('/companies');
    } catch {
      toast.error('Não foi possível remover este registro.');
    }
  };

  const getContentToRender = () => {
    const hasCompany = Object.keys(company).length;

    if (error) {
      return (
        <Error
          status={500}
          title="Não foi possível carregar o perfil do usuário."
        />
      );
    }

    return (
      <Container>
        {!hasCompany || loading ? (
          <Centered>
            <Loading />
          </Centered>
        ) : (
          <>
            {/* Avatar */}
            <MyAvatar.Container>
              <Avatar
                name={company.fantasy ?? company.social_reason}
                size={120}
                round="10px"
                color="#b1c2d9"
                style={{ marginRight: '42px' }}
              />

              <div>
                <Name.Container>
                  <Name>{company.fantasy}</Name>

                  {company.is_active && (
                    <Tooltip title="Publicado">
                      <Chip>P</Chip>
                    </Tooltip>
                  )}
                </Name.Container>
              </div>

              <Action>
                {user.profile_id !== 2 && user.profile_id !== 3 ? (
                  <Button
                    onClick={handleDelete}
                    size="large"
                    variant="outlined"
                    disableElevation
                    aria-label="Button remove"
                  >
                    Remover
                  </Button>
                ) : null}

                <Button
                  onClick={() => navigateTo(`/companies/${company.id}/edit`)}
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                  aria-label="Button editar"
                >
                  Editar
                </Button>
              </Action>
            </MyAvatar.Container>

            <Divider />

            <div>
              <Section>
                <Section.Title>Dados pessoais</Section.Title>

                <Row>
                  <Group>
                    <Label>Nome</Label>
                    <p>{company.social_reason}</p>
                  </Group>

                  <Group>
                    <Label>Email</Label>
                    <p>{company.email}</p>
                  </Group>

                  <Group>
                    <Label>Telefone</Label>
                    <p>{company.phone}</p>
                  </Group>
                </Row>
              </Section>
            </div>
          </>
        )}
      </Container>
    );
  };

  return <Layout>{getContentToRender()}</Layout>;
}
