import Types from './types';

export function signInRequest(username, password, redirect = false) {
  return {
    type: Types.SIGN_IN.REQUEST,
    payload: { username, password, redirect },
  };
}

export function signInSuccess(token, user) {
  return {
    type: Types.SIGN_IN.SUCCESS,
    payload: { token, user },
  };
}

export function signInFailure(message) {
  return {
    type: Types.SIGN_IN.FAILURE,
    payload: { message },
  };
}

export function signFailure(type, message = '', errors = []) {
  return {
    type: Types.SIGN_IN.FAILURE,
    payload: { message, errors },
  };
}

export function signOut() {
  return {
    type: Types.SIGN_OUT,
  };
}
