import Colors from './Colors';
import Fonts from './Fonts';

/**
 * Obtém as propriedades de estilo do components
 * com base na propriedade especificada.
 *
 * @param {Object} props
 * @param {Object} props.theme
 * @returns {Object}
 */
const getStyles = ({ theme }) => {
  const colors = Colors[theme];

  if (colors) {
    return {
      backgroundColor: colors.backgroundColor,
      color: colors.color,
    };
  }

  return {
    backgroundColor: Colors.secondary,
    color: 'black',
  };
};

export { Colors, Fonts, getStyles };
