import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { SimpleTable } from '~/components';
import api from '~/services/api';
import { navigateTo } from '~/services/history';
import { diffForHumans } from '~/utils';

export function BannerList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchBanners(page, term) {
    setLoading(true);
    const target = `/api/banners?page=${page}${term ? `&search=${term}` : ''}`;
    try {
      const response = await api.get(target);

      const banners = {
        ...response.data,
        data: response.data.data.map(banner => ({
          ...banner,
          created_at: diffForHumans(banner.created_at),
          active: banner.active ? 'Ativo' : 'Inativo',
        })),
      };

      setData(banners);
    } catch {
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteBanner(banner) {
    try {
      await api.delete(`/api/banners/${banner.id}`);
      toast.success('Registro excluído com sucesso!');
      fetchBanners();
    } catch (error) {
      toast.error('Não foi possível excluir esse registro, tenta novamente.');
    }
  }

  const columns = [
    {
      title: 'Nome',
      field: 'title',
      cellStyle: {
        width: '55%',
      },
      render: rowData => (
        <span
          style={{
            textDecoration:
              rowData.active === 'Inativo' ? 'line-through' : 'none',
            color: rowData.active === 'Inativo' ? '#848484' : '#000',
          }}
        >
          {rowData.title}
        </span>
      ),
    },
    {
      title: 'Status',
      field: 'active',
      align: 'center',
      render: rowData => (
        <span
          style={{
            padding: 5,
            fontSize: 13,
            backgroundColor:
              rowData.active === 'Inativo' ? '#f76a6a' : '#42e552',
            color: 'white',
            borderRadius: 50,
          }}
        >
          {rowData.active}
        </span>
      ),
    },
    { title: 'Criado', field: 'created_at' },
  ];

  const actions = [
    {
      icon: 'delete_forever',
      tooltip: 'Remover',
      onClick: (event, banner) => {
        handleDeleteBanner(banner);
      },
    },
    {
      icon: 'edit',
      tooltip: 'Editar',
      onClick: (_, banner) => {
        navigateTo(`/settings/banners/${banner.id}/edit`);
      },
    },
  ];

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <SimpleTable
      header={{
        action: {
          label: 'Adicionar',
          props: {
            onClick: () => navigateTo('/settings/banners/create'),
            style: {
              backgroundColor: '#5BB60A',
              color: 'white',
            },
          },
        },
      }}
      columns={columns}
      actions={actions}
      loading={loading}
      rows={data.data}
      paginable={{
        page: data.current_page,
        lastPage: data.next_page_url,
        onPageChange: page => fetchBanners(page),
      }}
      onSearchChange={term => fetchBanners(data.current_page, term)}
    />
  );
}
