/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import { Tag as TagIcon, ShoppingCart } from 'react-feather';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FaFacebook, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';

import Register from '~/components/SimpleHeader/components/Register';
import Loading from '~/assets/lottie/Loading';
import SimpleModal from '~/components/Modal';
import api from '~/services/api';
import { navigateTo } from '~/services/history';
import LoginForm from '~/components/SimpleHeader/components/LoginForm';
import ForgotPasswordForm from '~/components/SimpleHeader/components/ForgotPasswordForm';
import Centered from '~/components/Centered';
import { Colors } from '~/themes';

export default function Product({ product }) {
  const {
    id,
    thumbnail,
    thumbnails,
    allow_sale,
    name,
    inCash,
    previous_price,
    current_price,
    cupons,
    couponId,
  } = product;
  const [step, setStep] = useState(1);
  const { signed, profile } = useSelector(state => state.auth);
  const [open, setOpen] = useState(false);
  const [couponCaught, setCouponCaught] = useState('');
  const [title, setTitle] = useState('Encontrando seu cupom...');
  const [status, setStatus] = useState('');
  const [reload, setReload] = useState(false);
  const [loadGetCoupon, setLoadGetCoupon] = useState(true);

  const [leiaMais, setLeiaMais] = useState(true);

  const titlesByStep = {
    1: 'Faça o Login para obter seu cupom!',
    2: 'Recuperar senha',
    3: 'Registre-se',
  };

  const handleClose = () => {
    setOpen(false);
    setLoadGetCoupon(false);
    if (profile && reload) {
      setReload(false);
      document.location.reload();
    }
  };

  const getCoupon = async product_id => {
    try {
      setOpen(true);

      if (!profile) return;

      const data = {
        product: product_id,
        user: profile.id,
        coupon: couponId,
      };

      const response = await api.post('/api/products/coupons', data);

      if (response.status !== 200) {
        toast.error('Ops, não foi possível pegar este cupom.');
        return;
      }

      if (response.data.status === 'disponivel') {
        const info = `Utilize o código ${response.data.message} para obter um desconto na compra de um(a) ${response.data.data.product.description}.`;

        setCouponCaught(info);
        setTitle('Parabéns!');
        setLoadGetCoupon(false);
        setReload(true);
      } else if (response.data.status === 'esgotado') {
        setCouponCaught(response.data.message);
        setTitle('Esgotado!');
        setLoadGetCoupon(false);
        setReload(false);
      } else if (response.data.status === 'ativo') {
        setCouponCaught(response.data.message);
        setTitle('Cupom ativo!');
        setLoadGetCoupon(false);
        setReload(false);
      } else if (response.data.status === 'outOfPeriod') {
        setStatus(response.data.status);
        setCouponCaught(response.data.message);
        setTitle('Informação!');
        setLoadGetCoupon(false);
        setReload(false);
      } else if (response.data.status === 'unavailable') {
        setStatus(response.data.status);
        setCouponCaught(response.data.message);
        setTitle('Informação!');
        setLoadGetCoupon(false);
        setReload(false);
      }
    } catch (e) {
      if (e.response) {
        setOpen(false);
        setLoadGetCoupon(false);
        setReload(false);

        const { message } = e.response.data;
        toast.error(message);
      }
    }
  };

  async function handleAddCart({ id, company_id }) {
    if (!profile) {
      return setOpen(true);
    }

    try {
      const { data } = await api.post('/api/cart', {
        product_id: id,
        company_id,
      });

      const { success, message } = data;

      if (data.status === 'outOfPeriod') {
        setOpen(true);
        setStatus(data.status);
        setCouponCaught(data.message);
        setTitle('Informação!');
        setLoadGetCoupon(false);
        setReload(false);
      } else if (data.status === 'unavailable') {
        setOpen(true);
        setStatus(data.status);
        setCouponCaught(data.message);
        setTitle('Informação!');
        setLoadGetCoupon(false);
        setReload(false);
      } else if (success) {
        toast.success(message);
        navigateTo('/cart');
      }
    } catch (error) {
      const { message } = error.response.data;
      toast.error(message);
    }
  }

  useEffect(() => {
    if (signed) {
      navigateTo(document.location.pathname);
      setOpen(false);
    }
  }, [signed]);

  const renderForm = () => {
    switch (step) {
      case 1:
        return <LoginForm setStep={setStep} />;
      case 2:
        return <ForgotPasswordForm setStep={setStep} />;
      case 3:
        return <Register setStep={setStep} onChangeModal={e => setOpen(e)} />;
      default:
        break;
    }
  };

  const limit = (size, prepend = '...') => text => {
    if (text.length <= size) {
      return text;
    }

    return `${text.slice(0, size)}${prepend}`;
  };

  const Toggle = state => {
    if (state) {
      setLeiaMais(false);
    } else {
      setLeiaMais(true);
    }
  };

  return (
    <>
      {profile && signed ? (
        <SimpleModal
          open={open}
          title={title}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {loadGetCoupon ? (
            <Centered avoidFullHeight>
              <Loading />
            </Centered>
          ) : (
            <div>
              <span>{couponCaught}</span>
              {status !== 'outOfPeriod' && status !== 'unavailable' ? (
                <>
                  <br />
                  <br />
                  <span>
                    Os seus cupons de desconto estão disponíveis no e-mail
                    cadastrado, e também no seu perfil de usuário.
                  </span>
                  <br />
                  <br />
                  <span>Verifique também no no seu lixo eletrônico.</span>
                </>
              ) : (
                ''
              )}
            </div>
          )}
        </SimpleModal>
      ) : (
        <SimpleModal
          open={open}
          title={titlesByStep[step]}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Grid container>{renderForm()}</Grid>
        </SimpleModal>
      )}

      <div key={id} style={{ marginBottom: 30 }}>
        <DivImg>
          <Carousel autoPlay interval={5500} showStatus={false} infiniteLoop>
            <Thumbnail src={thumbnail} />
            {thumbnails.map(file => (
              <Thumbnail key={file.id} src={file.name} />
            ))}
          </Carousel>
        </DivImg>
        <div>
          <Ilustrativa>Imagem meramente ilustrativa</Ilustrativa>
        </div>
        <Title>
          {leiaMais ? limit(80)(name) : name}
          {name.length >= 80 ? (
            <ButtonLeiaMais
              type="button"
              onClick={() => {
                Toggle(leiaMais);
              }}
            >
              {leiaMais ? 'Leia Mais' : 'Leia Menos'}
            </ButtonLeiaMais>
          ) : null}
        </Title>
        <InCash>{inCash ? 'Válido apenas para pagamento á vista' : ''}</InCash>
        {couponId ? (
          <>
            <PreviousPrice>
              De <strike>{previous_price}</strike> por
            </PreviousPrice>
            <CurrentPrice>{current_price}</CurrentPrice>
          </>
        ) : (
          <>
            <CurrentPrice>{current_price}</CurrentPrice>
          </>
        )}

        {cupons?.is_active === 0 && (
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: '#9f9f9f',
              color: 'white',
            }}
            disableElevation
            fullWidth
            disabled
          >
            <TagIcon size={16} style={{ marginRight: 12 }} />
            Cupons esgotados
          </Button>
        )}

        {cupons?.is_active === 1 && (
          <>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: '#4BD37B',
                color: 'white',
              }}
              disableElevation
              fullWidth
              onClick={() => getCoupon(id)}
            >
              <TagIcon size={16} style={{ marginRight: 12 }} />
              Pegue seu cupom
            </Button>
          </>
        )}

        {allow_sale && (
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: '#5BB60A',
              color: 'white',
              marginTop: 10,
            }}
            disableElevation
            fullWidth
            onClick={() => handleAddCart(product)}
          >
            <ShoppingCart size={16} style={{ marginRight: 12 }} />
            Comprar
          </Button>
        )}

        <>
          <Social>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${document.URL}&t=${name}`}
              target="_blank"
              id="facebook"
            >
              <FaFacebook size={30} color="#072269" />
            </a>

            <a
              href={`https://api.whatsapp.com/send?text=Gostei desta publicação, olha o que eu encontrei no site *LIQUIDAQUI 2024* - *${name}* ${
                couponId
                  ? `. De ~*${previous_price}*~, à vista por *${current_price}* -`
                  : ' - '
              } Acesse agora o site ${
                !couponId
                  ? `${document.URL}`
                  : `e pegue o seu cupom também ${document.URL}`
              }`}
              target="_blank"
              id="whatsapp"
            >
              <FaWhatsapp size={30} color="#0CBB1E" />
            </a>
            <a
              href={`https://twitter.com/intent/tweet?text=Gostei desta publicação, olha o que eu encontrei no site *LIQUIDAQUI 2024 - ${name} ${
                couponId
                  ? `. De ${previous_price}, à vista por ${current_price} -`
                  : ' - '
              } Acesse agora o site ${
                !couponId
                  ? `${document.URL}`
                  : `e pegue o seu cupom também ${document.URL}`
              }`}
              target="_blank"
              id="twitter"
            >
              <FaTwitter size={30} color="#1585C8" />
            </a>
          </Social>
        </>
      </div>
    </>
  );
}

const Social = styled.div`
  height: 35px;
  margin-top: 10px;
  display: flex;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
`;

const DivImg = styled.div`
  margin: 0 auto;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Thumbnail = styled.img`
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
`;

const Title = styled.span`
  display: block;
  margin-top: 10px;
  margin-bottom: 8px;
`;

const ButtonLeiaMais = styled.button`
  border: none;
  background: none;
  padding: 0px 10px;
  outline: none;
  color: red;
  font-size: 14px;
  font-weight: 800;
  color: ${Colors.primary.backgroundColor};
`;

const Ilustrativa = styled.span`
  font-size: 11px;
  color: #ccc;
`;

const PreviousPrice = styled.span`
  display: block;
  text-align: center;
  margin-bottom: 6px;
`;

const CurrentPrice = styled.span`
  font-size: 22px;
  display: block;
  text-align: center;
  margin-bottom: 12px;
`;

const InCash = styled.span`
  color: #5b5b5b;
  font-size: 12px;
  display: flex;
  justify-content: center;
`;

Product.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    thumbnail: PropTypes.string.isRequired,
    thumbnails: null,
    allow_sale: null,
    name: PropTypes.string.isRequired,
    inCash: PropTypes.bool,
    previous_price: PropTypes.string.isRequired,
    current_price: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    couponId: PropTypes.number,
    cupons: null,
  }).isRequired,
};
