import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { Container, Form, Image, ErrorMessage } from './styles';

// Components
import { Input } from '~/components';
// Services
import api from '~/services/api';
import history from '~/services/history';
// import Logo from '~/assets/images/logo.png';

export default function PasswordReset() {
  const { token } = useParams();
  const { register, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = 'Recuperar senha';
  }, []);

  const onSubmit = async credentials => {
    const target = '/api/password/reset';

    try {
      const response = await api.post(target, {
        ...credentials,
        token,
      });

      if (response.status !== 200) {
        throw response;
      }

      toast.success('Uma nova senha foi cadastrada.');

      history.push('/');
    } catch (error) {
      if (error.response) {
        setErrors(error.response.data.errors);

        return;
      }

      toast.error(
        'Não foi possível cadastrar uma nova senha. Por favor, entre em contato com a equipe do evento.'
      );
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Image src={require('~/assets/images/logo_cdl.webp')} alt="logo" />

        <Form.Header>
          <h2>Recuperar senha</h2>
          <span>Por favor, confirme sua nova senha.</span>
        </Form.Header>

        {errors.token && <ErrorMessage>{errors.token}</ErrorMessage>}
        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="Nova senha"
          type="password"
          name="password"
          autoFocus
          error={Boolean(errors.password)}
          helperText={errors?.password}
        />

        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="Confirmar senha"
          type="password"
          name="password_confirmation"
          error={Boolean(errors.password_confirmation)}
          helperText={errors?.password_confirmation}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          disabled={isSubmitting}
        >
          Continuar
        </Button>
      </Form>
    </Container>
  );
}
