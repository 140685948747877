/* eslint-disable no-nested-ternary */
/* eslint-disable no-inner-declarations */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { Img } from './styles';

import { Input, Select, Layout } from '~/components';
import ResourcePageWithForm from '~/layouts/ResourcePageWithForm';
import { Centered } from '~/pages/Participant/components/styles';
import Loading from '~/assets/lottie/Loading';
import api from '~/services/api';

export function SponsorForm() {
  const { id } = useParams();
  const [sponsorImg, setSponsorImg] = useState('');
  const [loading, setLoading] = useState(false);
  const [sponsor, setSponsor] = useState({});

  useEffect(() => {
    if (id) {
      async function fetchSponsor() {
        setLoading(true);
        try {
          const { data } = await api.get(`/api/sponsors/${id}`);
          setSponsor(data);
          setSponsorImg(data?.image_url);
        } catch {
        } finally {
          setLoading(false);
        }
      }

      fetchSponsor();
    }
  }, [id]);

  function imgSponsor(e) {
    const file = e.target.files[0];

    if (!file) {
      setSponsorImg('');
      return;
    }

    setSponsorImg(URL.createObjectURL(file));
  }

  function handleData(data) {
    const formData = new FormData();
    Object.entries(data).map(([key, value]) => {
      if (key === 'image') {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });

    if (id) {
      formData.append('_method', 'put');
    }

    if (!data.image.length) {
      formData.delete('image');
    }

    return formData;
  }

  if (loading) {
    return (
      <Layout>
        <Centered>
          <Loading />
        </Centered>
      </Layout>
    );
  }

  const options = [
    { name: 'Ativo', value: 1 },
    { name: 'Inativo', value: 0 },
  ];

  const types = [
    { name: 'Patrocinador', value: 'sponsor' },
    { name: 'Realização', value: 'realization' },
    { name: 'Apoiadores', value: 'supporters' },
  ];

  const inputs = register => [
    <Select
      name="type"
      ref={register}
      required
      options={types}
      value={sponsor.type ?? 'sponsor'}
      columns={{ md: 4 }}
    />,
    <Input
      inputRef={register}
      label="Nome"
      type="text"
      name="name"
      required
      columns={{ md: 8 }}
    />,
    <Input
      inputRef={register}
      label="Link"
      type="text"
      name="link"
      columns={{ md: 12 }}
    />,
    <Input
      inputRef={register}
      type="file"
      name="image"
      label="Enviar image"
      inputProps={{ accept: 'image/*' }}
      columns={{ md: 6, xs: 12, lg: 6 }}
      onChange={e => {
        imgSponsor(e);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />,
    <Select
      name="active"
      ref={register}
      required
      options={options}
      value={sponsor.id ? (sponsor.active === true ? '1' : '0') : '1'}
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
    <Grid item md={6} xs={12} lg={6}>
      <Img src={sponsorImg} />
    </Grid>,
  ];

  document.title = sponsor.id ? 'Editando patrocinador' : 'Novo patrocinador';

  return (
    <ResourcePageWithForm
      title={sponsor.id ? 'Editando patrocinador' : 'Novo patrocinador'}
      target={sponsor.id ? `/api/sponsors/${sponsor.id}` : '/api/sponsors'}
      inputs={inputs}
      method="post"
      beforeSubmit={handleData}
      submitButtonLabel={sponsor.id ? 'Atualizar' : 'Salvar'}
      location="/settings"
      onSuccess={() =>
        toast.success(
          sponsor.id
            ? 'Registro atualizado com sucesso.'
            : 'Registro realizado com sucesso.',
          { autoClose: 2000 }
        )
      }
      onError={() => {
        toast.error('Não foi possivel realizar registro, tenta novamente.');
      }}
      formInitialState={{
        defaultValues: {
          name: sponsor.name,
          link: sponsor.link,
        },
      }}
    />
  );
}
