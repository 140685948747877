import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { Input, Select, Layout } from '~/components';
import ResourcePageWithForm from '~/layouts/ResourcePageWithForm';
import { Centered } from '~/pages/Participant/components/styles';
import Loading from '~/assets/lottie/Loading';
import api from '~/services/api';

export function EditionForm() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [edition, setEdition] = useState({});

  useEffect(() => {
    if (id) {
      async function fetchEdition() {
        setLoading(true);
        try {
          const { data } = await api.get(`/api/settings/${id}`);
          setEdition(data);
        } catch {
        } finally {
          setLoading(false);
        }
      }

      fetchEdition();
    }
  }, [id]);

  if (loading) {
    return (
      <Layout>
        <Centered>
          <Loading />
        </Centered>
      </Layout>
    );
  }

  const options = [
    { name: 'Ativo', value: 1 },
    { name: 'Inativo', value: 0 },
  ];

  const inputs = register => [
    <Input
      inputRef={register}
      label="Nome"
      type="text"
      name="title"
      required
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label="Ano"
      type="number"
      name="year"
      required
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label="Data Inicio"
      type="datetime-local"
      name="initial_date"
      required
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label="Data Final"
      type="datetime-local"
      name="final_date"
      required
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label="Background do Header"
      type="color"
      name="bg_header"
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label="Cor Texto do Header"
      type="color"
      name="color_text_header"
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label="Link (Mídia Kit)"
      type="text"
      name="link"
      columns={{ md: 12 }}
    />,
    <Select
      name="active"
      ref={register}
      required
      options={options}
      value={edition.id ? (edition.active === true ? '1' : '0') : '1'}
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
  ];

  document.title = edition.id ? 'Editando Edição' : 'Nova Edição';

  return (
    <ResourcePageWithForm
      title={edition.id ? 'Editando Edição' : 'Nova Edição'}
      target={edition.id ? `/api/settings/${edition.id}` : '/api/settings'}
      inputs={inputs}
      method={edition.id ? 'put' : 'post'}
      submitButtonLabel={edition.id ? 'Atualizar' : 'Salvar'}
      location="/settings"
      onSuccess={() =>
        toast.success(
          edition.id
            ? 'Registro atualizado com sucesso.'
            : 'Registro realizado com sucesso.',
          { autoClose: 2000 }
        )
      }
      onError={error => {
        if (error) {
          const { data } = error.response;

          if (!data.success && data.message) {
            return toast.error(data.message);
          }
        }
        toast.error('Não foi possivel realizar registro, tenta novamente.');
      }}
      formInitialState={{
        defaultValues: {
          year: edition.year,
          title: edition.title,
          initial_date: edition.initial_date,
          final_date: edition.final_date,
          bg_header: edition.bg_header,
          color_text_header: edition.color_text_header,
          link: edition.link,
        },
      }}
    />
  );
}
