import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import profile from './profile/sagas';
import company from './company/sagas';
import product from './product/sagas';
import coupon from './coupon/sagas';
import order from './order/sagas';
import setting from './setting/sagas';

export default function* rootSaga() {
  return yield all([auth, profile, company, product, coupon, order, setting]);
}
