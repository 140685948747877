import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* height: 100vh; */
  margin-top: 10rem;
  margin-bottom: 5rem;
`;

export const Title = styled.h2`
  font-weight: ${({ weight }) => weight || 300};

  margin-left: 5rem;
  margin-right: 5rem;

  ${({ size, textAlign }) =>
    css`
      font-size: ${size}px;
      text-align: ${textAlign};
    `}

  @media only screen and (max-width: 500px) {
    margin-left: 2rem;
    margin-right: 1rem;
    font-size: 19px;
  }
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

export const Divider = styled.hr`
  max-width: 150px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const Text = styled.p`
  /* text-indent: 10px; */
  text-align: justify;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 500px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;
