import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ResourcesPageWithTable from '~/layouts/ResourcesPageWithTable';
import { fetchProfilesRequested } from '~/store/modules/profile/actions';
import { navigateTo } from '~/services/history';

export default function CuponsList() {
  const dispatch = useDispatch();
  const { loading, /* error, */ profiles } = useSelector(
    state => state.profile
  );

  useEffect(() => {
    dispatch(fetchProfilesRequested());
  }, [dispatch]);

  const columns = [
    { title: 'Empresa', field: 'company' },
    { title: 'Codigo', field: 'code' },
    { title: 'Criado', field: 'created_at' },
  ];

  const actions = [
    {
      icon: 'edit',
      onClick: (event, user) => navigateTo(`/users/${user.id}/edit`),
    },
    {
      icon: 'arrow_right',
      onClick: (event, user) => navigateTo(`/users/${user.id}`),
    },
  ];

  return (
    <ResourcesPageWithTable
      title="Cupons"
      header={{
        action: {
          label: 'Novo Cupom',

          props: {
            onClick: () => navigateTo('/coupons/create'),
            style: {
              backgroundColor: '#5BB60A',
              color: 'white',
            },
          },
        },
      }}
      loading={loading}
      // error={error}
      columns={columns}
      rows={profiles.data}
      actions={actions}
      paginable={{
        page: profiles.page,
        lastPage: profiles.lastPage,
        onPageChange: page => dispatch(fetchProfilesRequested(page)),
      }}
      onSearchChange={term =>
        dispatch(fetchProfilesRequested(profiles.page, term))
      }
    />
  );
}
