import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.04);
`;
