import {
  FETCH_PROFILES_REQUESTED,
  FETCH_PROFILES_SUCCEEDED,
  FETCH_PROFILES_FAILED,
  FETCH_PROFILE_REQUESTED,
  FETCH_PROFILE_SUCCEEDED,
  FETCH_PROFILE_FAILED,
  CLEAR_PROFILE,
} from './types';

export const fetchProfilesRequested = (page = 1, term = null) => ({
  type: FETCH_PROFILES_REQUESTED,
  payload: {
    page,
    term,
  },
});

export const fetchProfilesSucceded = profiles => ({
  type: FETCH_PROFILES_SUCCEEDED,
  payload: {
    profiles,
  },
});

export const fetchProfilesFailed = error => ({
  type: FETCH_PROFILES_FAILED,
  payload: {
    error,
  },
});

export const fetchProfileRequested = id => ({
  type: FETCH_PROFILE_REQUESTED,
  payload: {
    id,
  },
});

export const fetchProfileSucceded = profile => ({
  type: FETCH_PROFILE_SUCCEEDED,
  payload: {
    profile,
  },
});

export const fetchProfileFailed = error => ({
  type: FETCH_PROFILE_FAILED,
  payload: {
    error,
  },
});

export const clearProfile = () => ({
  type: CLEAR_PROFILE,
});
