import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import * as Icon from 'react-feather';

export default function SimpleModal({
  open,
  onClose,
  children,
  title = '',
  width,
}) {
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: width || 500,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      borderRadius: 8,
      outline: 'none',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    text: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 5,
      borderBottom: '1px solid #cecece',
    },
  }));

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ overflow: 'scroll' }}
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.text}>
            <h3 id="simple-modal-title">{title}</h3>
            <CloseButton
              variant="contained"
              disableElevation
              onClick={() => onClose()}
            >
              <Icon.X size={18} color="#000" style={{ marginRight: 6 }} />
            </CloseButton>
          </div>
          <Body>{children}</Body>
        </div>
      </Modal>
    </div>
  );
}

const CloseButton = styled(IconButton)`
  height: 20px !important;
  background-color: #fff !important;
`;

const Body = styled.div`
  margin-top: 14px;
`;
