import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10rem;
  margin-bottom: 5rem;
`;

export const ContainerForm = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 390px;
  width: 100%;
  max-width: 520px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.08);
`;

export const Title = styled.h2`
  font-weight: 300;
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

export const Divider = styled.hr`
  max-width: 150px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const Form = styled.form`
  flex: 1;
  padding: 20px;
  max-width: 450px;
`;
