import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  flex-direction: column;
  height: 60vh;
  margin-bottom: 32px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.04);
`;

export const ChartTitle = styled.h3`
  font-weight: 300;
`;

ChartTitle.Container = styled.div`
  padding: 15px 24px;
`;

ChartTitle.Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 24px;
  background-color: #d9d9d9;
`;

export const Fieldset = styled.fieldset`
  border: 1px solid #cecece;
  padding: 6px;
`;

Fieldset.legend = styled.legend`
  padding: 5px;
`;

Fieldset.gridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 5px;
`;

Fieldset.label = styled.label`
  font-size: 13px;
  font-weight: 600;
  /* padding: 5px; */
`;

Fieldset.text = styled.text`
  font-size: 15px;
`;
