import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const SearchGroup = styled.div`
  display: flex;
  flex: 1;
  height: 50px;
  background-color: white;
  align-items: center;
  margin-bottom: -15px;
  box-shadow: 0 1px 2px 1px #cccc;
`;

export const Search = styled.input`
  flex: 1;
  font-size: 16px;
  height: 50px;
  font-weight: 300;
  border: none;
  outline: none;

  &:placeholder {
    color: #a3a3a3;
  }
`;
