/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import * as S from './styles';

import api from '~/services/api';
import { navigateTo } from '~/services/history';

export function AccountValidation() {
  const { token } = useParams();
  const [message, setMessage] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.post('api/account-validation', {
          token,
        });

        if (!data.success) {
          throw data;
        }

        if (data.success) {
          setMessage(data.message);
          setTimeout(() => {
            navigateTo('/');
          }, 3000);
        }
      } catch (error) {
        if (!error) return;
        setMessage(error.message);
      }
    })();
  }, []);

  return (
    <S.Container>
      <S.Image src={require('~/assets/images/logo_cdl.webp')} alt="logo" />

      <S.Header>
        <h2>Ativação de conta</h2>
        <span>{message}</span>
      </S.Header>
    </S.Container>
  );
}
