/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';

import { Img, ButtonRemove } from './styles';

import { Input, Select, Layout, Centered } from '~/components';
import ResourcePageWithForm from '~/layouts/ResourcePageWithForm';
import { fetchCompanyRequested } from '~/store/modules/company/actions';
import { cnpjMask, telefoneMask, cepMask } from '~/utils';
import * as Env from '~/.env';
import Loading from '~/assets/lottie/Loading';
import api from '~/services/api';

export default function CompanyForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, company } = useSelector(state => state.company);
  const { profile: user } = useSelector(state => state.auth);
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();

  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');

  const [maskCNPJ, setMaskCNPJ] = useState();
  const [maskTelefone, setMaskTelefone] = useState();
  const [maskWhatsapp, setMaskWhatsapp] = useState();
  const [maskCep, setMaskCep] = useState();

  const [planosTypes, setPlanosTypes] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(fetchCompanyRequested(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setAddress(company?.address);
    setNeighborhood(company?.neighborhood);
  }, [company]);

  useEffect(() => {
    api.get('/api/plans').then(response => {
      setPlanosTypes(response.data);
    });
  }, []);

  // Remover as imagem sem reload na página.
  useEffect(() => {
    setLogo(
      company.id && company.files.find(file => file.file_type_id === 1)
        ? `${Env.API_URL}/storage/${
            company.files.find(file => file.file_type_id === 1).filename
          }`
        : null
    );

    setBanner(
      company.id && company.files.find(file => file.file_type_id === 2)
        ? `${Env.API_URL}/storage/${
            company.files.find(file => file.file_type_id === 2).filename
          }`
        : null
    );
  }, [company.files, company.id]);

  const statusPageTypes = [
    { name: 'Em análise', value: 0 },
    { name: 'Publicado', value: 1 },
  ];

  const city = [
    { name: 'Alto Araguaia', value: 5186 },
    { name: 'Alto Garças', value: 5188 },
    { name: 'Alto Taquari', value: 5190 },
    { name: 'Itiquira', value: 5236 },
    { name: 'Jaciara', value: 5237 },
    { name: 'Juscimeira', value: 5243 },
    { name: 'Rondonópolis', value: 5292 },
    { name: 'Pedra Preta', value: 5273 },
  ];

  function avatarLogo(e) {
    const file = e.target.files[0];

    if (!file) {
      setLogo('');
      return;
    }

    setLogo(URL.createObjectURL(file));
  }

  function bannerCompanie(e) {
    const file = e.target.files[0];

    if (!file) {
      setBanner('');
      return;
    }

    setBanner(URL.createObjectURL(file));
  }

  function pesquisaCep(value) {
    const cep = value.replace(/\D/g, '');
    if (cep !== '') {
      const validacep = /^[0-9]{8}$/;
      if (validacep.test(cep)) {
        axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(response => {
          const { data } = response;
          if (data.erro) {
            return false;
          }
          setAddress(data.logradouro);
          setNeighborhood(data.bairro);
        });
      }
    }
  }

  function handleData(data) {
    const formData = new FormData();
    // eslint-disable-next-line array-callback-return
    Object.entries(data).map(([key, value]) => {
      if (key === 'avatar_logo' || key === 'banner') {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });

    return formData;
  }

  async function handleRemoveFile(id, file_type_id) {
    try {
      if (id) {
        await api.delete(`api/companies/${id}/files`, {
          data: { file_type_id },
        });
        toast.success('Arquivo removido com sucesso!');
      }

      if (file_type_id === 1) {
        setLogo('');
      }

      if (file_type_id === 2) {
        setBanner('');
      }
    } catch (e) {}
  }

  if (loading) {
    return (
      <Layout>
        <Centered>
          <Loading />
        </Centered>
      </Layout>
    );
  }

  const inputs = register => [
    <Input
      inputRef={register}
      label="Razão Social / Nome"
      type="text"
      name="social_reason"
      required
    />,
    <Input
      inputRef={register}
      label="Nome Fantasia"
      type="text"
      name="fantasy"
      required
    />,
    <Input
      inputRef={register}
      label="CNPJ"
      type="text"
      name="cnpj"
      required
      value={maskCNPJ}
      onChange={({ target }) => {
        setMaskCNPJ(cnpjMask(target.value));
      }}
      columns={{ md: 4, xs: 12, lg: 4 }}
    />,
    <Input
      inputRef={register}
      label="E-mail"
      type="email"
      name="email"
      required
      columns={{ md: 4, xs: 12, lg: 4 }}
    />,
    <Input
      inputRef={register}
      label="Fone"
      type="text"
      name="phone"
      required
      value={maskTelefone}
      onChange={({ target }) => {
        setMaskTelefone(telefoneMask(target.value));
      }}
      columns={{ md: 4, xs: 12, lg: 4 }}
    />,
    <Input
      inputRef={register}
      label="WhatsApp"
      type="text"
      name="whatsapp"
      value={maskWhatsapp}
      onChange={({ target }) => {
        setMaskWhatsapp(telefoneMask(target.value));
      }}
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
    <Input
      inputRef={register}
      label="Site"
      type="text"
      name="website"
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
    <Input
      inputRef={register}
      label="Facebook"
      type="text"
      name="facebook"
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
    <Input
      inputRef={register}
      label="Instagram"
      type="text"
      name="instagram"
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,

    <input
      ref={register}
      label="Cor"
      type="hidden"
      name="main_color"
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
    <Input
      inputRef={register}
      label="CEP"
      type="text"
      value={maskCep}
      name="cep"
      required
      onBlur={({ target }) => pesquisaCep(target.value)}
      onChange={({ target }) => {
        setMaskCep(cepMask(target.value));
      }}
      columns={{ md: 3, xs: 12, lg: 3 }}
    />,
    <Input
      inputRef={register}
      label="Endereço"
      type="text"
      name="address"
      value={address}
      onChange={({ target }) => {
        setAddress(target.value);
      }}
      required
      columns={{ md: 5, xs: 12, lg: 5 }}
    />,
    <Input
      inputRef={register}
      label="Numero"
      type="text"
      name="address_number"
      required
      columns={{ md: 4, xs: 12, lg: 4 }}
    />,
    <Input
      inputRef={register}
      label="Bairro"
      type="text"
      name="neighborhood"
      value={neighborhood}
      onChange={({ target }) => {
        setNeighborhood(target.value);
      }}
      required
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
    <Select
      name="city_id"
      label="Selecione Cidade"
      ref={register}
      required
      value={company?.city_id}
      options={city}
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
    <Input
      inputRef={register}
      type="file"
      name="avatar_logo"
      label="Enviar Logo (200x200)"
      inputProps={{ accept: 'image/png' }}
      columns={{ md: 6, xs: 12, lg: 6 }}
      onChange={e => avatarLogo(e)}
      InputLabelProps={{
        shrink: true,
      }}
    />,
    <Input
      inputRef={register}
      type="file"
      name="banner"
      label="Enviar Banner (1280x233)"
      inputProps={{ accept: 'image/png' }}
      columns={{ md: 6, xs: 12, lg: 6 }}
      onChange={e => bannerCompanie(e)}
      InputLabelProps={{
        shrink: true,
      }}
    />,
    <Grid item md={6} xs={12} lg={6}>
      <Img src={logo}>
        <ButtonRemove onClick={() => handleRemoveFile(company.id, 1)}>
          Remover
        </ButtonRemove>
      </Img>
    </Grid>,
    <Grid item md={6} xs={12} lg={6}>
      <Img src={banner} banner>
        <ButtonRemove onClick={() => handleRemoveFile(company.id, 2)}>
          Remover
        </ButtonRemove>
      </Img>
    </Grid>,
    user.profile_id !== 2 && user.profile_id !== 3 ? (
      <Select
        label="Selecione Plano"
        name="plan_id"
        options={planosTypes.map(plan => ({
          value: plan.id,
          name: plan.description,
        }))}
        ref={register}
        required
        value={company?.plan_id}
        columns={{ md: 6, xs: 12, lg: 6 }}
      />
    ) : (
      <Input
        type="hidden"
        name="plan_id"
        value={company?.plan_id}
        inputRef={register}
        columns={{ md: 6 }}
      />
    ),
    user.profile_id !== 2 && user.profile_id !== 3 ? (
      <Select
        label="Status Página"
        value={company?.is_active ? company?.is_active : '0'}
        name="is_active"
        options={statusPageTypes}
        ref={register}
        required
        columns={{ md: 6, xs: 12, lg: 6 }}
      />
    ) : (
      <Input
        type="hidden"
        name="is_active"
        value={company?.is_active}
        inputRef={register}
        columns={{ md: 6 }}
      />
    ),
  ];

  return (
    <ResourcePageWithForm
      title={company.id ? 'Editando empresa' : 'Nova Empresa'}
      target={
        company.id ? `/api/companies/${company.id}/update` : '/api/companies'
      }
      method="post"
      inputs={inputs}
      loading={loading}
      submitButtonLabel={company.id ? 'Atualizar' : 'Salvar'}
      location={company.id ? `/companies/${company.id}/edit` : '/companies'}
      beforeSubmit={handleData}
      onSuccess={() =>
        toast.success(
          company.id
            ? 'Registro atualizado com sucesso.'
            : 'Empresa criada com sucesso.',
          { autoClose: 2000 }
        )
      }
      onError={({ response }) => {
        const { errors } = response.data;
        if (errors && errors.length) {
          toast.error(errors[0]);
        }

        // eslint-disable-next-line dot-notation
        if (errors && errors['avatar_logo']) {
          // eslint-disable-next-line dot-notation
          toast.error(errors['avatar_logo'][0]);
        }

        // eslint-disable-next-line dot-notation
        if (errors && errors['banner']) {
          // eslint-disable-next-line dot-notation
          toast.error(errors['banner'][0]);
        }
      }}
      formInitialState={{
        defaultValues: {
          social_reason: company.social_reason,
          fantasy: company?.fantasy,
          phone: company?.phone,
          cnpj: company?.cnpj,
          email: company?.email,
          website: company?.website,
          whatsapp: company?.whatsapp,
          facebook: company?.facebook,
          instagram: company?.instagram,
          main_color: company?.main_color,
          cep: company?.cep,
          address: company?.address,
          address_number: company?.address_number,
          neighborhood: company?.neighborhood,
        },
      }}
    />
  );
}
