import { all, takeLatest, call, put } from 'redux-saga/effects';

import { fetchOrdersSucceded, fetchOrdersFailed } from './actions';
import { FETCH_ORDERS_REQUESTED } from './types';

import api from '~/services/api';
import { toDinheiro } from '~/utils';

const status = {
  1: 'Em andamento',
  2: 'Pedido Efetuado',
  3: 'Pedido Finalizado',
  4: 'Pedido Cancelado',
};

function* fetchOrders({ payload }) {
  const { page, term, id, type } = payload;
  const target = `/api/orders?page=${page}&company_id=${id}&type=${type}${
    term ? `&search=${term}` : ''
  }`;

  try {
    const response = yield call(api.get, target);

    if (response.status !== 200) {
      throw response;
    }

    const newData = {
      ...response.data,
      data: response.data.data.map(order => {
        return {
          ...order,
          amount: toDinheiro(order.total),
          status: status[order.status],
          status_int: order.status,
          method_send_description:
            order.method_send === 1 ? 'Entrega' : 'Retira na loja',
        };
      }),
    };

    yield put(fetchOrdersSucceded(newData));
  } catch (error) {
    yield put(fetchOrdersFailed(error));
  }
}

export default all([takeLatest(FETCH_ORDERS_REQUESTED, fetchOrders)]);
