/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { goToTop } from 'react-scrollable-anchor';
import * as Icon from 'react-feather';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Container } from './styles';

import { Select, SimpleHeader } from '~/components';
import history, { navigateTo } from '~/services/history';
import api from '~/services/api';
import { toDinheiro } from '~/utils';

const MethodPayments = [
  { name: 'Dinheiro', value: 1 },
  { name: 'Cartão de Crédito', value: 2 },
];

export function CheckOut() {
  const { profile: user } = useSelector(state => state.auth);
  const [orders, setOrders] = useState();
  const [payment, setPayment] = useState();
  const [loading, setLoading] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);

  const getOrders = useCallback(async () => {
    setLoading(false);
    const { data } = await api.get('/api/cart');
    const { order } = data;
    if (order && order.status) {
      return setOrders(data.order);
    }

    return user.role === 'cdl' || user.role === 'empresas'
      ? navigateTo('/dashboard')
      : navigateTo('/profile');
  }, [user.role]);

  useEffect(() => {
    document.title = 'LIQUIDAQUI 2024 - Meu carrinho - Finalizar Compra';
    goToTop();
  }, []);

  async function checkout() {
    if (!payment) {
      return toast.warn('Por favor, selecione a forma de pagamento!');
    }
    setIsSubmited(true);
    try {
      const { data } = await api.put(`/api/cart/${orders?.order_id}/update`, {
        step: 'checkout',
        payment_id: Number(payment),
      });

      if (data) {
        toast.success('Pedido efetuado com sucesso!');
        return user.role === 'cdl' || user.role === 'empresas'
          ? navigateTo('/dashboard')
          : navigateTo('/profile');
      }
    } finally {
      setIsSubmited(false);
    }
  }

  useEffect(() => {
    getOrders();
  }, [loading]);

  return (
    <>
      <SimpleHeader />

      <Container>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '80%' }}>
            <Button
              variant="contained"
              disableElevation
              onClick={history.goBack}
              style={{ marginBottom: 10 }}
            >
              <Icon.ChevronLeft />
              Voltar
            </Button>
            <Container.Cart>
              <TableContainer component={Paper}>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={4}
                        style={{
                          border: 'none',
                        }}
                      >
                        <h5 style={{ fontSize: 22 }}>Finalizar Pedido</h5>
                        <p style={{ fontSize: 20 }}>#{orders?.order_id}</p>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3} style={{ fontWeight: 'bold' }}>
                        Descrição
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: 'bold' }}>
                        Valor
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders &&
                      orders?.items.map(item => (
                        <TableRow key={String(item.id)}>
                          <TableCell colSpan={3}>{item.description}</TableCell>
                          <TableCell align="right">
                            {toDinheiro(item.amount)}
                          </TableCell>
                        </TableRow>
                      ))}

                    <TableRow>
                      <TableCell colSpan={2} style={{ border: 'none' }} />
                      <TableCell
                        align="right"
                        style={{ fontWeight: 'bold', border: 'none' }}
                      >
                        SubTotal
                      </TableCell>
                      <TableCell align="right" style={{ border: 'none' }}>
                        {toDinheiro(orders?.sub_total)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {orders?.discount ? (
                        <>
                          <TableCell colSpan={2} style={{ border: 'none' }} />
                          <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', border: 'none' }}
                          >
                            Cupom (-)
                          </TableCell>
                          <TableCell align="right" style={{ border: 'none' }}>
                            {toDinheiro(orders?.discount)}
                          </TableCell>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                    <TableRow>
                      {orders?.total_shipping ? (
                        <>
                          <TableCell colSpan={2} style={{ border: 'none' }} />
                          <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', border: 'none' }}
                          >
                            Frete (+)
                          </TableCell>
                          <TableCell align="right" style={{ border: 'none' }}>
                            {toDinheiro(orders?.total_shipping)}
                          </TableCell>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} style={{ border: 'none' }} />
                      <TableCell
                        align="right"
                        style={{ fontWeight: 'bold', border: 'none' }}
                      >
                        Total
                      </TableCell>
                      <TableCell align="right" style={{ border: 'none' }}>
                        {toDinheiro(orders?.total)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper} style={{ marginTop: 15 }}>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow />
                    <TableRow>
                      <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                        {orders?.method_send === 2
                          ? 'Endereço para retirar no local'
                          : 'Endereço de entrega'}
                      </TableCell>

                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        {orders?.method_send === 2
                          ? orders?.company_address
                          : orders?.user_address}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 20,
                }}
              >
                <Select
                  name="payment_id"
                  label="Selecione Forma de pagamento"
                  options={MethodPayments}
                  onChange={({ target }) => setPayment(target.value)}
                  columns={{ md: 3, xs: 12, lg: 3 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ marginLeft: 10 }}
                  onClick={checkout}
                  disabled={!payment || isSubmited}
                >
                  {isSubmited ? 'Enviando...' : 'Finalizar Compra'}
                  <Icon.CheckCircle style={{ marginLeft: 10 }} />
                </Button>
              </div>
            </Container.Cart>
          </div>
        </div>
      </Container>
    </>
  );
}
