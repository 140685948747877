import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* padding: 62px; */
  padding-left: 62px;
  padding-right: 62px;
  padding-bottom: 31px;
  margin-top: 60px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.06);
`;

export const Title = styled.h3`
  font-weight: 300;
  margin-top: 31px;
  margin-bottom: 31px;
`;
