import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  token: null,
  profile: null,
  signed: false,
  loading: false,
  message: null,
  errors: [],
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SIGN_IN.REQUEST: {
        draft.loading = true;
        draft.message = null;
        draft.errors = [];
        break;
      }
      case Types.SIGN_IN.SUCCESS: {
        draft.token = action.payload.token;
        draft.profile = action.payload.user;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case Types.SIGN_IN.FAILURE: {
        draft.loading = false;
        draft.message = action.payload.message;
        draft.errors = action.payload.errors;
        break;
      }
      case Types.SIGN_OUT: {
        draft.token = null;
        draft.signed = false;
        draft.profile = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
