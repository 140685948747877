/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import * as Icon from 'react-feather';

import { Img, InputCheckbox, ButtonRemove } from './styles';

import { Input, Layout, Centered, Select } from '~/components';
import ResourcePageWithForm from '~/layouts/ResourcePageWithForm';
import { fetchProductRequested } from '~/store/modules/product/actions';
import Loading from '~/assets/lottie/Loading';
import api from '~/services/api';

const thumb = {
  position: 'relative',
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 200,
  padding: 4,
  boxSizing: 'border-box',
  marginTop: 20,
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const active = [
  { name: 'Ativado', value: 1 },
  { name: 'Desativado', value: 0 },
];

export default function ProductsForm() {
  const { id, company_id } = useParams();
  const dispatch = useDispatch();
  const { loading, product } = useSelector(state => state.product);
  const { profile } = useSelector(state => state.auth);
  const [productImg, setProductImg] = useState();
  const [files, setFiles] = useState([]);

  let company = company_id;

  if (profile.role === 'empresas') {
    company = !company ? profile.company_id : company;
  } else if (profile.role === 'cdl') {
    company = !company ? product.company_id : company;
  } else {
    company = company_id;
  }

  const time = new Date().getTime();

  useEffect(() => {
    if (id) {
      dispatch(fetchProductRequested(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setProductImg(id && product.product_url ? product.product_url : '');
  }, [id, product.product_url]);

  useEffect(() => {
    if (id) {
      product.files &&
        setFiles(
          product.files.map(file => {
            return {
              id: file.id,
              preview: file.name,
              name: `${file.name}-${time}`,
            };
          })
        );
    }
  }, [id, product.files]);

  function imgProduct(e) {
    const file = e.target.files[0];

    if (!file) {
      setProductImg('');
      return;
    }

    setProductImg(URL.createObjectURL(file));
  }

  function handleData(data) {
    const formData = new FormData();

    // eslint-disable-next-line array-callback-return
    Object.entries(data).map(([key, value]) => {
      if (key === 'imagem_product') {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });

    files.map(file => {
      if (file.path) {
        formData.append('files[]', file);
      }
    });

    return formData;
  }

  async function handleRemoveFile(
    product_id,
    company_id,
    file_type_id,
    imageDefault = true,
    fileId = null,
    isPath = false,
    isUploadNew = false
  ) {
    try {
      if (product_id && company_id && !isUploadNew) {
        await api.delete(`api/products/${product_id}/files`, {
          data: { company_id, file_type_id, default: imageDefault, fileId },
        });
        toast.success('Arquivo removido com sucesso!');
      }

      if (imageDefault) {
        return setProductImg('');
      }

      let newFiles;

      if (isPath) {
        newFiles = files.filter(file => file.preview !== isPath);
      }

      setFiles(newFiles);
    } catch (e) {}
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(oldFiles => [
        ...oldFiles,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  if (loading) {
    return (
      <Layout>
        <Centered>
          <Loading />
        </Centered>
      </Layout>
    );
  }

  const inputs = register => [
    <input type="hidden" name="company_id" value={company} ref={register} />,

    <Input
      inputRef={register}
      label="Descrição"
      type="text"
      name="description"
      multiline
      required
    />,
    <Input
      inputRef={register}
      label="Valor normal do Produto"
      type="text"
      name="value"
      onChange={({ target }) => {
        let valor = target.value;

        valor = valor.replace(/\D/, '');
        valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
        valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');

        target.value = valor;
      }}
      required
      columns={{ md: 3, xs: 12, lg: 3 }}
    />,
    <Input
      inputRef={register}
      label="Valor do frete"
      type="text"
      name="shipping_cost"
      onChange={({ target }) => {
        let valor = target.value;

        valor = valor.replace(/\D/, '');
        valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
        valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');

        target.value = valor;
      }}
      columns={{ md: 3, xs: 12, lg: 3 }}
    />,
    <Grid item md={6}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 5,
        }}
      >
        <InputCheckbox
          id="checkbox1"
          type="checkbox"
          className="checkbox"
          defaultChecked={!!product.allow_sale}
          ref={register}
          name="allow_sale"
        />
        <label htmlFor="checkbox1">Permite venda online?</label>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 5,
          }}
        >
          <InputCheckbox
            id="checkbox1"
            type="checkbox"
            className="checkbox"
            defaultChecked={!!product.in_cash}
            ref={register}
            name="in_cash"
          />
          <label htmlFor="checkbox1">Válido apenas p/ pgto á vista?</label>
        </div>
      </div>
    </Grid>,

    <Input
      inputRef={register}
      type="file"
      name="imagem_product"
      label="Imagem do produto principal"
      columns={{ md: 6, xs: 12, lg: 6 }}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={e => {
        imgProduct(e);
      }}
    />,
    <Select
      name="active"
      value={product.id ? (product.active === true ? '1' : '0') : '1'}
      options={active}
      ref={register}
      required
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
    productImg ? (
      <Grid item md={12}>
        <Img src={productImg}>
          <ButtonRemove
            onClick={() => handleRemoveFile(product.id, product.company_id, 3)}
          >
            Remover
          </ButtonRemove>
        </Img>
      </Grid>
    ) : (
      <></>
    ),
    <Grid item md={12}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} type="file" name="file" />
        <p>
          Fotos adicionais do produto: Arraste e solte os arquivos aqui ou para
          selecionar
        </p>
      </div>
      {files.map(file => (
        <div style={thumb} key={file?.preview}>
          <div style={thumbInner}>
            <button
              type="button"
              onClick={() =>
                handleRemoveFile(
                  product.id,
                  product.company_id,
                  3,
                  false,
                  file,
                  file.preview,
                  file.path
                )
              }
              style={{
                position: 'absolute',
                right: 10,
                top: 10,
                border: 'none',
                backgroundColor: 'rgba(0,0,0,0.45)',
                padding: 3,
                borderRadius: 8,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon.X color="#fff" />
            </button>
            <img src={file?.preview} style={img} alt={file} />
          </div>
        </div>
      ))}
    </Grid>,
  ];

  return (
    <ResourcePageWithForm
      title={id ? 'Editando produto' : 'Adicionar novo Produto'}
      target={id ? `/api/products/${product.id}` : '/api/products'}
      inputs={inputs}
      beforeSubmit={handleData}
      loading={loading}
      location="/products"
      submitButtonLabel="Salvar"
      onSuccess={() => toast.success('Produto criado com sucesso.')}
      onError={({ response }) => {
        const { errors } = response.data;
        if (errors && errors.length) {
          toast.error(errors[0]);
        }

        // eslint-disable-next-line dot-notation
        if (errors && errors['imagem_product']) {
          // eslint-disable-next-line dot-notation
          toast.error(errors['imagem_product'][0]);
        }
      }}
      formInitialState={{
        defaultValues: {
          company_id: id ? product?.company_id : company_id,
          description: id ? product?.description : '',
          value: id ? product?.value : '',
          shipping_cost: id ? product?.shipping_cost : '',
          allow_sale: id ? Boolean(product?.allow_sale) : true,
          in_cash: id ? Boolean(product?.in_cash) : false,
        },
      }}
    />
  );
}
