import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: flex-end;
  align-items: center;
  background: #f6f6f6;
  margin-top: 10rem;
  padding: 0 35px;
`;

export const Title = styled.h2`
  font-size: 12px;
  color: #383838;
  font-weight: 500;
  margin-right: 10px;
`;

export const Logo = styled.img`
  width: 120px;
  height: 34px;
`;
