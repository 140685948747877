/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { Input, Select, Layout, Centered } from '~/components';
import ResourcePageWithForm from '~/layouts/ResourcePageWithForm';
import { fetchProfileRequested } from '~/store/modules/profile/actions';
import { cpfMask, telefoneMask } from '~/utils';
import Loading from '~/assets/lottie/Loading';

export default function UserForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, profile } = useSelector(state => state.profile);
  const { profile: user } = useSelector(state => state.auth);
  const [companies, setCompanies] = useState([]);
  const [hiddenCompany, setHiddenCompany] = useState(false);
  const [maskCPF, setMaskCPF] = useState();
  const [maskTelefone, setMaskTelefone] = useState();

  const userTypes = [
    { name: 'CDL', value: 1 },
    { name: 'Empresa', value: 2 },
    { name: 'Cliente', value: 3 },
  ];

  useEffect(() => {
    if (id) {
      dispatch(fetchProfileRequested(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    profile?.profile_id === 2 && setHiddenCompany(true);
  }, [profile]);

  const loadCompanies = async () => {
    const { data } = await api.get('/api/companiesAll');

    setCompanies(data);
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  if (loading) {
    return (
      <Layout>
        <Centered>
          <Loading />
        </Centered>
      </Layout>
    );
  }

  const inputs = register => [
    user.profile_id !== 2 && user.profile_id !== 3 ? (
      <Select
        name="profile_id"
        label="Selecione Perfil"
        ref={register({ required: true })}
        options={userTypes}
        columns={{ md: 6 }}
        value={id ? profile?.profile_id : ''}
        onChange={({ target }) => {
          if (target.value === '2') {
            setHiddenCompany(true);
          } else {
            setHiddenCompany(false);
          }
        }}
      />
    ) : (
      <Input
        type="hidden"
        name="profile_id"
        value={profile?.profile_id}
        inputRef={register}
        columns={{ md: 6 }}
      />
    ),
    user.profile_id !== 2 && user.profile_id !== 3 ? (
      <Select
        label="Seleciona Empresa"
        options={companies.map(company => ({
          value: company.id,
          name: company.fantasy,
        }))}
        ref={register}
        visible={hiddenCompany}
        value={profile?.company_id}
        required={hiddenCompany}
        name="company_id"
        columns={{ md: 6 }}
      />
    ) : (
      <Input
        type="hidden"
        name="company_id"
        value={profile?.company_id}
        inputRef={register}
        columns={{ md: 6 }}
      />
    ),
    <Input inputRef={register} label="Nome" type="text" name="name" required />,
    <Input
      inputRef={register}
      label="CPF"
      type="text"
      name="cpf"
      required
      value={maskCPF}
      onChange={({ target }) => {
        setMaskCPF(cpfMask(target.value));
      }}
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label="Celular"
      type="text"
      name="phone"
      value={maskTelefone}
      onChange={({ target }) => {
        setMaskTelefone(telefoneMask(target.value));
      }}
      columns={{ xs: 12, md: 6 }}
    />,
    <Input
      inputRef={register}
      label="E-mail"
      type="email"
      name="email"
      required
      columns={{ md: 6 }}
    />,
    <Input
      inputRef={register}
      label={id ? 'Alterar Senha' : 'Senha'}
      type="password"
      name="password"
      columns={{ md: 6 }}
      required={!id}
    />,
    user.profile_id === 1 ? (
      <Input
        type="hidden"
        name="profile"
        value="CDL"
        inputRef={register}
        columns={{ md: 6 }}
      />
    ) : (
      <></>
    ),
  ];

  return (
    <ResourcePageWithForm
      title="Novo usuário"
      target={id ? `/api/users/${profile.id}` : '/api/register'}
      inputs={inputs}
      method={id ? 'put' : 'post'}
      loading={loading}
      submitButtonLabel="Salvar"
      location={
        user.profile_id !== 2 && user.profile_id !== 3
          ? '/users'
          : `/users/${profile.id}`
      }
      onSuccess={() =>
        toast.success(
          id
            ? 'Registro atualizado com sucesso.'
            : 'Usuário criado com sucesso.',
          { autoClose: 2000 }
        )
      }
      onError={({ response }) => {
        const { errors } = response.data;

        if (errors) {
          toast.error(errors.cpf ? errors.cpf[0] : errors.email[0]);
        }
      }}
      formInitialState={{
        defaultValues: {
          name: id ? profile?.name : '',
          cpf: id ? profile?.cpf : '',
          email: id ? profile?.email : '',
          phone: id ? profile?.phone : '',
          company_id: id ? profile?.company_id : '',
          profile_id: id ? profile?.profile_id : '',
        },
      }}
    />
  );
}
