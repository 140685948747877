/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';

// Components
import { Layout, Card, Input } from '~/components';
// Services
import api from '~/services/api';
import { navigateTo } from '~/services/history';

export default function ResourcePageWithForm({
  title,
  inputs,
  target,
  method,
  onSubmit,
  handleSubmitted,
  formInitialState,
  submitButton,
  submitButtonProps,
  submitButtonLabel,
  location,
  beforeSubmit,
  onSuccess,
  onError,
  children,
}) {
  /**
   * Registra os componentes não suportados pelo elemento "input" padrão.
   * Sua renderização ocorre com base na propriedade "type" especificada.
   *
   * Exemplo:
   * { type: 'select', options: meusItens }  ->  <MySelectComponent options={meusItens} />
   */
  const customFormElements = {
    // select: MySelectComponent,
  };
  const { register, errors, handleSubmit, formState } = useForm(
    formInitialState
  );
  const { isSubmitting, isSubmitted } = formState;

  // Define uma ação padrão para o formulário (se não houver).
  if (!onSubmit) {
    onSubmit = async data => {
      if (beforeSubmit) {
        data = beforeSubmit(data);
      }

      try {
        const response = await api[method](target, data);

        if (response.status !== 200) {
          throw response;
        }

        if (onSuccess) {
          onSuccess(response);
          navigateTo(location);
        }
      } catch (error) {
        if (onError) {
          onError(error);
        }
      }
    };
  }

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  useEffect(() => {
    if (handleSubmitted && isSubmitted) {
      handleSubmitted();
    }
  }, [handleSubmitted, isSubmitted]);

  /**
   * Renderiza o campo no formulário.
   *
   * @param {Object|HTMLElement} FieldToRender
   * @returns {HTMLElement}
   */
  const renderInput = FieldToRender => {
    if (isValidElement(FieldToRender)) {
      return FieldToRender;
    }

    const { registerable, columns, ...props } = FieldToRender;
    const CustomComponent = customFormElements[props.type] ?? false;

    return CustomComponent ? (
      <CustomComponent
        ref={register(registerable)}
        error={Boolean(errors[props.name])}
        helperText={errors[props.name]?.message}
        columns={columns}
        {...props}
      />
    ) : (
      <Input
        inputRef={register(registerable)}
        error={Boolean(errors[props.name])}
        helperText={errors[props.name]?.message}
        columns={columns}
        {...props}
      />
    );
  };

  /**
   * Renderiza um componente filho do formulário.
   */
  const renderChildren = () => {
    if (!children) {
      return;
    }

    if (typeof children === 'function') {
      return children(register);
    }

    return children;
  };

  return (
    <Layout>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            {inputs(register).map(renderInput)}

            {renderChildren()}

            <Grid item xs={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {submitButton ?? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={isSubmitting}
                    {...submitButtonProps}
                  >
                    {submitButtonLabel}
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Layout>
  );
}

ResourcePageWithForm.propTypes = {
  title: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.object),
  target: PropTypes.string.isRequired,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  handleSubmitted: PropTypes.func,
  formInitialState: PropTypes.object,
  submitButton: PropTypes.element,
  submitButtonProps: PropTypes.object,
  submitButtonLabel: PropTypes.string,
  location: PropTypes.string.isRequired,
  beforeSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.element,
};

ResourcePageWithForm.defaultProps = {
  title: '',
  // inputs: [],
  method: 'post',
  submitButtonLabel: 'Continuar',
  onSubmit: null,
  beforeSubmit: null,
  onSuccess: null,
  onError: null,
  children: null,
};
