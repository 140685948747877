import styled, { css } from 'styled-components';

export const Img = styled.div`
  width: 410px;
  height: 280px;
  background-color: #c4c4c4;
  border: 0.1px solid #c4c4c4;
  margin-left: 16px;
  ${({ src }) =>
    src &&
    css`
      background: no-repeat center url('${src}');
      background-size: contain;
    `}
  border-radius: 6px;
`;

export const InputCheckbox = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;

  appearance: none;
  -webkit-appearance: none;
  height: 22px;
  width: 22px;
  background-color: #d5d5d5;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  margin-right: 6px;
  margin-left: 17px;

  & + label {
    color: #4c4c4c;
  }

  &:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f00c';
    font-size: 15px;
    color: white;
    display: none;
  }

  &:hover {
    background-color: #a5a5a5;
  }
  &:checked {
    background-color: #5bcd3e;
  }
  &:checked:after {
    display: block;
  }
`;

export const ButtonRemove = styled.span`
  background-color: rgba(0, 0, 0, 0.45);
  color: white;

  justify-content: flex-end;
  padding: 5px 10px;

  float: right;

  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;

  cursor: pointer;
`;
