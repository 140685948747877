import { all, takeLatest, call, put } from 'redux-saga/effects';

import { FETCH_PROFILES_REQUESTED, FETCH_PROFILE_REQUESTED } from './types';
import {
  fetchProfilesSucceded,
  fetchProfilesFailed,
  fetchProfileSucceded,
  fetchProfileFailed,
} from './actions';

import api from '~/services/api';
import { diffForHumans, limit } from '~/utils';

function* fetchProfiles({ payload }) {
  const { page, term } = payload;
  const target = `/api/users?page=${page}${term ? `&search=${term}` : ''}`;

  try {
    const { data, ...response } = yield call(api.get, target);

    if (response.status !== 200) {
      throw response;
    }

    const profiles = {
      ...data,
      data: data.data.map(profile => ({
        ...profile,
        created_at: diffForHumans(profile.created_at),
        is_active: profile.is_active ? 'Sim' : 'Não',
        company_name: profile.company ? limit(15)(profile.company.fantasy) : '',
      })),
    };

    yield put(fetchProfilesSucceded(profiles));
  } catch (error) {
    yield put(fetchProfilesFailed(error));
  }
}

/**
 * Manipula a ação de buscar o perfil do usuário.
 *
 * @param {Object} payload
 */
function* fetchProfile({ payload }) {
  try {
    const response = yield call(api.get, `/api/users/${payload.id}`);

    if (response.status !== 200) {
      throw response;
    }

    yield put(fetchProfileSucceded(response.data));
  } catch (error) {
    yield put(fetchProfileFailed(error));
  }
}

export default all([
  takeLatest(FETCH_PROFILES_REQUESTED, fetchProfiles),
  takeLatest(FETCH_PROFILE_REQUESTED, fetchProfile),
]);
