/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import {
  Container,
  // Dialog,
  // DialogContent,
  /* Dialog, DialogContent, */ Grid,
} from '@material-ui/core';
import Slider from 'react-slick';
import useSWR from 'swr';
import { Carousel } from 'react-responsive-carousel';
import { Gift as GiftIcon } from 'react-feather';
import { configureAnchors, goToTop } from 'react-scrollable-anchor';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  Hero,
  SliderItem,
  SliderItemPatrocinador,
  SliderApoiador,
  Title,
  TitleContainer,
  Participants,
  ParticipantsItem,
  Company,
  CompanyName,
  CompanyLogo,
  CouponIndicator,
  Divider,
  SearchInput,
  Apoiadores,
  Patrocinadores,
  HeroImg,
} from './styles';

import * as Env from '~/.env';
import { navigateTo } from '~/services/history';
import api from '~/services/api';
import EmptyPhoto from '~/assets/images/banner-disponivel.jpeg';
import { SimpleHeader, SimpleFooter } from '~/components';
import { useSelector } from 'react-redux';

const fetcher = url => api.get(url).then(res => res.data);

export default function Home() {
  const { setting } = useSelector(state => state.setting);
  const response = useSWR('/api/companiesAll', fetcher);
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState('');
//  const [openModal, setOpenModal] = useState(true);

  const sliderOptions = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    document.title = 'LIQUIDAQUI 2024';
    configureAnchors({ offset: -60, scrollDuration: 200 });
    goToTop();
  }, []);

  useEffect(() => {
    async function loadCompanies() {
      const data = {
        ...response.data,
        company:
          response.data &&
          response.data.map(company => {
            const newCompany = {
              ...company,
              logo_url: company.files.find(file => file.file_type_id === 1)
                ? `${Env.API_URL}/storage/${
                    company.files.find(file => file.file_type_id === 1).filename
                  }`
                : '',
            };
            return newCompany;
          }),
      };
      setCompanies(data.company);
    }

    loadCompanies();
  }, [response.data]);

  // const closeModal = () => {
  //   setOpenModal(false);
  // };

  return (
    <>
      <SimpleHeader />

      {/* <Dialog open={openModal} onClose={closeModal} id="popup-modal">
        <DialogContent>
          <img
            src={require('~/assets/images/Popup1.png')}
            alt="pop-up"
            style={{ objectFit: 'contain' }}
          />
        </DialogContent>
      </Dialog> */}

      {setting?.banners && setting?.banners.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Carousel
              autoPlay
              interval={5500}
              showStatus={false}
              showThumbs={false}
              infiniteLoop
            >
              {setting?.banners?.map(banner => (
                <Hero
                  src={
                    banner.background_url
                      ? banner.background_url
                      : banner.banner_url
                  }
                >
                  {banner.background_url && (
                    <HeroImg
                      style={{ width: 1140 }}
                      src={banner.banner_url}
                      alt={banner.title}
                    />
                  )}
                </Hero>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      )}

      <div style={{ padding: 10, textAlign: 'center', fontSize: 15 }}>
        <a
          href={`${Env.API_URL}/documents/regulamento_liquidaqui_2024.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          CERTIFICADO DE AUTORIZAÇÃO SPA/ME Nº 06.036262/2024
        </a>
      </div>

      <TitleContainer style={{ marginTop: '2rem' }}>
        <Title>Lojas destaque</Title>
      </TitleContainer>

      <Grid container style={{ justifyContent: 'center' }}>
        <Grid item xs={12} sm={11}>
          {companies && (
            <Slider {...sliderOptions}>
              {companies.map(
                company =>
                  company.plan_id === 1 &&
                  company.logo_url !== '' && (
                    <div
                      style={{ display: 'flex', justifyContent: 'center' }}
                      key={company.id}
                    >
                      <SliderItem onClick={() => navigateTo(`${company.id}`)}>
                        <img
                          key={company}
                          src={company.logo_url ? company.logo_url : EmptyPhoto}
                          alt={company.fantasy}
                        />
                      </SliderItem>
                    </div>
                  )
              )}
            </Slider>
          )}
        </Grid>
      </Grid>

      {/* <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Carousel
            autoPlay
            interval={5500}
            showStatus={false}
            showThumbs={false}
            infiniteLoop
          >
            <SliderItemBanner color="#fafafa">
              <Link to="/participantes/2">
                <img
                  src={require('~/assets/images/banner-cert-digital.png')}
                  alt="banner-cert-digital"
                />
              </Link>
            </SliderItemBanner>
            <SliderItemBanner color="#fafafa">
              <a
                href="https://forms.microsoft.com/Pages/ResponsePage.aspx?id=TJYjMh9uuki3BUIzUSgajKfpjleF-Y9JvW7ogzikbHNUOVFITDJVOEhGMEJPUDI4S1BXSk1TVThZNS4u"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require('~/assets/images/banner-webbanner-iquidaqui.jpg')}
                  alt="banner-webbanner-iquidaqui"
                />
              </a>
            </SliderItemBanner>
          </Carousel>
        </Grid>
      </Grid> */}

      <Container style={{ marginTop: 50, marginBottom: 32 }}>
        <TitleContainer style={{ marginTop: '2rem' }}>
          <Title translate="no">Pegue seu cupom nas lojas participantes</Title>
          <Divider />
          <SearchInput
            type="text"
            name="search"
            placeholder="Pesquisar lojas participantes"
            value={search}
            onChange={({ target }) => {
              setSearch(target.value);
            }}
          />
        </TitleContainer>
        <section id="participantes">
          <Grid container>
            {companies &&
              companies
                .filter(item =>
                  search.length
                    ? item.fantasy.toLowerCase().includes(search.toLowerCase())
                    : true
                )
                .map(company => (
                  <Grid
                    item
                    sm={12}
                    md={6}
                    style={{ width: '100%' }}
                    key={company.id}
                  >
                    <Participants onClick={() => navigateTo(`${company.id}`)}>
                      <ParticipantsItem>
                        <Company>
                          <CompanyLogo src={company.logo_url} />

                          <CompanyName translate="no">
                            {company.fantasy}
                          </CompanyName>
                        </Company>
                        {company.coupons_count > 0 ? (
                          <CouponIndicator>
                            <GiftIcon size={18} color="red" />
                          </CouponIndicator>
                        ) : (
                          ''
                        )}
                      </ParticipantsItem>
                    </Participants>
                  </Grid>
                ))}
          </Grid>
        </section>

        {setting?.sponsors && setting?.sponsors?.realization?.length > 0 && (
          <>
            <TitleContainer>
              <Title translate="no">Realização</Title>
              <Divider />
            </TitleContainer>

            <section id="patrocinadores">
              <Grid container>
                <Grid item sm={12}>
                  <Patrocinadores>
                    {setting?.sponsors?.realization?.map(realization => (
                      <SliderItemPatrocinador
                        as="a"
                        href={realization?.link}
                        target="_blank"
                      >
                        <img
                          src={realization?.image_url}
                          alt={realization?.name ?? ''}
                        />
                      </SliderItemPatrocinador>
                    ))}
                  </Patrocinadores>
                </Grid>
              </Grid>
            </section>
          </>
        )}

        {setting?.sponsors && setting?.sponsors?.sponsor?.length > 0 && (
          <>
            <TitleContainer>
              <Title translate="no">Patrocinador</Title>

              <Divider />
            </TitleContainer>
            <section id="patrocinadores">
              <Grid container>
                <Grid item sm={12}>
                  <Patrocinadores>
                    {setting?.sponsors?.sponsor?.map(sponsor => (
                      <SliderItemPatrocinador
                        as="a"
                        href={sponsor?.link}
                        target="_blank"
                      >
                        <img
                          src={sponsor?.image_url}
                          alt={sponsor?.name ?? ''}
                        />
                      </SliderItemPatrocinador>
                    ))}
                  </Patrocinadores>
                </Grid>
              </Grid>
            </section>
          </>
        )}

        {setting?.sponsors && setting?.sponsors?.supporters?.length > 0 && (
          <>
            <TitleContainer>
              <Title translate="no">Apoiadores</Title>

              <Divider />
            </TitleContainer>
            <section id="apoiadores">
              <Grid container style={{ justifyContent: 'center' }}>
                <Grid item sm={12}>
                  <Apoiadores>
                    {setting?.sponsors?.supporters?.map(supporter => (
                      <SliderApoiador
                        as="a"
                        href={supporter?.link}
                        target="_blank"
                      >
                        <img
                          src={supporter?.image_url}
                          alt={supporter?.name ?? ''}
                        />
                      </SliderApoiador>
                    ))}
                  </Apoiadores>
                </Grid>
              </Grid>
            </section>
          </>
        )}
      </Container>
      <SimpleFooter />
    </>
  );
}
