/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import { useSelector } from 'react-redux';

import { Container, Title, UserContainer, IconContainer } from './styles';

import { navigateTo } from '~/services/history';
import Colors from '~/themes/Colors';

export default function Header({ menuIcon, title }) {
  const { profile } = useSelector(state => state.auth);

  return (
    <Container>
      <Title.Container>
        {/* The menu icon for small devices */}
        {menuIcon}
        {title && <Title>{title}</Title>}
      </Title.Container>

      <UserContainer>
        <div
          onClick={() => navigateTo(`/users/${profile.id}`)}
          style={{ cursor: 'pointer' }}
        >
          <div style={{ textAlign: 'right' }}>
            <p>{profile.name}</p>
            <p style={{ fontSize: 12, color: '#616968' }}>{profile.email}</p>
          </div>
        </div>

        <IconContainer>
          <Icon.Bell color={Colors.primary.backgroundColor} size={18} />
        </IconContainer>
      </UserContainer>
    </Container>
  );
}

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  menuIcon: PropTypes.object,
  title: PropTypes.string,
};

Header.defaultProps = {
  menuIcon: {},
  title: null,
};
