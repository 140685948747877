import {
  FETCH_PRODUCTS_REQUESTED,
  FETCH_PRODUCTS_SUCCEDED,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCT_REQUESTED,
  FETCH_PRODUCT_SUCCEDED,
  FETCH_PRODUCT_FAILED,
  CLEAR_PRODUCT,
  CREATE_PRODUCT_COUPONS_SUCCESS,
  DELETE_PRODUCT_SUCCEEDED,
} from './types';

export const fetchProductsRequested = (page = 1, term = null, id) => ({
  type: FETCH_PRODUCTS_REQUESTED,
  payload: {
    id,
    page,
    term,
  },
});

export const fetchProductsSucceded = products => ({
  type: FETCH_PRODUCTS_SUCCEDED,
  payload: {
    products,
  },
});

export const fetchProductsFailed = error => ({
  type: FETCH_PRODUCTS_FAILED,
  payload: {
    error,
  },
});

export const fetchProductRequested = id => ({
  type: FETCH_PRODUCT_REQUESTED,
  payload: {
    id,
  },
});

export const fetchProductSucceded = product => ({
  type: FETCH_PRODUCT_SUCCEDED,
  payload: {
    product,
  },
});

export const fetchProductFailed = error => ({
  type: FETCH_PRODUCT_FAILED,
  payload: {
    error,
  },
});

export const createProductCouponsSucccess = coupons => ({
  type: CREATE_PRODUCT_COUPONS_SUCCESS,
  payload: {
    coupons,
  },
});

export const deleteProductSucceeded = id => ({
  type: DELETE_PRODUCT_SUCCEEDED,
  payload: {
    id,
  },
});

export const clearProduct = () => ({
  type: CLEAR_PRODUCT,
});
