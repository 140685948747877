import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ResponsiveDrawer from '../ResponsiveDrawer';

import { signOut } from '~/store/modules/auth/actions';

export default function Layout({ children }) {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.auth);
  const { setting } = useSelector(state => state.setting);

  const menuItems = [
    {
      text: 'Dashboard',
      icon: 'Layout',
      link: '/dashboard',
      hidden: profile.profile_id,
    },
    {
      text: 'Usuários',
      icon: 'User',
      hidden: profile.profile_id,
      link: '/users',
    },
    {
      text: profile.profile_id !== 2 ? 'Empresas' : 'Empresa',
      icon: 'Briefcase',
      hidden: profile.profile_id,
      link:
        profile.profile_id !== 2
          ? '/companies'
          : `/companies/${profile.company_id}/edit`,
    },
    {
      text: 'Produtos',
      icon: 'Database',
      hidden: profile.profile_id,
      link: '/products',
    },
    {
      text: 'Mídia Kit',
      icon: 'Aperture',
      link: '#',
      action: () => {
        if (setting?.link) {
          return window.open(setting?.link, '_blank');
        }
      },
    },
    {
      text: 'Configurações',
      icon: 'Settings',
      hidden: profile.profile_id,
      link: '/settings',
    },
    {
      text: 'Encerrar sessão',
      icon: 'LogOut',
      link: '#',
      action: () => dispatch(signOut()),
    },
  ];

  return <ResponsiveDrawer menuItems={menuItems}>{children}</ResponsiveDrawer>;
}

Layout.propTypes = {
  children: PropTypes.element,
};

Layout.defaultProps = {
  children: null,
};
