import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';

import { SimpleTable } from '~/components';
import { navigateTo } from '~/services/history';
import api from '~/services/api';

export function EditionList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchEditions(page, term) {
    setLoading(true);
    const target = `/api/settings?page=${page}${term ? `&search=${term}` : ''}`;
    try {
      const response = await api.get(target);

      const editions = {
        ...response.data,
        data: response.data.data.map(edition => ({
          ...edition,
          initial_date: format(
            parseISO(edition?.initial_date),
            'dd/MM/yyyy HH:mm'
          ),
          final_date: format(parseISO(edition?.final_date), 'dd/MM/yyyy HH:mm'),
          active: edition.active ? 'Ativo' : 'Inativo',
        })),
      };

      setData(editions);
    } catch {
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      title: 'Ano',
      field: 'year',
      cellStyle: {
        width: '5%',
      },
      render: rowData => (
        <span
          style={{
            textDecoration:
              rowData.active === 'Inativo' ? 'line-through' : 'none',
            color: rowData.active === 'Inativo' ? '#848484' : '#000',
          }}
        >
          {rowData.year}
        </span>
      ),
    },
    {
      title: 'Titulo',
      field: 'title',
      cellStyle: {
        width: '35%',
      },
      render: rowData => (
        <span
          style={{
            textDecoration:
              rowData.active === 'Inativo' ? 'line-through' : 'none',
            color: rowData.active === 'Inativo' ? '#848484' : '#000',
          }}
        >
          {rowData.title}
        </span>
      ),
    },
    {
      title: 'Inicio',
      field: 'initial_date',
      render: rowData => (
        <span
          style={{
            textDecoration:
              rowData.active === 'Inativo' ? 'line-through' : 'none',
            color: rowData.active === 'Inativo' ? '#848484' : '#000',
          }}
        >
          {rowData.initial_date}
        </span>
      ),
    },
    {
      title: 'Final',
      field: 'final_date',
      render: rowData => (
        <span
          style={{
            textDecoration:
              rowData.active === 'Inativo' ? 'line-through' : 'none',
            color: rowData.active === 'Inativo' ? '#848484' : '#000',
          }}
        >
          {rowData.final_date}
        </span>
      ),
    },
    {
      title: 'Atual',
      field: 'active',
      align: 'center',
      render: rowData => (
        <span
          style={{
            padding: 5,
            fontSize: 13,
            backgroundColor:
              rowData.active === 'Inativo' ? '#f76a6a' : '#42e552',
            color: 'white',
            borderRadius: 50,
          }}
        >
          {rowData.active}
        </span>
      ),
    },
  ];

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Editar',
      onClick: (_, edition) => {
        navigateTo(`/settings/edition/${edition.id}/edit`);
      },
    },
  ];

  useEffect(() => {
    fetchEditions();
  }, []);

  return (
    <SimpleTable
      header={{
        action: {
          label: 'Adicionar',
          props: {
            onClick: () => navigateTo('/settings/edition/create'),
            style: {
              backgroundColor: '#5BB60A',
              color: 'white',
            },
          },
        },
      }}
      columns={columns}
      actions={actions}
      loading={loading}
      rows={data.data}
      paginable={{
        page: data.current_page,
        lastPage: data.next_page_url,
        onPageChange: page => fetchEditions(page),
      }}
      onSearchChange={term => fetchEditions(data.current_page, term)}
    />
  );
}
