import produce from 'immer';

import {
  FETCH_PROFILES_REQUESTED,
  FETCH_PROFILES_SUCCEEDED,
  FETCH_PROFILES_FAILED,
  FETCH_PROFILE_REQUESTED,
  FETCH_PROFILE_SUCCEEDED,
  FETCH_PROFILE_FAILED,
  CLEAR_PROFILE,
} from './types';

const profileInitialState = {
  loading: false,
  error: false,
  profile: {},
  profiles: {
    page: 1,
    lastPage: 1,
    data: [],
  },
};

const Profile = (state = profileInitialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_PROFILES_REQUESTED:
        draft.loading = true;
        draft.error = false;
        draft.profiles = [];
        break;
      case FETCH_PROFILES_SUCCEEDED:
        draft.loading = false;
        draft.error = false;
        draft.profiles = action.payload.profiles;
        break;
      case FETCH_PROFILES_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.profiles = [];
        break;
      case FETCH_PROFILE_REQUESTED:
        draft.loading = true;
        draft.error = false;
        draft.profile = {};
        break;
      case FETCH_PROFILE_SUCCEEDED:
        draft.loading = false;
        draft.error = false;
        draft.profile = action.payload.profile;
        break;
      case FETCH_PROFILE_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.profile = {};
        break;
      case CLEAR_PROFILE:
        draft.profile = profileInitialState.profile;
        break;
      default:
    }
  });
};

export default Profile;
