import {
  FETCH_COUPONS_REQUESTED,
  FETCH_COUPONS_SUCCEDED,
  FETCH_COUPONS_FAILED,
  CLEAR_COUPON,
  DELETE_COUPON,
  DELETE_COUPON_SUCCEDED,
  DELETE_COUPON_FAILED,
  DELETE_ALL_COUPONS,
  DELETE_ALL_COUPONS_SUCCEDED,
  DELETE_ALL_COUPONS_FAILED,
} from './types';

export const fetchCouponsRequested = (page = 1, term = null, id, type) => ({
  type: FETCH_COUPONS_REQUESTED,
  payload: {
    id,
    page,
    type,
    term,
  },
});

export const fetchCouponsSucceded = coupons => ({
  type: FETCH_COUPONS_SUCCEDED,
  payload: {
    coupons,
  },
});

export const fetchCouponsFailed = error => ({
  type: FETCH_COUPONS_FAILED,
  payload: {
    error,
  },
});

export const deleteCoupon = id => ({
  type: DELETE_COUPON,
  payload: {
    id,
  },
});

export const deleteCouponSucceded = submitted => ({
  type: DELETE_COUPON_SUCCEDED,
  payload: {
    submitted,
  },
});

export const deleteCouponFailed = error => ({
  type: DELETE_COUPON_FAILED,
  payload: {
    error,
  },
});

export const deleteAllCoupons = (all, company_id, product_id) => ({
  type: DELETE_ALL_COUPONS,
  payload: {
    confirmation: all,
    company_id,
    product_id,
  },
});

export const deleteAllCouponsSucceded = submitted => ({
  type: DELETE_ALL_COUPONS_SUCCEDED,
  payload: {
    submitted,
  },
});

export const deleteAllCouponsFailed = error => ({
  type: DELETE_ALL_COUPONS_FAILED,
  payload: {
    error,
  },
});

export const clearCoupon = () => ({
  type: CLEAR_COUPON,
});
