import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';

// Styles
import {
  Container,
  MyAvatar,
  Name,
  Action,
  Divider,
  Section,
  Label,
} from './styles';

import { fetchProfileRequested } from '~/store/modules/profile/actions';
import {
  fetchCouponsRequested,
  deleteCoupon,
} from '~/store/modules/coupon/actions';
import { Layout, Centered, Error, SimpleTable } from '~/components';
import history, { navigateTo } from '~/services/history';
import Loading from '~/assets/lottie/Loading';
import { diffForHumans } from '~/utils';
import api from '~/services/api';

export default function UserDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, error, profile } = useSelector(state => state.profile);
  const { profile: user } = useSelector(state => state.auth);
  const type = 'users';
  const {
    loading: loadingCoupon,
    error: errorCoupon,
    coupons,
    submitted,
  } = useSelector(state => state.coupon);

  const columns = [
    { title: 'Empresa', field: 'company_name' },
    { title: 'Produto', field: 'product_description' },
    { title: 'Código do Cupom', field: 'code' },
    { title: 'Valor', field: 'value' },
    { title: 'Status', field: 'status' },
  ];

  let actions = [];
  if (user.profile_id === 3) {
    actions = [];
  } else {
    actions = [
      {
        icon: 'delete_forever',
        tooltip: 'Excluir',
        onClick: (event, coupon) => {
          dispatch(deleteCoupon(coupon.id));
        },
      },
    ];
  }

  useEffect(() => {
    document.title = 'Usuários';

    dispatch(fetchProfileRequested(id));
    dispatch(fetchCouponsRequested(1, null, id, type));
  }, [dispatch, id]);

  useEffect(() => {
    if (submitted) {
      toast.success('Cupom removido!', { autoClose: 2000 });
      dispatch(fetchCouponsRequested(1, null, id, type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  const handleDelete = async () => {
    try {
      const response = await api.delete(`/api/users/${id}`);

      if (response.status !== 200) {
        throw response;
      }

      toast.success('O usuário foi removido com sucesso');

      history.push('/users');
    } catch {
      toast.error('Não foi possível remover este usuário.');
    }
  };

  const getInitials = value => {
    const [name, surname] = value.split(' ');

    return `${name[0]} ${surname ? surname[0] : ''}`;
  };

  const getContentToRender = () => {
    const hasProfile = Object.keys(profile).length;

    if (error) {
      return (
        <Error
          status={500}
          title="Não foi possível carregar o perfil do usuário."
        />
      );
    }

    return (
      <Container>
        {!hasProfile || loading ? (
          <Centered>
            <Loading />
          </Centered>
        ) : (
          <>
            {/* MyAvatar */}
            <MyAvatar.Container>
              <Avatar
                name={getInitials(profile.name)}
                size={120}
                round="10px"
                color="#b1c2d9"
                style={{ marginRight: '42px' }}
              />
              <div>
                <Name.Container>
                  <Name>{profile.name}</Name>
                </Name.Container>
                {profile.profile_id !== 1 && (
                  <Label>
                    {profile.profile_id === 2
                      ? 'Empresa'
                      : `Criado ${diffForHumans(profile.created_at)}`}
                  </Label>
                )}
              </div>

              <Action>
                {user.id !== profile.id && user.role === 'cdl' && (
                  <Button
                    onClick={handleDelete}
                    size="large"
                    variant="outlined"
                    disableElevation
                  >
                    Remover
                  </Button>
                )}

                <Button
                  onClick={() => navigateTo(`/users/${profile.id}/edit`)}
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Editar
                </Button>
              </Action>
            </MyAvatar.Container>

            <Divider />

            <Section>
              <Section.Title>Dados pessoais</Section.Title>

              <Grid container spacing={6}>
                <Grid item>
                  <Label>Nome</Label>
                  <p>{profile.name}</p>
                </Grid>
                <Grid item>
                  <Label>Email</Label>
                  <p>{profile.email}</p>
                </Grid>
                <Grid item>
                  <Label>Telefone</Label>
                  <p>{profile.phone}</p>
                </Grid>
              </Grid>
            </Section>

            <Divider />

            <Section>
              <Section.Title>
                {user.role === 'empresas' || user.role === 'clientes'
                  ? 'Meus Cupons'
                  : 'Cupons'}
              </Section.Title>

              {user.profile_id !== 2 && user.profile_id !== 3 ? (
                <Grid
                  container
                  direction="row"
                  spacing={5}
                  justify="flex-start"
                  style={{ marginBottom: '20px' }}
                >
                  <Grid item>
                    <Label>Total</Label>
                    {coupons.data && coupons.data[0] ? (
                      <p>{coupons.data[0].total}</p>
                    ) : (
                      <p>0</p>
                    )}
                  </Grid>
                  <Grid item>
                    <Label>Disponíveis</Label>
                    {coupons.data && coupons.data[0] ? (
                      <p>{coupons.data[0].available}</p>
                    ) : (
                      <p>0</p>
                    )}
                  </Grid>
                  <Grid item>
                    <Label>Retirados</Label>
                    {coupons.data && coupons.data[0] ? (
                      <p>{coupons.data[0].ransomed}</p>
                    ) : (
                      <p>0</p>
                    )}
                  </Grid>
                  <Grid item>
                    <Label>Utilizados</Label>
                    {coupons.data && coupons.data[0] ? (
                      <p>{coupons.data[0].used}</p>
                    ) : (
                      <p>0</p>
                    )}
                  </Grid>
                </Grid>
              ) : null}

              <SimpleTable
                loading={loadingCoupon}
                error={errorCoupon}
                columns={columns}
                rows={coupons.data}
                actions={actions}
                paginable={{
                  page: coupons.current_page,
                  lastPage: coupons.next_page_url,
                  onPageChange: page =>
                    dispatch(fetchCouponsRequested(page, null, id, type)),
                }}
                onSearchChange={term =>
                  dispatch(fetchCouponsRequested(coupons.page, term, id, type))
                }
              />
            </Section>
          </>
        )}
      </Container>
    );
  };

  return <Layout>{getContentToRender()}</Layout>;
}
