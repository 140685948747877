import styled from 'styled-components';

import Colors from '~/themes/Colors';

export const MySelect = styled.select`
  padding: 12px 14px;
  font: inherit;
  color: currentColor;
  height: 3.1875em;
  margin: 0;
  border: 0;
  border-radius: 5px;
  display: block;
  border: 1px solid rgb(195, 195, 195);
  outline-color: ${Colors.primary.backgroundColor};
`;
