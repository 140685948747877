/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  textField: {
    marginBottom: theme.spacing(1),
  },
}));

export default function Input({ columns, withDefaultStyle, hidden, ...props }) {
  const classes = useStyles();

  const defaultProps = {
    variant: 'outlined',
    fullWidth: true,
    className: withDefaultStyle && classes.textField,
  };

  if (Object.keys(columns).length) {
    return (
      <Grid
        item
        {...columns}
        style={{ display: `${props.type === 'hidden' ? 'none' : 'block'} ` }}
      >
        {!hidden && (
          <TextField
            {...defaultProps}
            InputLabelProps={{ style: { height: 20 } }}
            InputProps={{ className: classes.textField }}
            {...props}
          />
        )}
      </Grid>
    );
  }

  return <TextField {...defaultProps} {...props} />;
}

Input.propTypes = {
  columns: PropTypes.object,
  withDefaultStyle: PropTypes.bool,
};

Input.defaultProps = {
  columns: {
    xs: 12,
    md: 12,
    lg: 12,
  },
  withDefaultStyle: false,
};
