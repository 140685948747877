import { combineReducers } from 'redux';

import auth from './auth/reducer';
import profile from './profile/reducers';
import company from './company/reducers';
import product from './product/reducers';
import coupon from './coupon/reducers';
import order from './order/reducers';
import setting from './setting/reducers';

export default combineReducers({
  auth,
  profile,
  company,
  product,
  coupon,
  order,
  setting,
});
