import { all, takeLatest, call, put } from 'redux-saga/effects';

import { fetchSettingSucceded, fetchSettingFailed } from './actions';
import { FETCH_SETTING_REQUESTED } from './types';

import api from '~/services/api';

function* fetchSetting() {
  const target = `/api/setting`;

  try {
    const response = yield call(api.get, target);
    if (response.status !== 200) {
      throw response;
    }

    yield put(fetchSettingSucceded(response.data));
  } catch (error) {
    yield put(fetchSettingFailed(error));
  }
}

export default all([takeLatest(FETCH_SETTING_REQUESTED, fetchSetting)]);
