import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { goToTop } from 'react-scrollable-anchor';
import * as Icon from 'react-feather';

import { Container } from './styles';

import { SimpleHeader } from '~/components';
import history, { navigateTo } from '~/services/history';
import api from '~/services/api';
import { toDinheiro } from '~/utils';

export function CheckoutShipping() {
  const [orders, setOrders] = useState();
  const [methodSend, setMethodSend] = useState(1);
  const [loading, setLoading] = useState(true);

  async function getOrders() {
    setLoading(false);
    const { data } = await api.get('/api/cart');
    setOrders(data.order);
  }

  useEffect(() => {
    document.title = 'LIQUIDAQUI 2024 - Meu carrinho - Método de Envio';
    goToTop();
  }, []);

  async function handleNext() {
    api.put(`/api/cart/${orders?.order_id}/update`, {
      step: 'checkout-shipping',
      method_send: Number(methodSend),
    });

    navigateTo(`/cart/${orders?.order_id}/check-out`);
  }

  useEffect(() => {
    getOrders();
  }, [loading]);

  return (
    <>
      <SimpleHeader />

      <Container>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '80%' }}>
            <Button
              variant="contained"
              disableElevation
              onClick={history.goBack}
              style={{ marginBottom: 10 }}
            >
              <Icon.ChevronLeft />
              Voltar
            </Button>
            <Container.Cart>
              <TableContainer component={Paper} style={{ marginTop: 15 }}>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow />
                    <TableRow>
                      <TableCell colSpan={2}>
                        Escolha a forma de envio para o seu endereço:
                        <br />
                        <b>{orders?.user_address}</b>
                      </TableCell>

                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Table aria-label="spanning table">
                          <TableHead />
                          <TableBody>
                            <TableRow>
                              <TableCell style={{ width: 20 }}>
                                <input
                                  type="radio"
                                  value="1"
                                  name="method_send"
                                  defaultChecked
                                  onChange={({ target }) =>
                                    setMethodSend(target.value)
                                  }
                                />
                              </TableCell>
                              <TableCell style={{ width: 20 }}>
                                <img
                                  src="https://www.lojabandeirantes.com.br/img/transportadora.png"
                                  style={{ width: 50 }}
                                  alt=""
                                />
                              </TableCell>
                              <TableCell>Entrega em Rondonópolis</TableCell>
                              <TableCell>
                                {toDinheiro(orders?.cost_of_freight)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ width: 20 }}>
                                <input
                                  type="radio"
                                  value="2"
                                  name="method_send"
                                  onChange={({ target }) =>
                                    setMethodSend(target.value)
                                  }
                                />
                              </TableCell>
                              <TableCell style={{ width: 20 }}>
                                <img
                                  src="https://www.lojabandeirantes.com.br/img/em_maos.png"
                                  style={{ width: 50 }}
                                  alt=""
                                />
                              </TableCell>
                              <TableCell>Retirar na loja</TableCell>
                              <TableCell>R$ 0,00</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 20,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleNext}
                >
                  Próximo passo
                  <Icon.ChevronRight />
                </Button>
              </div>
            </Container.Cart>
          </div>
        </div>
      </Container>
    </>
  );
}
