import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import * as Icon from 'react-feather';

import { Container } from './styles';

export default function Pagination({
  page,
  lastPage,
  prevButtonText,
  nextButtonText,
  onPageChange,
}) {
  const fetchPrevious = () => {
    if (page === 1) return;

    onPageChange(page - 1);
  };

  const fetchNext = () => {
    if (page >= lastPage) return;

    onPageChange(page + 1);
  };

  return (
    <Container>
      {/* Previous */}
      <Button disabled={page === 1} onClick={fetchPrevious} variant="outlined">
        <Icon.ArrowLeft style={{ marginRight: 12 }} size={16} />

        {prevButtonText}
      </Button>

      {/* Next */}
      <Button
        disabled={page >= lastPage}
        onClick={fetchNext}
        variant="outlined"
      >
        {nextButtonText}

        <Icon.ArrowRight style={{ marginLeft: 12 }} size={16} />
      </Button>
    </Container>
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  lastPage: PropTypes.number,
  prevButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  page: 1,
  lastPage: 1,
  prevButtonText: 'Anterior',
  nextButtonText: 'Próximo',
};
