import React from 'react';

export default function Loading(props) {
  return (
    <lottie-player
      {...props}
      src="https://assets5.lottiefiles.com/datafiles/aba45c7b75d547282b2dbdc97969412b/progress_bar.json"
      style={{ width: 300, height: 200 }}
      background="transparent"
      speed="1"
      loop
      autoplay
    />
  );
}
