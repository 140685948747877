import React from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import { Button } from '@material-ui/core';

import { Error } from '~/components';

export default function NotFound({ history }) {
  return (
    <Error
      status={404}
      title="Página não encontrada"
      message="A página que você está procurando não pode ser encontrada."
    >
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={history.goBack}
      >
        <Icon.ArrowLeft style={{ marginRight: 12 }} size={16} />
        Voltar
      </Button>
    </Error>
  );
}

NotFound.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
};

NotFound.defaultProps = {
  history: null,
};
