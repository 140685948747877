import produce from 'immer';

import {
  FETCH_PRODUCTS_REQUESTED,
  FETCH_PRODUCTS_SUCCEDED,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCT_REQUESTED,
  FETCH_PRODUCT_SUCCEDED,
  FETCH_PRODUCT_FAILED,
  CLEAR_PRODUCT,
  DELETE_PRODUCT_SUCCEEDED,
} from './types';

const productInitialState = {
  loading: false,
  error: false,
  product: {},
  products: {
    page: 1,
    lastPage: 1,
    data: [],
  },
};

const Product = (state = productInitialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_PRODUCTS_REQUESTED:
        draft.loading = true;
        draft.error = false;
        draft.products = [];
        break;
      case FETCH_PRODUCTS_SUCCEDED:
        draft.loading = false;
        draft.error = false;
        draft.products = action.payload.products;
        break;
      case FETCH_PRODUCTS_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.products = [];
        break;
      case FETCH_PRODUCT_REQUESTED:
        draft.loading = true;
        draft.error = false;
        draft.product = {};
        break;
      case FETCH_PRODUCT_SUCCEDED:
        draft.loading = false;
        draft.error = false;
        draft.product = action.payload.product;
        break;
      case FETCH_PRODUCT_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.product = {};
        break;
      case DELETE_PRODUCT_SUCCEEDED:
        draft.loading = false;
        draft.error = false;
        draft.products = state.products.data.filter(
          product => product.id !== action.payload.id
        );
        break;
      case CLEAR_PRODUCT:
        draft.product = productInitialState.product;
        break;
      default:
    }
  });
};

export default Product;
