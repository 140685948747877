import styled, { css } from 'styled-components';

export const Img = styled.div`
  width: 100%;
  height: 200px;
  background-color: #c4c4c4;
  ${({ src, banner }) =>
    src &&
    css`
      background: no-repeat center url('${src}');
      background-size: ${banner ? 'cover' : 'contain'};
    `}
  border-radius: 6px;
`;

export const ButtonRemove = styled.span`
  background-color: rgba(0, 0, 0, 0.45);
  color: white;

  justify-content: flex-end;
  padding: 5px 10px;

  float: right;

  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;

  cursor: pointer;
`;
