/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import NotFound from '~/pages/NotFound';

export default function PrivateRoute({
  component: Component,
  roles,
  ...props
}) {
  const { signed, profile } = useSelector(state => state.auth);

  if (!signed) {
    return <Redirect to="/" />;
  }

  const hasPermission = () => {
    if (Array.isArray(roles) && roles.length) {
      return roles.includes(profile.role);
    }

    return roles === profile.role;
  };

  if (roles && !hasPermission()) {
    return <Route component={NotFound} />;
  }

  const renderComponent = routeProps => {
    return <Component {...routeProps} />;
  };

  return <Route {...props} render={renderComponent} />;
}

PrivateRoute.propTypes = {
  // component: PropTypes.element.isRequired,
  roles: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
    PropTypes.string,
  ]),
};

PrivateRoute.defaultProps = {
  roles: false,
};
