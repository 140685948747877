import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Form, Row, ForgotPasswordLink, TextLink } from '../styles';

import Input from '~/components/Input';
import { signInRequest } from '~/store/modules/auth/actions';

export default function LoginForm({ setStep }) {
  const { register, errors, handleSubmit } = useForm();
  const { loading } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const onSubmit = ({ email, password }) => {
    dispatch(signInRequest(email, password, false));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        {/* Email */}
        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="E-mail"
          type="email"
          placeholder="Entre com seu email"
          name="email"
          autoFocus
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          withDefaultStyle
        />

        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="Senha"
          type="password"
          placeholder="Entre com sua senha"
          name="password"
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          withDefaultStyle
        />

        {/* Password */}
        <Row style={{ marginLeft: 'auto' }}>
          <ForgotPasswordLink to="#" onClick={() => setStep(2)}>
            Esqueceu sua senha?
          </ForgotPasswordLink>
        </Row>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          disabled={loading}
        >
          {loading ? 'Carregando...' : 'Continuar'}
        </Button>
        {document.location.pathname !== '/register' && (
          <Row style={{ margin: 'auto', marginTop: 15 }}>
            <TextLink to="#" onClick={() => setStep(3)}>
              Registre-se
            </TextLink>
          </Row>
        )}
      </Grid>
    </Form>
  );
}

LoginForm.propTypes = {
  setStep: PropTypes.func.isRequired,
};
