import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ResourcesPageWithTable from '~/layouts/ResourcesPageWithTable';
import {
  fetchCompaniesRequested,
  clearCompany,
} from '~/store/modules/company/actions';
import { navigateTo } from '~/services/history';

export default function CompaniesList() {
  const dispatch = useDispatch();
  const { loading, error, companies } = useSelector(state => state.company);
  useEffect(() => {
    dispatch(fetchCompaniesRequested());
  }, [dispatch]);

  const columns = [
    { title: 'Nome', field: 'fantasy' },
    { title: 'Email', field: 'email' },
    { title: 'Criado', field: 'created_at' },
    { title: 'Ativo', field: 'is_active' },
  ];

  const actions = [
    {
      icon: 'edit',
      onClick: (event, company) => navigateTo(`/companies/${company.id}/edit`),
    },
    {
      icon: 'arrow_right',
      onClick: (event, company) => navigateTo(`/companies/${company.id}`),
    },
  ];

  return (
    <ResourcesPageWithTable
      title="Empresas"
      header={{
        action: {
          label: 'Nova Empresa',
          props: {
            onClick: () => {
              dispatch(clearCompany());
              navigateTo('/companies/create');
            },
            style: {
              backgroundColor: '#5BB60A',
              color: 'white',
            },
          },
        },
      }}
      loading={loading}
      error={error}
      columns={columns}
      rows={companies.data}
      actions={actions}
      searchable
      pageSize={companies.per_page}
      paginable={{
        page: companies.current_page,
        lastPage: companies.next_page_url,
        onPageChange: page => dispatch(fetchCompaniesRequested(page)),
      }}
      onSearchChange={term =>
        dispatch(fetchCompaniesRequested(companies.page, term))
      }
    />
  );
}
