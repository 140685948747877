import React, { useEffect } from 'react';
import { goToTop } from 'react-scrollable-anchor';
// import { Link } from 'react-router-dom';

import { Container, TitleContainer, Title, Divider, Text } from './styles';

import { SimpleHeader, SimpleFooter } from '~/components';
// import { API_URL } from '~/.env';

export default function About() {
  useEffect(() => {
    document.title = 'LIQUIDAQUI 2024 - Como funciona?';
    goToTop();
  }, []);

  return (
    <>
      <SimpleHeader />

      <Container>
        <TitleContainer>
          <Title>
            Como funciona o <b>LiquidAqui?</b> A CDL explica para você
          </Title>

          <Divider />
        </TitleContainer>

        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            O que é o LiquidAqui?
          </Title>
        </TitleContainer>
        <Text>
          O LiquidAqui é uma campanha da CDL, que visa o desenvolvimento da
          economia do varejo de Rondonópolis. A proposta deste ano é movimentar
          o comércio no mês de setembro. Na toada da retomada da economia, o
          objetivo é ofertar produtos com preços e prazos diferenciados
          fomentando o comércio local. A já tradicional festa do varejo terá
          prêmios que somam quase R$ 100mil e show do Patati e Patata.
        </Text>

        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            Quem são os nossos parceiros?
          </Title>
        </TitleContainer>
        <Text>
          Este ano, a CDL de Rondonópolis realiza a campanha com apoio da
          Prefeitura Municipal de Rondonópolis, ACIR, Polícia Militar,
          Fecomércio e SindComRoo.
        </Text>

        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            Quando acontecerá o LiquidAqui
          </Title>
        </TitleContainer>
        <Text>O LiquidAqui acontecerá de 10 a 14 de setembro de 2024</Text>

        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            Quais serão os prêmios sorteados?
          </Title>
        </TitleContainer>
        <Text>
          Os prêmios serão os seguintes:
          <ul style={{ listStyle: 'inside' }}>
            <li>90 mil em Pix</li>
            <li>10 mil em vale compras</li>
          </ul>
        </Text>

        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            Como eu faço para concorrer aos prêmios do LiquidAqui?
          </Title>
        </TitleContainer>
        <Text>
          A cada R$ 50,00 em compras, nas lojas participantes, você terá direito
          a um cupom.
        </Text>
        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            Como eu faço para saber quais lojas estão participando e quais
            produtos estão em promoção?
          </Title>
        </TitleContainer>
        <Text>
          Visitando as lojas participantes e conferindo as ofertas postadas
          aqui. Clique na opção “Lojas participantes” e confira a lista
          completa.
        </Text>

        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            O que são os cupons de descontos no Hotsite?
          </Title>
        </TitleContainer>
        <Text>
          São cupons que podem ser resgatados pelo cliente, na página da loja de
          seu interesse, e que darão direito a um preço especial no produto
          desejado. Os cupons gerados têm a validade de 24 horas e, caso o
          cliente não consiga resgatar determinado cupom, poderá gerar outro a
          qualquer momento.
        </Text>

        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            Onde e quando será realizado o sorteio dos prêmios do LiquidAqui?
          </Title>
        </TitleContainer>

        <Text>
          O primeiro sorteio será no dia 14 de setembro às 15:30 na praça dos Carreiros.
        </Text>

    
        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            O comércio ficará aberto até qual horário durante o LiquidAqui?
          </Title>
        </TitleContainer>
        <Text>
          Durante os dias da campanha, as lojas são autorizadas a abrirem em
          horário especial à noite para maior comodidade dos consumidores.
          <ul style={{ listStyle: 'inside' }}>
            <li>Do dia 10/09 a 14/09 as lojas abertas até às 22:00 horas</li>            
          </ul>
          {/* • A adesão ao horário especial é prerrogativa da empresa participante. */}
        </Text>

        {/* <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            Eu tenho direito a meia entrada?
          </Title>
        </TitleContainer>
        <Text>
          O benefício do pagamento de meia-entrada será concedido para
          estudantes, idosos, pessoas com deficiência, jovens de 15 a 29 anos
          comprovadamente carentes e servidores públicos municipais.
        </Text> */}
        {/* <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            Como tenho acesso ao ingresso para o show do cantor Murilo Huff?
          </Title>
        </TitleContainer>
        <Text>
          A CDL e parceiros fornecerão ingressos para as empresas participantes
          para que elas distribuam ou sorteiem entre os consumidores que fizerem
          compras durante o LiquidAqui, como forma de incentivar as compras e
          fidelizações dos clientes.
        </Text>
        <TitleContainer>
          <Title size={22} textAlign="left" weight={400}>
            Eu tenho direito a meia entrada?
          </Title>
        </TitleContainer>
        <Text>
          O benefício do pagamento de meia-entrada será concedido para
          estudantes, idosos, pessoas com deficiência, jovens de 15 a 29 anos
          comprovadamente carentes e servidores públicos municipais.
        </Text>
        <Text>
          <a
            href={`${API_URL}/documents/parecer_beneficio_do_pagamento_de_meia.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Consulte todos os detalhes clicando aqui
          </a>
        </Text>
        <Text>
          <Link to="/regulamento">
            Clique aqui e acesse o regulamento completo da Campanha LiquidAqui
            2022
          </Link>
        </Text> */}
      </Container>
      <SimpleFooter />
    </>
  );
}
