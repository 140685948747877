import {
  FETCH_SETTING_REQUESTED,
  FETCH_SETTING_SUCCEDED,
  FETCH_SETTING_FAILED,
} from './types';

export const fetchSettingRequested = () => ({
  type: FETCH_SETTING_REQUESTED,
  payload: null,
});

export const fetchSettingSucceded = setting => ({
  type: FETCH_SETTING_SUCCEDED,
  payload: {
    setting,
  },
});

export const fetchSettingFailed = error => ({
  type: FETCH_SETTING_FAILED,
  payload: {
    error,
  },
});
