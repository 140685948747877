import React, { useEffect, useState, useCallback } from 'react';
import { goToTop } from 'react-scrollable-anchor';
import * as Icon from 'react-feather';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from '@material-ui/core';
import { toast } from 'react-toastify';

import { Container } from './styles';

import { SimpleHeader } from '~/components';
import { navigateTo } from '~/services/history';
import api from '~/services/api';
import { toDinheiro } from '~/utils';

export default function Cart() {
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = 'LIQUIDAQUI 2024 - Meu carrinho';
    goToTop();
  }, []);

  async function getOrders() {
    const { data } = await api.get('/api/cart');
    setOrders(data.order);
    setLoading(false);
  }

  async function getCoupon(id, product_id, order_id) {
    await api.post('/api/get-coupon', {
      id,
      product_id,
      order_id,
    });

    getOrders();
  }

  const removeItem = useCallback(async (id, order_id) => {
    setLoading(true);
    const { data } = await api.delete(
      `/api/destroyItem?id=${id}&order_id=${order_id}`
    );
    toast.success(data.message);
  }, []);

  useEffect(() => {
    getOrders();
  }, [loading]);

  function buttonsCoupons(item) {
    if (item.there_are_coupon > 0) {
      return item.coupon_already_taken ? (
        <Button
          variant="contained"
          style={{
            backgroundColor: '#9f9f9f',
            color: 'white',
            marginRight: 4,
            height: 30,
          }}
          disableElevation
          disabled
        >
          <Icon.Tag size={16} />
          <p style={{ marginLeft: 5, fontSize: 11 }}>Cupom adicionado</p>
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            backgroundColor: '#5BB60A',
            color: 'white',
            marginRight: 4,
            height: 30,
          }}
          onClick={() => getCoupon(item.id, item.product_id, orders.order_id)}
          disableElevation
        >
          <Icon.Tag size={16} />
          <p style={{ marginLeft: 5, fontSize: 11 }}>Pegue seu cupom</p>
        </Button>
      );
    }
    return <></>;
  }

  return (
    <>
      <SimpleHeader />

      <Container>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '80%' }}>
            <Container.Cart>
              {orders ? (
                <>
                  <TableContainer component={Paper}>
                    <Table aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={4}
                            style={{
                              border: 'none',
                              fontWeight: 'bold',
                              fontSize: 20,
                            }}
                          >
                            Meu Carrinho
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                            Descrição
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ fontWeight: 'bold' }}
                          >
                            Valor
                          </TableCell>
                          <TableCell align="right" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orders &&
                          orders?.items.map(item => (
                            <TableRow key={String(item.id)}>
                              <TableCell colSpan={2}>
                                {item.description}
                              </TableCell>
                              <TableCell align="right">
                                {toDinheiro(item.amount)}
                              </TableCell>
                              <TableCell align="right" style={{ width: 290 }}>
                                {buttonsCoupons(item)}
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: '#ec0007',
                                    color: 'white',
                                  }}
                                  onClick={() =>
                                    removeItem(item.id, orders.order_id)
                                  }
                                  disableElevation
                                >
                                  <Icon.X size={16} />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}

                        <TableRow>
                          <TableCell colSpan={2} style={{ border: 'none' }} />
                          <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', border: 'none' }}
                          >
                            SubTotal
                          </TableCell>
                          <TableCell align="right" style={{ border: 'none' }}>
                            {toDinheiro(orders?.sub_total)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {orders?.discount ? (
                            <>
                              <TableCell
                                colSpan={2}
                                style={{ border: 'none' }}
                              />
                              <TableCell
                                align="right"
                                style={{ fontWeight: 'bold', border: 'none' }}
                              >
                                Cupom (-)
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ border: 'none' }}
                              >
                                {toDinheiro(orders?.discount)}
                              </TableCell>
                            </>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                        <TableRow>
                          {orders?.total_shipping ? (
                            <>
                              <TableCell
                                colSpan={2}
                                style={{ border: 'none' }}
                              />
                              <TableCell
                                align="right"
                                style={{ fontWeight: 'bold', border: 'none' }}
                              >
                                Frete (+)
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ border: 'none' }}
                              >
                                {toDinheiro(orders?.total_shipping)}
                              </TableCell>
                            </>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2} style={{ border: 'none' }} />
                          <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', border: 'none' }}
                          >
                            Total
                          </TableCell>
                          <TableCell align="right" style={{ border: 'none' }}>
                            {toDinheiro(orders?.total)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 20,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() =>
                        navigateTo(`/cart/${orders?.order_id}/address`)
                      }
                    >
                      Próximo passo
                      <Icon.ChevronRight />
                    </Button>
                  </div>
                </>
              ) : (
                <p style={{ textAlign: 'center', fontSize: 20 }}>
                  Carrinho Vazio
                </p>
              )}
            </Container.Cart>
          </div>
        </div>
      </Container>
    </>
  );
}
