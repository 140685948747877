import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';

import { Container, Form } from './styles';

// Components
import { Input } from '~/components';
// Services
import api from '~/services/api';

export default function PasswordForgot() {
  const { register, errors, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  useEffect(() => {
    document.title = 'Recuperar senha';
  }, []);

  const onSubmit = async ({ email }) => {
    const target = '/api/password/forgot';

    try {
      const response = await api.post(target, { email });

      if (response.status !== 200) {
        throw response;
      }

      toast.success(`Um link de recuperação foi enviado para ${email}.`);
    } catch {
      toast.error(
        'Não foi possível enviar um link de recuperação para o email informado.'
      );
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Header>
          <h2>Recuperar senha</h2>
          <span>Entre com o email que deseja recuperar.</span>
        </Form.Header>

        {/* Email */}
        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="E-mail"
          type="email"
          placeholder="exemplo@email.com.br"
          name="email"
          autoFocus
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          disabled={isSubmitting}
        >
          Continuar
        </Button>
      </Form>
    </Container>
  );
}
