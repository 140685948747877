import produce from 'immer';

import {
  FETCH_SETTING_REQUESTED,
  FETCH_SETTING_SUCCEDED,
  FETCH_SETTING_FAILED,
} from './types';

const settingInitialState = {
  loading: false,
  error: false,
  setting: null,
};

const Setting = (state = settingInitialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_SETTING_REQUESTED:
        draft.loading = true;
        draft.error = false;
        draft.setting = null;
        break;
      case FETCH_SETTING_SUCCEDED:
        draft.loading = false;
        draft.error = false;
        draft.setting = action.payload.setting;
        break;
      case FETCH_SETTING_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.setting = {};
        break;

      default:
    }
  });
};

export default Setting;
