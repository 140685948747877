import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Form = styled.form`
  flex: 1;
  padding: 20px;
  max-width: 450px;
`;

export const TextLink = styled(Link)`
  color: #5bb60a;
`;
