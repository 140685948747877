import styled from 'styled-components';

import { Colors } from '~/themes';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 62px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.06);
`;

export const Action = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  button {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const MyAvatar = styled.img`
  width: 120px;
  height: 120px;
  margin-right: 42px;
  border-radius: 100%;
`;

export const DefaultMyAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  width: 120px;
  height: 120px;
  margin-right: 42px;
  border-radius: 100%;
  background-color: #b1c2d9;
  color: white;
`;

MyAvatar.Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 480px) {
    align-items: center;
    flex-direction: column;
    text-align: center;

    ${MyAvatar} {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px;
    }

    ${DefaultMyAvatar} {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px;
    }

    ${Action} {
      margin-top: 32px;
    }
  }
`;

export const Name = styled.p`
  font-size: 18px;
  font-weight: 300;
  margin-right: 22px;
`;

Name.Container = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 18px;

    ${Name} {
      margin-bottom: 6px;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: ${Colors.muted.backgroundColor};
`;

export const Row = styled.div`
  display: flex;
`;

export const Group = styled.div`
  &:not(:last-child) {
    margin-right: 3.5rem;

    @media only screen and (max-width: 480px) {
      margin-bottom: 22px;
    }
  }
`;

export const Label = styled.span`
  display: block;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 4px;
  color: ${Colors.muted.backgroundColor};
`;

export const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

Section.Title = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  margin-bottom: 12px;
`;

export const Muted = styled.p`
  font-weight: 300;
  color: ${Colors.muted.backgroundColor};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleDescription = styled.h4`
  color: #cecece;
  font-size: 14px;
`;

export const TitleProduct = styled.p`
  padding-left: 10px;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px 20px;
`;

export const ValueDescription = styled.h4`
  color: #cecece;
  font-size: 14px;
`;

export const ValueProduct = styled.p`
  padding-left: 10px;
`;
