import styled from 'styled-components';

import { Colors } from '~/themes';

export const Container = styled.div`
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: ${props =>
    props.withRightContent ? 'space-between' : 'flex-start'};
  align-items: center;
`;

export const Title = styled.h3`
  font-size: 22px;
  font-weight: 300;
`;

export const Subtitle = styled.p`
  font-weight: 300;
  color: ${Colors.muted.backgroundColor};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 12px;
  margin-bottom: 36px;
  background-color: #a8a8a8;
`;
