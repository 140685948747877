import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import Types from './types';
import { signInSuccess, signFailure } from './actions';

import * as Env from '~/.env';
import { navigateTo } from '~/services/history';
import api from '~/services/api';

export function* signIn({ payload }) {
  const { redirect } = payload;
  const credentials = {
    client_id: Env.API_CLIENT_ID,
    client_secret: Env.API_CLIENT_SECRET,
    grant_type: Env.API_GRANT_TYPE,
    ...payload,
  };

  try {
    const response = yield call(api.post, '/oauth/token', credentials);

    if (response.status !== 200) {
      throw response;
    }

    const { access_token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${access_token}`;

    const { data: profile } = yield call(api.get, '/api/user');

    yield put(signInSuccess(access_token, profile));
    if (redirect) {
      if (profile.role !== 'clientes') {
        navigateTo('/dashboard');
      } else {
        navigateTo(`users/${profile.id}`);
      }
    }
  } catch (error) {
    toast.error('Falha na autenticação!');

    yield put(signFailure(Types.SIGN_IN.FAILURE));
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  navigateTo('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(Types.SIGN_IN.REQUEST, signIn),
  takeLatest(Types.SIGN_OUT, signOut),
]);
