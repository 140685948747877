import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 7rem;
  margin-bottom: 5rem;
`;

Container.Cart = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.06);
`;

export const Title = styled.h2`
  font-weight: 300;
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

export const Divider = styled.hr`
  max-width: 80%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;
