/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as Icon from 'react-feather';
import { goToTop, removeHash } from 'react-scrollable-anchor';

import LoginForm from './components/LoginForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import Register from './components/Register';
import { Container, LogoCmp, Menu, Navbar } from './styles';

import { navigateTo } from '~/services/history';
import SimpleModal from '~/components/Modal';

export default function SimpleHeader() {
  const { setting } = useSelector(state => state.setting);
  const [step, setStep] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const { signed, profile } = useSelector(state => state.auth);
  const titlesByStep = {
    1: 'Login',
    2: 'Recuperar senha',
    3: 'Registre-se',
  };

  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  useEffect(() => {
    if (signed && openLogin) {
      if (profile?.role === 'cdl' || profile?.role === 'empresas') {
        navigateTo('/dashboard');
      } else if (profile.profile_id === 3) {
        navigateTo(document.location.pathname);
      } else {
        navigateTo(`/users/${profile?.id}`);
      }
      setOpenLogin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signed]);

  const renderForm = () => {
    switch (step) {
      case 1:
        return <LoginForm setStep={setStep} />;
      case 2:
        return <ForgotPasswordForm setStep={setStep} />;
      case 3:
        return <Register setStep={setStep} />;
      default:
        break;
    }
  };

  return (
    <>
      <SimpleModal
        open={openLogin}
        title={titlesByStep[step]}
        onClose={handleCloseLogin}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid
          container
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          {renderForm()}
        </Grid>
      </SimpleModal>

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Container backgroundColor={setting?.bg_header}>
          <LogoCmp.Container>
            <Link
              to="/"
              onClick={() => {
                removeHash();
                goToTop();
              }}
              rel="noreferrer"
            >
              <LogoCmp
                src={require('~/assets/images/logo_cdl_w.png')}
                alt="logo CDL"
              />
            </Link>
          </LogoCmp.Container>

          <div>
            <Menu onClick={() => setCollapsed(!collapsed)}>
              <Icon.Menu size={24} />
            </Menu>

            <Navbar collapsed={collapsed} backgroundColor={setting?.bg_header}>
              <Navbar.Item
                color={setting?.color_text_header}
                to="/"
                onClick={() => {
                  removeHash();
                  goToTop();
                }}
              >
                Início
              </Navbar.Item>
              <Navbar.Ancora
                href="/#participantes"
                rel="noreferrer"
                color={setting?.color_text_header}
              >
                Lojas Participantes
              </Navbar.Ancora>
              <Navbar.Ancora
                href="/#patrocinadores"
                rel="noreferrer"
                color={setting?.color_text_header}
              >
                Patrocinadores
              </Navbar.Ancora>
              <Navbar.Ancora
                href="/#apoiadores"
                rel="noreferrer"
                color={setting?.color_text_header}
              >
                Apoiadores
              </Navbar.Ancora>
              <Navbar.Item
                to="/como-funciona"
                rel="noreferrer"
                color={setting?.color_text_header}
              >
                Como Funciona
              </Navbar.Item>
              {/* <Navbar.Ancora
                href="https://www.usinadoingresso.com.br/#/evento/125/liquidaqui"
                target="_blank"
                rel="noreferrer"
                color={setting?.color_text_header}
              >
                Compre seu Camarote
              </Navbar.Ancora> */}
              {!signed && (
                <Navbar.Item
                  to="/register"
                  rel="noreferrer"
                  color={setting?.color_text_header}
                >
                  Registre-se
                </Navbar.Item>
              )}
              <Navbar.Item
                to={`${
                  !signed
                    ? `${document.location.pathname}`
                    : signed && profile.role === 'clientes'
                    ? '/profile'
                    : '/dashboard'
                }`}
                onClick={() => {
                  if (!signed) {
                    handleOpenLogin();
                  }
                }}
                rel="noreferrer"
                color={setting?.color_text_header}
              >
                {signed ? 'Minha Conta' : 'Login'}
              </Navbar.Item>
              {signed && (
                <Navbar.Item
                  to="/cart"
                  rel="noreferrer"
                  isCart
                  color={setting?.color_text_header}
                >
                  <Icon.ShoppingCart size={24} color="white" />
                  <p style={{ display: 'none' }}>Carrinho</p>
                </Navbar.Item>
              )}
            </Navbar>
          </div>
        </Container>
      </div>
    </>
  );
}
