import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container as MaterialContainer, Button } from '@material-ui/core';
import { goToTop } from 'react-scrollable-anchor';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Form,
  ContainerForm,
  Title,
  TitleContainer,
  Divider,
} from './styles';

import { SimpleHeader, Input } from '~/components';
import api from '~/services/api';
import { navigateTo } from '~/services/history';
import { cpfMask, telefoneMask } from '~/utils';
import { signInRequest } from '~/store/modules/auth/actions';

export default function Register() {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit, formState, reset } = useForm();
  const { isSubmitting } = formState;

  const [maskCPF, setMaskCPF] = useState('');
  const [maskTelefone, setMaskTelefone] = useState('');

  const { profile } = useSelector(state => state.auth);

  const onSubmit = async ({ name, email, cpf, phone, password }) => {
    try {
      const response = await api.post('/api/register', {
        name,
        email,
        cpf,
        phone,
        password,
        profile_id: 3,
      });

      if (response.data && !profile) {
        dispatch(signInRequest(email, password));
      }

      reset();
      setMaskCPF('');
      setMaskTelefone('');

      toast.success(
        'Foi enviado um SMS para telefone cadastrado, clique no link informado para ativa conta!'
      );

      navigateTo('/');

      // eslint-disable-next-line no-empty
    } catch (error) {
      if (error.response) {
        const { errors: responseErrors } = error.response.data;

        if (responseErrors) {
          toast.error(
            responseErrors.cpf ? responseErrors.cpf[0] : responseErrors.email[0]
          );
        }
      }
    }
  };

  useEffect(() => {
    document.title = 'LIQUIDAQUI 2022 - Registre-se';
    goToTop();
  }, []);

  return (
    <>
      <SimpleHeader />

      <Container>
        <TitleContainer>
          <Title>Registre-se</Title>

          <Divider />
        </TitleContainer>

        <MaterialContainer
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <ContainerForm>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* Email */}
              <Input
                inputRef={register({ required: 'Este campo é obrigatório' })}
                label="Nome Completo"
                type="text"
                name="name"
                autoFocus
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                withDefaultStyle
              />

              <Input
                inputRef={register({ required: 'Este campo é obrigatório' })}
                label="CPF"
                type="text"
                name="cpf"
                value={maskCPF}
                onChange={({ target }) => {
                  setMaskCPF(cpfMask(target.value));
                }}
                error={Boolean(errors.cpf)}
                helperText={errors.name?.message}
                withDefaultStyle
              />

              <Input
                inputRef={register({ required: 'Este campo é obrigatório' })}
                label="Telefone"
                type="telefone"
                name="phone"
                value={maskTelefone}
                onChange={({ target }) => {
                  setMaskTelefone(telefoneMask(target.value));
                }}
                error={Boolean(errors.phone)}
                helperText={errors.name?.message}
                withDefaultStyle
              />

              <Input
                inputRef={register({ required: 'Este campo é obrigatório' })}
                label="E-mail"
                type="email"
                name="email"
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                withDefaultStyle
              />

              <Input
                inputRef={register({ required: 'Este campo é obrigatório' })}
                label="Senha"
                type="password"
                name="password"
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                withDefaultStyle
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                fullWidth
                disabled={isSubmitting}
              >
                Cadastrar
              </Button>
            </Form>
          </ContainerForm>
        </MaterialContainer>
      </Container>
    </>
  );
}
