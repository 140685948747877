import React from 'react';
import PropTypes from 'prop-types';

// Components
import Centered from '../Centered';
// Styled components
import { Emoji, Title, Message } from './styles';

export default function Error({ title, message, status, children }) {
  const emojisBySatus = {
    500: '😱',
    404: '😅',
    401: '🤔',
  };

  return (
    <Centered style={{ textAlign: 'center' }}>
      <div>
        {status && <Emoji>{emojisBySatus[status]}</Emoji>}
        {title && <Title>{title}</Title>}
        {message && <Message>{message}</Message>}

        {children}
      </div>
    </Centered>
  );
}

Error.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  status: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

Error.defaultProps = {
  message: null,
  children: null,
};
