import produce from 'immer';

import {
  FETCH_COMPANIES_REQUESTED,
  FETCH_COMPANIES_SUCCEDED,
  FETCH_COMPANIES_FAILED,
  FETCH_COMPANY_REQUESTED,
  FETCH_COMPANY_SUCCEDED,
  FETCH_COMPANY_FAILED,
  REMOVE_PRODUCT_SUCCESS,
  CLEAR_COMPANY,
} from './types';

const companyInitialState = {
  loading: false,
  error: false,
  company: {},
  companies: {
    page: 1,
    lastPage: 1,
    data: [],
  },
};

const Company = (state = companyInitialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_COMPANIES_REQUESTED:
        draft.loading = true;
        draft.error = false;
        draft.companies = [];
        break;
      case FETCH_COMPANIES_SUCCEDED:
        draft.loading = false;
        draft.error = false;
        draft.companies = action.payload.companies;
        break;
      case FETCH_COMPANIES_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.companies = [];
        break;
      case FETCH_COMPANY_REQUESTED:
        draft.loading = true;
        draft.error = false;
        draft.company = {};
        break;
      case FETCH_COMPANY_SUCCEDED:
        draft.loading = false;
        draft.error = false;
        draft.company = action.payload.company;
        break;
      case FETCH_COMPANY_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.company = {};
        break;
      case REMOVE_PRODUCT_SUCCESS:
        draft.company = {
          ...state.company,
          products: state.company.products.filter(
            product => product.id !== action.payload.productId
          ),
        };
        break;
      case CLEAR_COMPANY:
        draft.company = companyInitialState.company;
        break;
      default:
    }
  });
};

export default Company;
