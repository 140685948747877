import {
  FETCH_ORDERS_REQUESTED,
  FETCH_ORDERS_SUCCEDED,
  FETCH_ORDERS_FAILED,
} from './types';

export const fetchOrdersRequested = (page = 1, term = null, id, type) => ({
  type: FETCH_ORDERS_REQUESTED,
  payload: {
    id,
    page,
    type,
    term,
  },
});

export const fetchOrdersSucceded = orders => ({
  type: FETCH_ORDERS_SUCCEDED,
  payload: {
    orders,
  },
});

export const fetchOrdersFailed = error => ({
  type: FETCH_ORDERS_FAILED,
  payload: {
    error,
  },
});
