import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { Colors } from '~/themes';

export const Form = styled.form`
  flex: 1;
  padding: 20px;
  max-width: 450px;
`;

export const Image = styled.img`
  display: block;
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 62px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextLink = styled(Link)`
  color: #ff00ff;
`;

export const ErrorMessage = styled.span`
  display: block;
  font-size: 14px;
  margin-top: 6px;
  color: ${Colors.danger.backgroundColor};
`;

export const ForgotPasswordLink = styled(Link)`
  font-weight: 300;
  color: #9896ae;
  margin-bottom: 6px;
`;

export const Centered = styled.div`
  display: flex;
  flex: 1;
  ${props => {
    if (!props.avoidFullHeight) {
      return css`
        height: 50px;
      `;
    }
  }}
  justify-content: center;
  align-items: center;
`;
