/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { goToTop } from 'react-scrollable-anchor';
import * as Icon from 'react-feather';
import { toast } from 'react-toastify';

import { Container } from './styles';
import { MyAddress } from '../../components/Address';

import { SimpleHeader } from '~/components';
import history, { navigateTo } from '~/services/history';
import api from '~/services/api';

export function Address() {
  const [orders, setOrders] = useState();
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  async function getOrders() {
    setLoading(false);
    const { data } = await api.get('/api/cart');
    setOrders(data.order);
  }

  async function getAddresses() {
    setLoading(false);
    const { data } = await api.get('/api/addresses?is_cart=true');
    const newData = data.map(address => {
      if (address.default) {
        setSelectedAddress(address.id);
      }

      return {
        id: address.id,
        name: `${address.street}, ${address.number} - ${address.neighborhood} - ${address.city} - MT`,
        default: address.default,
      };
    });

    setAddresses(newData);
  }

  useEffect(() => {
    document.title = 'LIQUIDAQUI 2024 - Meu carrinho - Endereço';
    goToTop();
  }, []);

  function handleNext() {
    if (!selectedAddress) {
      return toast.warn('Precisa adicionar um novo endereço ou selecione');
    }

    api.put(`/api/cart/${orders?.order_id}/update`, {
      step: 'address',
      address_id: Number(selectedAddress),
    });

    navigateTo(`/cart/${orders?.order_id}/checkout-shipping`);
  }

  useEffect(() => {
    getOrders();
    getAddresses();
  }, [loading]);



  return (
    <>
      <SimpleHeader />

      <MyAddress
        open={open}
        close={closeModal}
        responseData={() => setLoading(true)}
      />

      <Container>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '80%' }}>
            <Button
              variant="contained"
              disableElevation
              onClick={history.goBack}
              style={{ marginBottom: 10 }}
            >
              <Icon.ChevronLeft />
              Voltar
            </Button>
            <Container.Cart>
              <div style={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    marginRight: 10,
                    backgroundColor: '#4BD37B',
                    color: 'white',
                  }}
                  onClick={openModal}
                >
                  Adicionar novo endereço
                  <Icon.ChevronRight />
                </Button>
              </div>
              <TableContainer component={Paper} style={{ marginTop: 15 }}>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow />
                    <TableRow>
                      <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                        Endereço
                      </TableCell>

                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addresses.length ? (
                      addresses.map(address => (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ width: 20 }}
                              keu={`address-${address.id}`}
                            >
                              <input
                                type="radio"
                                value={address.id}
                                name="address_id"
                                defaultChecked={address.default}
                                onChange={({ target }) =>
                                  setSelectedAddress(target.value)
                                }
                              />
                            </TableCell>

                            <TableCell>{address.name}</TableCell>
                          </TableRow>
                        </>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>Nenhum endereço cadastrado!</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 20,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleNext}
                  disabled={!selectedAddress}
                >
                  Próximo passo
                  <Icon.ChevronRight />
                </Button>
              </div>
            </Container.Cart>
          </div>
        </div>
      </Container>
    </>
  );
}
