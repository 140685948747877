import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import '@material/react-layout-grid/dist/layout-grid.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Poppins', sans-serif;
    background-color: #fafafa;
  }

  a {
    text-decoration: none;
  }

  ul,
  ol {
    list-style: none;
  }

  button {
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
  }

  img {
    width: 100%;
  }
.slick-prev:before {
  color: #c9c9c9;
}
.slick-next:before {
  color: #c9c9c9;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .slick-prev, .slick-next{
      display: none !important;
    }
}

#popup-modal .MuiPaper-root {
  background-color: transparent;
  width: 100%;

}

#popup-modal .MuiDialog-paperWidthSm {
  // max-width: 1200px !important;
  max-width: 866px !important;

}

#popup-modal .MuiPaper-elevation24 {
  box-shadow: none;
}

.rdw-editor-main{
    height: 400px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    margin-bottom: 20px;
  }

`;
