import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from '~/components';
import SimpleModal from '~/components/Modal';
import api from '~/services/api';

export function AlertCancel({ open, close, order, responseData }) {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  async function onSubmit({ reason_cancellation }) {
    setLoading(true);
    try {
      await api.put(`api/cancel/${order?.id}`, {
        reason_cancellation,
        user_id: order?.user_id,
      });
      close();
      responseData(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <SimpleModal
      open={open}
      title={`Motivo do cancelamento - #${order?.id}`}
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      width={650}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          type="textarea"
          name="reason_cancellation"
          columns={{ md: 12 }}
          multiline
          required
        />
        <Button
          type="submit"
          variant="contained"
          disableElevation
          fullWidth
          disabled={loading}
          style={{
            marginRight: 10,
            backgroundColor: loading ? '#d5d5d5' : '#4BD37B',
            color: 'white',
            marginTop: 10,
          }}
        >
          {loading ? 'Enviando...' : 'Cancelar Pedido'}
        </Button>
      </form>
    </SimpleModal>
  );
}
