import styled from 'styled-components';

import { Colors } from '~/themes';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Form = styled.form`
  flex: 1;
  padding: 20px;
  max-width: 450px;
`;

Form.Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;

  h2 {
    margin-bottom: 4px;
  }
`;

export const Image = styled.img`
  display: block;
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;

export const ErrorMessage = styled.span`
  display: block;
  font-size: 14px;
  text-align: center;
  margin-bottom: 24px;
  color: ${Colors.danger.backgroundColor};
`;
