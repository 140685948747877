/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { SponsorList } from './components/Sponsors/List';
import { BannerList } from './components/Banners/List';
import { EditionList } from './components/Editions/List';

import { Card, Layout } from '~/components';

export function Settings() {
  document.title = 'Configurações';

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <AppBar position="static" color="#ffffff">
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Edição" />
          <Tab label="Banners" />
          <Tab label="Realização/Patrocinadores/Apoiadores" />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <EditionList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BannerList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SponsorList />
      </TabPanel>
    </Layout>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Card>{children}</Card>}
    </div>
  );
}
