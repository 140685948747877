import produce from 'immer';

import {
  FETCH_ORDERS_REQUESTED,
  FETCH_ORDERS_SUCCEDED,
  FETCH_ORDERS_FAILED,
} from './types';

const couponInitialState = {
  loading: false,
  error: false,
  orders: {},
  submitted: false,
};

const Order = (state = couponInitialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_ORDERS_REQUESTED:
        draft.loading = true;
        draft.error = false;
        draft.orders = {};
        break;
      case FETCH_ORDERS_SUCCEDED:
        draft.loading = false;
        draft.error = false;
        draft.orders = action.payload.orders;
        draft.submitted = false;
        break;
      case FETCH_ORDERS_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.orders = {};
        break;

      default:
    }
  });
};

export default Order;
