/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Button, Tab, Tabs, AppBar } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Tag as TagIcon } from 'react-feather';
import { toast } from 'react-toastify';

import { Fieldset } from './styles';
import { AlertCancel } from './components/AlertCancel';

import { fetchCouponsRequested } from '~/store/modules/coupon/actions';
import { Layout, Card, Input, SimpleTable } from '~/components';
import api from '~/services/api';
import SimpleModal from '~/components/Modal';
import { fetchOrdersRequested } from '~/store/modules/order/actions';
import { toDinheiro } from '~/utils';

export default function Dashboard() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [indicators, setIndicators] = useState(null);

  const { register, errors, handleSubmit } = useForm();
  const [code, setCode] = useState('');
  const [coupon, setCoupon] = useState({});
  const [order, setOrder] = useState({});

  const { loading: loadingCoupon, errorCoupon, coupons } = useSelector(
    state => state.coupon
  );
  const { profile: user } = useSelector(state => state.auth);
  const { loading: loadingOrder, errorOrder, orders } = useSelector(
    state => state.order
  );
  const id = user?.company_id;
  const type = user?.role === 'empresas' ? 'company' : 'cdl';

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getIndicators() {
    try {
      const { data } = await api.get('/api/indicators');
      setIndicators(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    dispatch(fetchCouponsRequested(1, null, id, type));
    dispatch(fetchOrdersRequested(1, null, id, type));
    getIndicators();
  }, [dispatch, id, type, user]);

  document.title = 'Dashboard';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenOrder = () => {
    setOpenOrder(true);
  };

  const handleCloseOrder = () => {
    setOpenOrder(false);
  };

  const handleOpenCancel = () => {
    setOpenCancel(true);
  };
  const handleCloseCancel = () => {
    setOpenCancel(false);
  };

  async function finishOrder(orderId, userId) {
    try {
      await api.put(`api/finish/${orderId}`, {
        user_id: userId,
      });
      dispatch(fetchOrdersRequested(1, null, id, type));
    } catch (error) {}
  }

  const columns = [
    { title: 'Código do Cupom', field: 'code' },
    { title: 'Produto', field: 'product_description' },
    { title: 'Valor', field: 'value' },
    { title: 'Status', field: 'status' },
    { title: 'Cliente', field: 'user_name' },
    { title: 'Disponível', field: 'is_active' },
  ];

  const columnsCuponsCDL = [
    { title: 'Código do Cupom', field: 'code' },
    { title: 'Produto', field: 'product_description' },
    { title: 'Valor', field: 'value' },
    { title: 'Status', field: 'status' },
    { title: 'Empresa', field: 'company_name' },
    { title: 'Cliente', field: 'user_name' },
    { title: 'Disponível', field: 'is_active' },
  ];

  const columnsOrder = [
    { title: 'Numero', field: 'id' },
    {
      title: user?.role === 'cdl' ? 'Empresa' : 'Cliente',
      field: user?.role === 'cdl' ? 'company.fantasy' : 'user.name',
    },
    {
      title: 'Método de envio',
      field: 'method_send_description',
    },
    { title: 'Valor', field: 'amount' },
    { title: 'Status', field: 'status' },
  ];

  const onSubmit = async ({ code }) => {
    try {
      const request = {
        code,
        company_id: id,
      };

      const response = await api.post('api/coupons/validateCoupon', request);

      if (response.status !== 200) {
        toast.error('Ops, não foi possível consultar este cupom.');
        return;
      }

      if (response.data.status === 'validate') {
        toast.success(`👍 ${response.data.message}`, {
          position: 'top-center',
        });
        dispatch(fetchCouponsRequested(1, null, id, type));
        setCode('');
      } else if (response.data.status === 'retirado') {
        toast.info(`✋ ${response.data.message}`, { position: 'top-center' });
      } else if (response.data.status === 'expire') {
        toast.info(`😔 ${response.data.message}`, { position: 'top-center' });
      } else {
        toast.error(`👎 ${response.data.message}`, {
          position: 'top-center',
        });
      }
    } catch (e) {
      if (e.response) {
        const { errors } = e.response.data;
        if (errors.length) {
          toast.error(errors[0]);
        }
      }
    }
  };

  const actions = [
    {
      icon: 'search',
      tooltip: 'Detalhe do Cupom',
      onClick: (event, coupon) => {
        setCoupon(coupon);
        handleOpen();
      },
    },
  ];

  const actionsOrder = [
    rowData => ({
      icon: 'delete-forever',
      tooltip: 'Cancelar pedido',
      onClick: (event, order) => {
        if (order.status_int === 4) {
          return toast.warn(
            'Ops, não pode cancelar pedido que foi finalizado!'
          );
        }

        if (order.status_int === 3) {
          return toast.warn(`Pedido #${order.id}, já encontra-se cancelado!`);
        }
        setOrder(order);
        handleOpenCancel();
      },
      disabled:
        rowData.status_int === 1 ||
        rowData.status_int === 4 ||
        rowData.status_int === 3,
    }),
    rowData => ({
      icon: 'check-circle',
      tooltip: 'Finalizar pedido',
      onClick: (event, order) => {
        if (order.status_int === 4) {
          return toast.warn('Ops, não pode finalizar um pedido cancelado!');
        }

        if (order.status_int === 3) {
          return toast.warn(`Pedido #${order.id}, já encontra-se finalizado!`);
        }
        finishOrder(order.id, order.user_id);
      },
      disabled:
        rowData.status_int === 1 ||
        rowData.status_int === 4 ||
        rowData.status_int === 3,
    }),
    {
      icon: 'search',
      tooltip: 'Detalhe do Pedido',
      onClick: (event, order) => {
        setOrder(order);
        handleOpenOrder();
      },
    },
  ];

  const renderDetalhe = () => {
    return (
      <>
        <Fieldset>
          <Fieldset.legend>Cliente</Fieldset.legend>
          <Fieldset.gridContainer>
            <Fieldset.label>Nome</Fieldset.label>
            <Fieldset.label>Telefone</Fieldset.label>
            <Fieldset.label>E-mail</Fieldset.label>

            <Fieldset.text>{coupon.user_name}</Fieldset.text>
            <Fieldset.text>{coupon.user_phone}</Fieldset.text>
            <Fieldset.text>{coupon.user_email}</Fieldset.text>
          </Fieldset.gridContainer>
        </Fieldset>
        <Fieldset>
          <Fieldset.legend>Detalhe do Cupom</Fieldset.legend>
          <Fieldset.gridContainer>
            <Fieldset.label>Código do Cupom</Fieldset.label>
            <Fieldset.label>Produto</Fieldset.label>
            <Fieldset.label>Valor</Fieldset.label>

            <Fieldset.text>{coupon.code}</Fieldset.text>
            <Fieldset.text>{coupon.product_description}</Fieldset.text>
            <Fieldset.text>{coupon.value}</Fieldset.text>
          </Fieldset.gridContainer>
        </Fieldset>
      </>
    );
  };

  const renderDetalheOrder = () => {
    return (
      <>
        <di style={{ textAlign: 'center' }}>
          {order?.status_int === 1 ? (
            <h2 style={{ color: 'green' }}>{order?.status}</h2>
          ) : (
            <h2>{order?.status}</h2>
          )}
          <p>Número do pedido: #{order?.id}</p>
        </di>
        <Fieldset>
          <Fieldset.legend>Cliente</Fieldset.legend>
          <Fieldset.gridContainer>
            <Fieldset.label>Nome</Fieldset.label>
            <Fieldset.label>Telefone</Fieldset.label>
            <Fieldset.label>E-mail</Fieldset.label>

            <Fieldset.text>{order?.user?.name}</Fieldset.text>
            <Fieldset.text>{order?.user?.phone}</Fieldset.text>
            <Fieldset.text>{order?.user?.email}</Fieldset.text>
          </Fieldset.gridContainer>
        </Fieldset>
        <Fieldset>
          <Fieldset.legend>Endereço</Fieldset.legend>

          {order?.address_id ? (
            <Fieldset.text
              style={{ padding: 10 }}
            >{`${order?.address?.street}, ${order?.address?.number} - ${order?.address?.neighborhood}, ${order?.address?.city} - MT`}</Fieldset.text>
          ) : (
            <Fieldset.text style={{ padding: 10, color: 'red' }}>
              Endereço não informado.
            </Fieldset.text>
          )}
        </Fieldset>
        <Fieldset>
          <Fieldset.legend>Produtos</Fieldset.legend>

          {order?.items?.map(item => (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 10,
                }}
              >
                <div>
                  <strong>Descrição</strong>
                  <p>{item.product.description}</p>
                </div>
                <div style={{ width: 100, textAlign: 'end' }}>
                  <strong>Valor</strong>
                  <p>{toDinheiro(item.total)}</p>
                </div>
              </div>
            </>
          ))}
          <div style={{ textAlign: 'end', marginTop: 14 }}>
            <strong>Total</strong>
            <p>{toDinheiro(order?.total)}</p>
          </div>
        </Fieldset>
        <Fieldset>
          <Fieldset.legend>Forma de Pagamento</Fieldset.legend>
          {order?.payment_id ? (
            <Fieldset.text style={{ padding: 10 }}>
              {order?.payment?.name}
            </Fieldset.text>
          ) : (
            <Fieldset.text style={{ padding: 10, color: 'red' }}>
              Forma de pagamento não informado.
            </Fieldset.text>
          )}
        </Fieldset>
        <Fieldset>
          <Fieldset.legend>Metodo de envio</Fieldset.legend>
          <Fieldset.text style={{ padding: 10 }}>
            {order?.method_send_description}
          </Fieldset.text>
        </Fieldset>
        {order?.status_int === 4 && order?.reason_cancellation && (
          <Fieldset>
            <Fieldset.legend>Motivo do cancelamento</Fieldset.legend>
            <Fieldset.text style={{ padding: 10, color: 'red' }}>
              {order?.reason_cancellation}
            </Fieldset.text>
          </Fieldset>
        )}
      </>
    );
  };

  return (
    <Layout>
      <AlertCancel
        open={openCancel}
        close={handleCloseCancel}
        order={order}
        responseData={success => {
          if (success) {
            dispatch(fetchOrdersRequested(1, null, id, type));
          }
        }}
      />
      <AppBar position="static" color="#ffffff">
        <Tabs value={value} onChange={handleChange}>
          {user?.role === 'cdl' && <Tab label="Dashboard" />}
          <Tab label="Pedidos" />
          <Tab
            label={user?.role === 'empresas' ? 'Validar cupons' : 'Cupons'}
          />
        </Tabs>
      </AppBar>
      {user?.role === 'cdl' && (
        <TabPanel value={value} index={0}>
          {indicators && (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 10,
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0px 0px 4px 0px #000000',
                  padding: 15,
                  borderRadius: 10,
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <b>Total de Lojas Publicada</b>
                </p>
                <p style={{ textAlign: 'center', fontSize: 30, marginTop: 5 }}>
                  {indicators.companies_with_published_and_analysis.published}
                </p>

                <p style={{ textAlign: 'center' }}>
                  <b>Total de Lojas em Análise</b>
                </p>
                <p style={{ textAlign: 'center', fontSize: 30, marginTop: 5 }}>
                  {indicators.companies_with_published_and_analysis.analysis}
                </p>
                <p style={{ textAlign: 'center' }}>
                  <b>Total</b>
                </p>
                <p style={{ textAlign: 'center', fontSize: 30, marginTop: 5 }}>
                  {indicators.companies.quantity}
                </p>
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0px 0px 4px 0px #000000',
                  padding: 15,
                  borderRadius: 10,
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <b>Produtos</b>
                </p>
                <p>
                  <b>Quantidade</b>
                </p>
                <p>{indicators.products_presential.quantity}</p>

                <p>
                  <b>Valor médio dos produtos presencial</b>
                </p>
                <p>{toDinheiro(indicators.products_presential.value)}</p>
                <hr style={{ margin: 10 }} />
                <p>
                  <b>Quantidade</b>
                </p>
                <p>{indicators.products_online.quantity}</p>

                <p>
                  <b>Valor médio dos produtos online</b>
                </p>
                <p>{toDinheiro(indicators.products_online.value)}</p>
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0px 0px 4px 0px #000000',
                  padding: 15,
                  borderRadius: 10,
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <b>Vendas</b>
                </p>
                <p>
                  <b>Quantidade</b>
                </p>
                <p>{indicators.sales.quantity}</p>

                <p>
                  <b>Valor das Vendas</b>
                </p>
                <p>{toDinheiro(indicators.sales.value)}</p>
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0px 0px 4px 0px #000000',
                  padding: 15,
                  borderRadius: 10,
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <b>Cupons</b>
                </p>
                <p>
                  <b>Gerados</b>
                </p>
                <p>{indicators.coupons.generated}</p>
                <p>
                  <b>Resgatados</b>
                </p>
                <p>{indicators.coupons.ransomed}</p>
                <p>
                  <b>Utilizado</b>
                </p>
                <p>{indicators.coupons.used}</p>
                <p>
                  <b>Expirados</b>
                </p>
                <p>{indicators.coupons.expired}</p>
              </div>
            </div>
          )}
        </TabPanel>
      )}
      <TabPanel value={value} index={user?.role === 'cdl' ? 1 : 0}>
        <SimpleModal
          open={openOrder}
          title=""
          onClose={handleCloseOrder}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          width={650}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {renderDetalheOrder()}
          </div>
        </SimpleModal>

        <SimpleTable
          loading={loadingOrder}
          error={errorOrder}
          columns={columnsOrder}
          rows={orders.data}
          actions={actionsOrder}
          paginable={{
            page: orders.current_page,
            lastPage: orders.next_page_url,
            onPageChange: page =>
              dispatch(fetchOrdersRequested(page, null, id, type)),
          }}
          onSearchChange={term =>
            dispatch(fetchOrdersRequested(orders.page, term, id, type))
          }
        />
      </TabPanel>
      <>
        <TabPanel value={value} index={user?.role === 'cdl' ? 2 : 1}>
          <SimpleModal
            open={open}
            title=""
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            width={650}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {renderDetalhe()}
            </div>
          </SimpleModal>

          {user?.role === 'empresas' && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Input
                  inputRef={register({
                    required: 'Este campo é obrigatório',
                  })}
                  label="Validar codigo do cupom"
                  type="text"
                  name="code"
                  value={code}
                  onChange={({ target }) =>
                    setCode(target.value.toUpperCase().substring(0, 6))
                  }
                  error={Boolean(errors.code)}
                  helperText={errors.code?.message}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    marginLeft: -5,
                    height: 56,
                  }}
                >
                  <TagIcon size={22} />
                </Button>
              </div>
            </form>
          )}

          <SimpleTable
            loading={loadingCoupon}
            error={errorCoupon}
            columns={user?.role === 'empresas' ? columns : columnsCuponsCDL}
            rows={coupons.data}
            actions={actions}
            paginable={{
              page: coupons.current_page,
              lastPage: coupons.next_page_url,
              onPageChange: page =>
                dispatch(fetchCouponsRequested(page, null, id, type)),
            }}
            onSearchChange={term =>
              dispatch(fetchCouponsRequested(coupons.page, term, id, type))
            }
          />
        </TabPanel>
      </>
    </Layout>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Card>{children}</Card>}
    </div>
  );
}
