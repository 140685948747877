import {
  FETCH_COMPANIES_REQUESTED,
  FETCH_COMPANIES_SUCCEDED,
  FETCH_COMPANIES_FAILED,
  FETCH_COMPANY_REQUESTED,
  FETCH_COMPANY_SUCCEDED,
  FETCH_COMPANY_FAILED,
  CLEAR_COMPANY,
  REMOVE_PRODUCT_SUCCESS,
} from './types';

export const fetchCompaniesRequested = (page = 1, term = null) => ({
  type: FETCH_COMPANIES_REQUESTED,
  payload: {
    page,
    term,
  },
});

export const fetchCompaniesSucceded = companies => ({
  type: FETCH_COMPANIES_SUCCEDED,
  payload: {
    companies,
  },
});

export const fetchCompaniesFailed = error => ({
  type: FETCH_COMPANIES_FAILED,
  payload: {
    error,
  },
});

export const fetchCompanyRequested = id => ({
  type: FETCH_COMPANY_REQUESTED,
  payload: {
    id,
  },
});

export const fetchCompanySucceded = company => ({
  type: FETCH_COMPANY_SUCCEDED,
  payload: {
    company,
  },
});

export const fetchCompanyFailed = error => ({
  type: FETCH_COMPANY_FAILED,
  payload: {
    error,
  },
});

export const removeProductSuccess = productId => ({
  type: REMOVE_PRODUCT_SUCCESS,
  payload: {
    productId,
  },
});

export const clearCompany = () => ({
  type: CLEAR_COMPANY,
});
