import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Routes from './routes';
import history from './services/history';
import { store, persistor } from './store';

import GlobalStyle from './components/GlobalStyle';
import Colors from '~/themes/Colors';

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    palette: {
      primary: {
        main: Colors.primary.backgroundColor,
        contrastText: Colors.primary.color,
      },
      secondary: {
        main: Colors.secondary.backgroundColor,
        contrastText: Colors.secondary.color,
      },
      danger: {
        main: Colors.danger.backgroundColor,
        contrastText: Colors.danger.color,
      },
      tonalOffset: 0.1,
    },
  });

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <ToastContainer />

            <Routes />

            <GlobalStyle />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
