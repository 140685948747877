import styled, { css } from 'styled-components';

import Colors from '~/themes/Colors';

export const Container = styled.div`
  margin-top: 10rem;
  margin-bottom: 5rem;

  @media (max-width: 602px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;

export const CompanyLogo = styled.img`
  display: block;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
`;

export const CompanyContact = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0 15px;
  padding: 15px 35px;
  border-radius: 8px;
  background-color: #ebebeb;

  @media (max-width: 500px) {
    padding: 23px 23px;
    width: 100px;
    font-size: 11px;
  }
`;

export const CompanyContactItem = styled.li`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 12px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const Title = styled.h2`
  font-weight: 300;
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

export const Divider = styled.hr`
  max-width: 50px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const DivImg = styled.div`
  margin: 0 auto;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
`;

export const Thumbnail = styled.img`
  height: inherit;
  max-width: 100%;
  object-fit: cover;
`;

export const EmptyImg = styled.div`
  width: 100%;
  height: 40rem;
  border-radius: 6px;

  ${({ src }) =>
    src &&
    css`
      background: no-repeat center url('${src}');
      background-size: contain;
    `}
`;

export const SearchInput = styled.input`
  width: 500px;
  height: 40px;

  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  border: none;
  border: 1px solid #c9c9c9;
  border-radius: 5px;

  outline-color: ${Colors.primary.backgroundColor};
  -webkit-outline-color: ${Colors.primary.backgroundColor};

  &:hover {
    border-color: #000;
  }
  &::placeholder {
    color: #c9c9c9;
  }

  @media only screen and (max-width: 500px) {
    width: 335px;
  }

  @media only screen and (max-width: 300px) {
    width: 250px;
  }
`;

export const LinkSocial = styled.a``;
