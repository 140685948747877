// Buscar os perfis dos usuários.
export const FETCH_PROFILES_REQUESTED = 'FETCH_PROFILES_REQUESTED';
export const FETCH_PROFILES_SUCCEEDED = 'FETCH_PROFILES_SUCCEDED';
export const FETCH_PROFILES_FAILED = 'FETCH_PROFILES_FAILED';

// Buscar o perfil do usuário.
export const FETCH_PROFILE_REQUESTED = 'FETCH_PROFILE_REQUESTED';
export const FETCH_PROFILE_SUCCEEDED = 'FETCH_PROFILE_SUCCEEDED';
export const FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED';

export const CLEAR_PROFILE = 'CLEAR_PROFILE';
