import React, { useEffect } from 'react';
import { goToTop } from 'react-scrollable-anchor';

import { Container, TitleContainer, Title, Divider, Text } from './styles';

import { SimpleHeader, SimpleFooter } from '~/components';
import { API_URL } from '~/.env';

export function Regulation() {
  useEffect(() => {
    document.title = 'LIQUIDAQUI 2024 - Regulamento?';
    goToTop();
  }, []);

  return (
    <>
      <SimpleHeader />

      <Container>
        <TitleContainer>
          <Title>Regulamentos</Title>

          <Divider />
        </TitleContainer>
        <Text>
          <a
            href={`${API_URL}/documents/assemelhada_concurso_certificado_de_autorizacao.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            LiquidAqui 2022 Assemelhada Concurso - Certificado de Autorização
          </a>
        </Text>
        <Text>
          <a
            href={`${API_URL}/documents/assemelhada_concurso_regulamento_autorizado.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            LiquidAqui 2022 Assemelhada Concurso - Regulamento Autorizado
          </a>
        </Text>
        {/* <Text>
          <a
            href={`${API_URL}/documents/prova_dos_sorteados_concurso_certificado_de_autorizacao.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Prova dos Sorteados Concurso - Certificado de Autorização
          </a>
        </Text>
        <Text>
          <a
            href={`${API_URL}/documents/prova_dos_sorteados_concurso_regulamento_autorizado.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Prova dos Sorteados Concurso - Regulamento Autorizado
          </a>
        </Text> */}
      </Container>
      <SimpleFooter />
    </>
  );
}
