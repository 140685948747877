import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PrivateRoute from './PrivateRoute';

// Pages
import Home from '~/pages/Home';
import PasswordForgot from '~/pages/PasswordForgot';
import PasswordReset from '~/pages/PasswordReset';
import UsersList from '~/pages/Users/List';
import UserDetails from '~/pages/Users/Details';
import UsersForm from '~/pages/Users/Form';
import CompanyForm from '~/pages/Companies/Form';
import CompanyDetails from '~/pages/Companies/Details';
import CompaniesList from '~/pages/Companies/List';
import ProductsForm from '~/pages/Products/Form';
import ProductsList from '~/pages/Products/List';
import ProductsDetails from '~/pages/Products/Details';
import CuponsForm from '~/pages/Cupons/Form';
import CuponsList from '~/pages/Cupons/List';
import Dashboard from '~/pages/Dashboard';
import NotFound from '~/pages/NotFound';
import Register from '~/pages/Register';
import Participant from '~/pages/Participant';
import About from '~/pages/About';
import Profile from '~/pages/Profile';
import ProfileForm from '~/pages/Profile/Form';
import { AccountValidation } from '~/pages/account-validation';
import Cart from '~/pages/cart';
import { Address } from '~/pages/cart/steps/address';
import { CheckOut } from '~/pages/cart/steps/check-out';
import { CheckoutShipping } from '~/pages/cart/steps/checkout-shipping';
import { Regulation } from '~/pages/regulation';
import { fetchSettingRequested } from '~/store/modules/setting/actions';
import { Settings } from '~/pages/Settings';
import { SponsorForm } from '~/pages/Settings/components/Sponsors/Form';
import { EditionForm } from '~/pages/Settings/components/Editions/Form';
import { BannerForm } from '~/pages/Settings/components/Banners/Form';

export default function Routes() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettingRequested());
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/como-funciona" component={About} />
      <Route path="/register" component={Register} />
      <Route path="/account-validation/:token" component={AccountValidation} />
      <Route path="/regulamento" component={Regulation} />

      {/* Authentication */}
      <Route path="/password/forgot" component={PasswordForgot} />
      <Route path="/password/reset/:token" component={PasswordReset} />

      {/* Dashboard */}
      <PrivateRoute
        path="/dashboard"
        roles={['cdl', 'empresas']}
        component={Dashboard}
      />

      {/* Users */}
      <PrivateRoute
        exact
        path="/profile"
        roles={['clientes']}
        component={Profile}
      />
      <PrivateRoute path="/profile/:id/edit" component={ProfileForm} />
      <PrivateRoute path="/users" roles={['cdl']} exact component={UsersList} />
      <PrivateRoute
        path="/users/create"
        roles={['cdl', 'empresas']}
        exact
        component={UsersForm}
      />
      <PrivateRoute path="/users/:id" exact component={UserDetails} />
      <PrivateRoute path="/users/:id/edit" component={UsersForm} />

      {/* Companies */}
      <PrivateRoute
        path="/companies"
        roles={['cdl']}
        exact
        component={CompaniesList}
      />
      <PrivateRoute
        path="/companies/create"
        roles={['cdl', 'empresas']}
        exact
        component={CompanyForm}
      />
      <PrivateRoute
        path="/companies/:id"
        roles={['cdl', 'empresas']}
        exact
        component={CompanyDetails}
      />
      <PrivateRoute
        path="/companies/:id/edit"
        roles={['cdl', 'empresas']}
        component={CompanyForm}
      />

      {/* products */}
      <PrivateRoute path="/products" exact component={ProductsList} />
      <PrivateRoute
        path="/products/create"
        roles={['empresas']}
        exact
        component={ProductsForm}
      />

      <PrivateRoute path="/products/:id" exact component={ProductsDetails} />
      <PrivateRoute
        path="/products/company/:company_id/create"
        roles={['cdl']}
        exact
        component={ProductsForm}
      />
      <PrivateRoute
        path="/products/company/:id"
        exact
        roles={['cdl']}
        component={ProductsDetails}
      />
      <PrivateRoute
        path="/products/:id/edit"
        roles={['cdl', 'empresas']}
        component={ProductsForm}
      />

      {/* coupons */}
      <PrivateRoute path="/coupons" exact component={CuponsList} />
      <PrivateRoute path="/coupons/create" exact component={CuponsForm} />
      <PrivateRoute path="/coupons/:id/edit" exact component={CuponsForm} />

      {/* Cart */}
      <PrivateRoute path="/cart" exact component={Cart} />
      <PrivateRoute path="/cart/:orderId/address" exact component={Address} />
      <PrivateRoute
        path="/cart/:orderId/checkout-shipping"
        exact
        component={CheckoutShipping}
      />
      <PrivateRoute
        path="/cart/:orderId/check-out"
        exact
        component={CheckOut}
      />

      {/* Setting */}
      <PrivateRoute
        path="/settings"
        exact
        roles={['cdl']}
        component={Settings}
      />

      {/* Setting - edition */}
      <PrivateRoute
        roles={['cdl']}
        path="/settings/edition/create"
        exact
        component={EditionForm}
      />
      <PrivateRoute
        roles={['cdl']}
        path="/settings/edition/:id/edit"
        exact
        component={EditionForm}
      />

      {/* Setting - Banners */}
      <PrivateRoute
        roles={['cdl']}
        path="/settings/banners/create"
        exact
        component={BannerForm}
      />
      <PrivateRoute
        roles={['cdl']}
        path="/settings/banners/:id/edit"
        exact
        component={BannerForm}
      />

      {/* Setting - Sponsor */}
      <PrivateRoute
        roles={['cdl']}
        path="/settings/sponsor/create"
        exact
        component={SponsorForm}
      />
      <PrivateRoute
        roles={['cdl']}
        path="/settings/sponsor/:id/edit"
        exact
        component={SponsorForm}
      />

      <Route path="/:id" component={Participant} />

      {/* Not Found */}
      <Route component={NotFound} />
    </Switch>
  );
}
