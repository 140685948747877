import { all, takeLatest, call, put } from 'redux-saga/effects';

import { FETCH_PRODUCTS_REQUESTED, FETCH_PRODUCT_REQUESTED } from './types';
import {
  fetchProductsSucceded,
  fetchProductsFailed,
  fetchProductSucceded,
  fetchProductFailed,
} from './actions';

import api from '~/services/api';
import { diffForHumans, toDinheiro } from '~/utils';
import * as Env from '~/.env';

function* fetchProducts({ payload }) {
  const { page, term, id } = payload;
  const target = `/api/products?page=${page}&company_id=${id}${
    term ? `&search=${term}` : ''
  }`;

  try {
    const { data, ...response } = yield call(api.get, target);

    if (response.status !== 200) {
      throw response;
    }

    const products = {
      ...data,
      data: data.data.map(company => ({
        ...company,
        created_at: diffForHumans(company.created_at),
        is_active: company.is_active ? 'Sim' : 'Não',
        value: toDinheiro(company.value),
      })),
    };
    yield put(fetchProductsSucceded(products));
  } catch (error) {
    yield put(fetchProductsFailed(error));
  }
}

/**
 * Manipula a ação de buscar o perfil do usuário.
 *
 * @param {Object} payload
 */
function* fetchProduct({ payload }) {
  try {
    const response = yield call(api.get, `/api/products/${payload.id}`);

    if (response.status !== 200) {
      throw response;
    }

    const product = {
      ...response.data,
      product_url: response.data.files.find(
        file => file.default === true && file.file_type_id === 3
      )
        ? `${Env.API_URL}/storage/${
            response.data.files.find(
              file => file.default === true && file.file_type_id === 3
            ).filename
          }`
        : null,
      files: response.data.files
        .filter(file => file.default === false && file.file_type_id === 3)
        .map(productFile => {
          return productFile
            ? {
                id: productFile.id,
                name: `${Env.API_URL}/storage/${productFile.filename}`,
              }
            : null;
        }),
    };

    yield put(fetchProductSucceded(product));
  } catch (error) {
    yield put(fetchProductFailed(error));
  }
}

export default all([
  takeLatest(FETCH_PRODUCTS_REQUESTED, fetchProducts),
  takeLatest(FETCH_PRODUCT_REQUESTED, fetchProduct),
]);
