import styled from 'styled-components';

import Colors from '~/themes/Colors';

export const Hero = styled.div`
  display: flex;
  /* justify-content: ${props => props.justify ?? 'center'}; */
  /* align-items: ${props => props.align ?? 'center'}; */
  width: 100%;
  height: 0;
  background-color: #fff;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* object-fit: contain; */
  margin-top: 80px;
  padding-bottom: 42%;

  @media screen and (max-width: 1101px) {
    margin-top: 50px;
  }
`;

export const HeroImg = styled.img`
  @media only screen and (max-width: 974px) {
    max-width: 500px;
    margin-top: 0px !important;
  }

  @media only screen and (max-width: 500px) {
    max-width: 350px;
    margin-top: 0px !important;
  }

  @media only screen and (max-width: 350px) {
    max-width: 250px;
    margin-top: 0px !important;
  }
`;

export const SliderTitle = styled.p`
  font-size: 18px;
`;

export const SliderItem = styled.div`
  max-width: 200px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 0 10px;
  margin-left: 15px;

  display: flex;

  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  img {
    height: inherit;
    object-fit: contain;
  }

  @media only screen and (max-width: 700px) {
    /* height: 70px; */
    width: 120px;
  }

  @media only screen and (max-width: 500px) {
    height: 70px;
    /* width: 80px; */
  }
`;

export const SliderItemBanner = styled.div`
  height: ${({ height }) => `${height ? `${height}vh` : '328px'}`};

  background-color: ${({ color }) => color || '#fff'};
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  @media only screen and (max-width: 500px) {
    height: 30vh;
  }
`;

export const SliderItemPatrocinador = styled.div`
  max-width: 200px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 0 10px;
  margin-left: 15px;

  display: flex;

  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  cursor: pointer;

  img {
    height: inherit;
    object-fit: contain;
  }
`;

export const SliderApoiador = styled.div`
  -webkit-max-width: 200px;
  max-width: 200px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 0 10px;
  margin-left: 15px;

  display: flex;

  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  cursor: pointer;

  img {
    height: inherit;
    object-fit: contain;
    padding: 10px;
  }
`;

export const Title = styled.h2`
  font-weight: 300;
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
`;

export const Divider = styled.hr`
  max-width: 100px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const Participants = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

export const ParticipantsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid grey;
`;

export const Company = styled.div`
  display: flex;
  align-items: center;
`;

export const CompanyName = styled.div`
  margin-left: 20px;
`;

export const CompanyLogo = styled.img`
  width: auto;
  max-height: 32px;
`;

export const CouponIndicator = styled.div``;

export const SearchInput = styled.input`
  width: 500px;
  height: 40px;

  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  border: none;
  border: 1px solid #c9c9c9;
  border-radius: 5px;

  outline-color: ${Colors.primary.backgroundColor};

  &:hover {
    border-color: #000;
  }
  &::placeholder {
    color: #c9c9c9;
  }

  @media only screen and (max-width: 500px) {
    width: 335px;
  }

  @media only screen and (max-width: 300px) {
    width: 250px;
  }
`;

export const Apoiadores = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Patrocinadores = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
