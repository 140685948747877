import styled from 'styled-components';

export const Container = styled.header`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 300;
`;

Title.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #383838;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  padding: 5px;
  border-radius: 20px;
  background-color: #dff7d5;
`;
