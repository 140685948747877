/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { Grid, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';

import { MySelect } from './styles';

const Select = React.forwardRef(
  (
    { name, label, options, columns, onChange, visible, value, ...rest },
    ref
  ) => {
    const [targetValue, setTargetValue] = useState(value);

    return (
      <>
        <Grid item {...columns} style={{ display: visible ? 'block' : 'none' }}>
          <FormControl variant="outlined" fullWidth>
            <MySelect
              name={name}
              ref={ref}
              value={targetValue}
              onChange={e =>
                onChange
                  ? setTargetValue(onChange(e))
                  : setTargetValue(e.target.value)
              }
              {...rest}
            >
              {label && (
                <option selected value="">
                  {label}
                </option>
              )}
              {options.map(option => (
                <option value={option.value} key={option.value}>
                  {option.name}
                </option>
              ))}
            </MySelect>
          </FormControl>
        </Grid>
      </>
    );
  }
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  columns: PropTypes.object,
  visible: PropTypes.bool,
  value: PropTypes.string || PropTypes.number,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  label: '',
  value: '',
  visible: true,
};

export default Select;
