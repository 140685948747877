/* eslint-disable no-unused-vars */
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import differenceInHours from 'date-fns/differenceInHours';

import {
  fetchCouponsSucceded,
  fetchCouponsFailed,
  deleteCouponSucceded,
  deleteCouponFailed,
  deleteAllCouponsSucceded,
  deleteAllCouponsFailed,
} from './actions';
import {
  FETCH_COUPONS_REQUESTED,
  DELETE_COUPON,
  DELETE_ALL_COUPONS,
} from './types';

import api from '~/services/api';
import { toDinheiro, limit, diffForHumans } from '~/utils';

function* fetchCoupons({ payload }) {
  const { page, term, id, type } = payload;
  const target = `/api/coupons/products?page=${page}&id=${id}&type=${type}${
    term ? `&search=${term}` : ''
  }`;

  try {
    const response = yield call(api.get, target);

    if (response.status !== 200) {
      throw response;
    }

    const getHours = coupon => {
      const hours = differenceInHours(new Date(coupon.expire_at), new Date());

      if (coupon.used_at) {
        const [date, time] = coupon.used_at.split(' ');
        const [, month, day] = date.split('-');
        const [hour, minute] = time.split(':');
        return `Utilizado em ${day}/${month} as ${hour}:${minute}`;
      }

      if (hours <= 0) {
        const [date, time] = coupon.expire_at.split(' ');
        const [, month, day] = date.split('-');
        const [hour, minute] = time.split(':');
        return `Expirado em ${day}/${month} as ${hour}:${minute}`;
      }

      return `Expira em ${hours} horas`;
    };

    const coupons = {
      ...response.data,
      data: response.data.data.map(coupon => {
        return {
          ...coupon,
          user_name: coupon.user ? coupon.user.name : null,
          user_phone: coupon.user ? coupon.user.phone : null,
          user_email: coupon.user ? coupon.user.email : null,
          company_name:
            type !== 'company'
              ? coupon.product
                ? coupon.product.company.fantasy
                : null
              : null,
          product_description: coupon.product
            ? limit(30)(coupon.product.description)
            : null,
          value: toDinheiro(coupon.value),
          created_at: diffForHumans(coupon.created_at),
          is_active: coupon.is_active ? 'Sim' : 'Não',
          status: coupon.expire_at ? getHours(coupon) : '',
          delivery_at: coupon.delivery_at
            ? new Date(coupon.delivery_at).toLocaleString()
            : 'Não resgatado',
          used_at: coupon.used_at
            ? new Date(coupon.used_at).toLocaleString()
            : 'Não utilizado',
        };
      }),
    };

    yield put(fetchCouponsSucceded(coupons));
  } catch (error) {
    yield put(fetchCouponsFailed(error));
  }
}

function* deleteCoupon({ payload }) {
  const { id } = payload;

  if (!id) return;

  const target = `/api/coupons/${id}`;

  try {
    const response = yield call(api.delete, target);

    if (response.status !== 200) {
      throw response;
    }

    yield put(deleteCouponSucceded(true));
  } catch (error) {
    if (error.response) {
      const { errors } = error.response.data;

      toast.error(errors[0]);
    }

    yield put(deleteCouponFailed(error));
  }
}

function* deleteAll({ payload }) {
  const { confirmation, company_id, product_id } = payload;

  const target = `/api/coupons/deleteall?company_id=${company_id}&product_id=${product_id}`;

  if (!confirmation && !company_id) return;

  try {
    const response = yield call(api.get, target);

    if (response.status !== 200) {
      throw response;
    }

    yield put(deleteAllCouponsSucceded(true));
  } catch (error) {
    if (error.response) {
      const { errors } = error.response.data;

      toast.error(errors[0]);
    }
    yield put(deleteAllCouponsFailed(error));
  }
}

export default all([
  takeLatest(FETCH_COUPONS_REQUESTED, fetchCoupons),
  takeLatest(DELETE_COUPON, deleteCoupon),
  takeLatest(DELETE_ALL_COUPONS, deleteAll),
]);
