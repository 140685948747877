/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { map, clone } from 'lodash';
import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import * as Icon from 'react-feather';

import { Header, SearchGroup, Search } from './styles';

// Components
import { Layout, Centered, Error, Pagination } from '~/components';

export default function ResourcesPageWithTable({
  title,
  header,
  loading,
  columns,
  rows,
  actions,
  error,
  tableProps,
  onSearchChange,
  searchable,
  paginable,
  pageSize,
}) {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  const localization = {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'itens',
      firstTooltip: 'Primeira página',
      lastTooltip: 'Última página',
      previousTooltip: 'Anterior',
      nextTooltip: 'Próxima',
    },
    toolbar: {
      nRowsSelected: '{0} registros selecionados',
      searchTooltip: 'Pesquisar',
      searchPlaceholder: 'Pesquisar',
      pageSizeOptions: [5, 50],
    },
    header: {
      actions: '',
    },
    body: {
      emptyDataSourceMessage: 'Nenhum registro encontrado.',
      filterRow: {
        filterTooltip: 'Pesquisar',
      },
    },
  };

  const getContentToRender = () => {
    if (error) {
      return (
        <Centered>
          <Error
            status={500}
            title="Não foi possível carregar a lista de usuários."
          />
        </Centered>
      );
    }

    return (
      <>
        {header && (
          <Header>
            {header.action && (
              <Button
                variant="contained"
                disableElevation
                {...header.action.props}
              >
                {header.action.label}
              </Button>
            )}
          </Header>
        )}

        {searchable && (
          <Header>
            <SearchGroup>
              <Icon.Search
                style={{ marginRight: 14, marginLeft: 14 }}
                color="#8e8e8e"
                size={19}
              />

              <Search
                placeholder="Pesquisar (digite o termo e pressione 'Enter')"
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    onSearchChange(event.target.value);
                  }
                }}
              />
            </SearchGroup>
          </Header>
        )}

        <MaterialTable
          style={{ display: 'grid' }}
          isLoading={loading}
          columns={columns}
          data={map(rows, clone)}
          components={{
            Pagination: () =>
              paginable ? (
                <td style={{ padding: 15, display: 'block' }}>
                  <Pagination {...paginable} />
                </td>
              ) : null,
          }}
          localization={localization}
          actions={actions}
          options={{
            showTitle: false,
            search: false,
            actionsColumnIndex: -1,
            paginationType: 'stepped',
            pageSize,
            emptyRowsWhenPaging: false,
            headerStyle: {
              fontWeight: 600,
            },
            toolbar: false,
          }}
          {...tableProps}
        />
      </>
    );
  };

  return <Layout>{getContentToRender()}</Layout>;
}

ResourcesPageWithTable.propTypes = {
  title: PropTypes.string,
  header: PropTypes.object,
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.any,
  tableProps: PropTypes.object,
  onSearchChange: PropTypes.func,
  paginable: PropTypes.object,
  pageSize: PropTypes.number,
};

ResourcesPageWithTable.defaultProps = {
  title: '',
  header: null,
  loading: false,
  rows: [],
  actions: [],
  error: false,
  tableProps: {},
  onSearchChange: () => {},
  paginable: {},
  pageSize: 15,
};
