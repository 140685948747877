import distanceInWordsStrict from 'date-fns/formatDistanceStrict';
import pt from 'date-fns/locale/pt';
import get from 'lodash/get';

export function generateActions(prefix, action) {
  return {
    REQUEST: `@${prefix}/${action}_REQUEST`,
    SUCCESS: `@${prefix}/${action}_SUCCESS`,
    FAILURE: `@${prefix}/${action}_FAILURE`,
  };
}

/**
 * @param {String} template
 * @param {Object} target
 * @returns {String}
 */
export const getFromTemplate = template => target => {
  const expression = /\{(.*?)\}/g;
  const replaces = template.match(expression);

  // eslint-disable-next-line array-callback-return
  replaces.map(replaceable => {
    replaceable = replaceable.replace('{', '');
    replaceable = replaceable.replace('}', '');

    template = template.replace(replaceable, get(target, replaceable));

    template = template.replace('{', '');
    template = template.replace('}', '');
  });

  return template;
};

export const limit = (size, prepend = '...') => text => {
  if (text.length <= size) {
    return text;
  }

  return `${text.slice(0, size)}${prepend}`;
};

// Mascara para CPF 000.000.000-00
export const cpfMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

// Mascara para CNPJ 00.000.000/0000-00
export const cnpjMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

// Mascara para Telefone e Celular (00) 00000-0000
export const telefoneMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
};

// Mascara para CEP 000000-000
export const cepMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

export const toDinheiro = value => {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

// Doc: https://date-fns.org/v2.14.0/docs/formatDistanceStrict
export const diffForHumans = date => {
  return distanceInWordsStrict(new Date(date), new Date(), {
    locale: pt,
    addSuffix: true,
  });
};

export const popupCenter = (url, title, w, h) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : '';
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : '';

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : '';
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : '';

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 3 - h / 3 + dualScreenTop;

  const newWindow = window.open(
    url,
    title,
    `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`
  );

  // Puts focus on the newWindow
  if (newWindow && newWindow.focus) {
    newWindow.focus();
  }
};
