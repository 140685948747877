import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Input } from '~/components';
import ResourcePageWithForm from '~/layouts/ResourcePageWithForm';
import { fetchProfileRequested } from '~/store/modules/profile/actions';

export default function CuponsForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading /* profile */ } = useSelector(state => state.profile);

  useEffect(() => {
    if (id) {
      dispatch(fetchProfileRequested(id));
    }
  }, [dispatch, id]);

  const inputs = register => [
    <Input
      inputRef={register}
      label="Codigo"
      type="text"
      name="cnpj_cpf"
      required
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
    <Input
      inputRef={register}
      label="Valor"
      type="text"
      name="inscricao_estadual"
      required
      columns={{ md: 6, xs: 12, lg: 6 }}
    />,
  ];

  return (
    <ResourcePageWithForm
      title="Adicionar um novo cupom"
      target="/api/companies"
      inputs={inputs}
      loading={loading}
      submitButtonLabel="Salvar"
      onSuccess={() => toast.success('Empresa criada com sucesso.')}
      onError={() => toast.error('Não foi possível criar uma empresa.')}
    />
  );
}
