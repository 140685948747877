// Buscar os produtos.
export const FETCH_PRODUCTS_REQUESTED = 'FETCH_PRODUCTS_REQUESTED';
export const FETCH_PRODUCTS_SUCCEDED = 'FETCH_PRODUCTS_SUCCEDED';
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';

// Buscar o perfil da empresa.
export const FETCH_PRODUCT_REQUESTED = 'FETCH_PRODUCT_REQUESTED';
export const FETCH_PRODUCT_SUCCEDED = 'FETCH_PRODUCT_SUCCEDED';
export const FETCH_PRODUCT_FAILED = 'FETCH_PRODUCT_FAILED';

// Criar cupons para o produto.
export const CREATE_PRODUCT_COUPONS_REQUEST = 'CREATE_PRODUCT_COUPONS_REQUEST';
export const CREATE_PRODUCT_COUPONS_SUCCESS = 'CREATE_PRODUCT_COUPONS_SUCCESS';
export const CREATE_PRODUCT_COUPONS_FAIL = 'CREATE_PRODUCT_COUPONS_FAIL';

export const DELETE_PRODUCT_REQUESTED = 'DELETE_PRODUCT_REQUESTED';
export const DELETE_PRODUCT_SUCCEEDED = 'DELETE_PRODUCT_SUCCEEDED';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
