import styled, { css } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: fixed;
  width: 100%;
  min-height: 80px;
  /* margin-top: 20px; */
  padding: 10px 10px 5px 20px;
  /* border-radius: 8px; */
  background-color: ${props => props.backgroundColor ?? 'white'};
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.08);
  z-index: 9;
  top: 0;

  @media (max-width: 440px) {
    padding-left: 0px;
  }

  @media (max-width: 768px) {
    padding-left: 0px;
    min-height: 50px;
  }

  @media (max-width: 1100px) {
    min-height: 50px;
  }
`;

export const LogoCmp = styled.img`
  max-width: 120px;
  height: auto;
  margin-left: 20px;
  margin-right: 32px;

  @media (max-width: 440px) {
    width: 100%;
  }

  @media (max-width: 1121px) {
    width: 80px;
  }

  @media (max-width: 1100px) {
    width: 80px;
  }

  @media (max-width: 768px) {
    width: 60px;
  }
`;

LogoCmp.Container = styled.div`
  cursor: pointer;
  @media (max-width: 440px) {
    display: flex;
    justify-content: 'center';
  }
`;

export const Navbar = styled.nav`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0 auto;
  padding: 0;
  height: 64px;
  top: 90px;
  right: 0;

  @media (max-width: 1360px) {
    flex-direction: column;
    position: absolute;
    display: ${props => (props.collapsed ? 'flex' : 'none')};
    margin: -39px 0 0 auto;
    padding: 10px 15px;
    overflow: hidden;
    height: ${props => (props.collapsed ? 321 : 0)}px;
    border-radius: 6px;
    background-color: ${props => props.backgroundColor ?? 'white'};
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4);
  }
`;

Navbar.Item = styled(NavLink)`
  text-transform: uppercase;
  padding: 0 14px;
  color: ${props => props.color ?? '#383838'};

  ${props =>
    props.isCart &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      padding: 10px;
      border-radius: 8px;
      background-color: #5bb60a;
      @media (max-width: 1100px) {
        padding: 10px !important;

        p {
          display: block !important;
          color: white;
          margin-left: 10px;
        }
      }
    `}

  @media (max-width: 1100px) {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
  }

  @media (max-width: 1185px) {
    padding: 0 10px;
  }

  @media (max-width: 1127px) {
    padding: 0 8px;
  }
`;

Navbar.Ancora = styled.a`
  text-transform: uppercase;
  padding: 0 14px;
  color: ${props => props.color ?? '#383838'};

  @media (max-width: 1100px) {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
  }

  @media (max-width: 1185px) {
    padding: 0 10px;
  }

  @media (max-width: 1127px) {
    padding: 0 8px;
  }
`;

export const Menu = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 1360px) {
    display: flex;
    justify-content: space-between;

    width: 22px;

    margin-right: 18px;

    align-items: center;
  }

  @media (max-width: 440px) {
    margin-left: 5px;
  }
`;

export const MobileContainer = styled.div`
  display: flex;

  @media (max-width: 1360px) {
    flex: 1;
    justify-content: flex-end;
  }

  @media (max-width: 440px) {
    margin: 10px 0px 10px 10px;
  }
`;

export const MainSection = styled.div`
  display: flex;

  @media (max-width: 1360px) {
    flex: 1;
    align-items: center;
  }
`;

export const Form = styled.form`
  flex: 1;
  padding: 20px;
  max-width: 450px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ForgotPasswordLink = styled(Link)`
  font-weight: 300;
  color: #9896ae;
  margin-bottom: 6px;
`;

export const TextLink = styled(Link)`
  color: #5bb60a;
`;

export const Image = styled.img`
  display: block;
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 62px;
`;
