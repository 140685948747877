import React, { useEffect, useState } from 'react';
import { Container as MaterialContainer, Grid } from '@material-ui/core';
import * as Icon from 'react-feather';
import { goToTop } from 'react-scrollable-anchor';
import { useParams } from 'react-router-dom';
import { FaWhatsapp, FaFacebook, FaInstagram, FaLink } from 'react-icons/fa';

import {
  Container,
  CompanyLogo,
  CompanyContact,
  CompanyContactItem,
  TitleContainer,
  Title,
  Divider,
  DivImg,
  Thumbnail,
  EmptyImg,
  SearchInput,
  LinkSocial,
} from './styles';
import Product from './components/Product';

import { toDinheiro } from '~/utils';
import * as Env from '~/.env';
import ImgEmpty from '~/assets/images/empty.png';
import productPhotoEmpty from '~/assets/images/foto-nao-disponivel.png';
import { SimpleHeader, Centered, SimpleFooter } from '~/components';
import Loading from '~/assets/lottie/Loading';
import api from '~/services/api';

export default function Participant() {
  const { id } = useParams();
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  useEffect(() => {
    document.title = 'LIQUIDAQUI 2024 - Participantes';
    goToTop();
  }, [id]);

  useEffect(() => {
    async function loadProduct() {
      const response = await api.get(`/api/company/${id}`);

      const data = {
        ...response.dada,
        company: {
          ...response.data,
          logo_url:
            response.data.files.find(file => file.file_type_id === 1) &&
            `${Env.API_URL}/storage/${
              response.data.files.find(file => file.file_type_id === 1).filename
            }`,
          banner_url:
            response.data.files.find(file => file.file_type_id === 2) &&
            `${Env.API_URL}/storage/${
              response.data.files.find(file => file.file_type_id === 2).filename
            }`,
          social_whatsapp: response.data.whatsapp ? response.data.whatsapp : '',
          social_facebook: response.data.facebook ? response.data.facebook : '',
          social_instagram: response.data.instagram
            ? response.data.instagram
            : '',
          web_site: response.data.website ? response.data.website : '',
          city: response.data.city ? response.data.city.name : '',
          products: [
            ...response.data.products.map(product => {
              const newProduct = {
                ...product,
                product_url: product.files.find(
                  file => file.default === true && file.file_type_id === 3
                )
                  ? `${Env.API_URL}/storage/${
                      product.files.find(
                        file => file.default === true && file.file_type_id === 3
                      ).filename
                    }`
                  : productPhotoEmpty,
                thumbnails: product.files
                  .filter(
                    file => file.default === false && file.file_type_id === 3
                  )
                  .map(productFile => {
                    return productFile
                      ? {
                          id: productFile.id,
                          name: `${Env.API_URL}/storage/${productFile.filename}`,
                        }
                      : null;
                  }),
              };
              delete newProduct.files;
              return newProduct;
            }),
          ],
        },
      };

      delete data.company.files;
      setCompany(data.company);
      setLoading(false);
    }

    loadProduct();
  }, [id]);

  const styles = {
    marginRight: 10,
  };

  return (
    <>
      <SimpleHeader />
      {loading ? (
        <Centered>
          <Loading />
        </Centered>
      ) : (
        <Container>
          {company.id && (
            <>
              <MaterialContainer>
                <Grid container spacing={5} style={{ marginBottom: 52 }}>
                  <Grid item sm={6} xl={12} style={{ width: '100%' }}>
                    {company.logo_url && (
                      <CompanyLogo
                        src={company.logo_url}
                        alt={company.fantasy}
                      />
                    )}
                  </Grid>

                  <CompanyContact>
                    <ul>
                      <CompanyContactItem>
                        <Icon.MapPin size={20} />
                        {company.address}, {company.address_number} -
                        {company.neighborhood}, {company.city} - MT,{' '}
                        {company.cep}
                      </CompanyContactItem>

                      <CompanyContactItem>
                        <Icon.Phone size={20} />
                        {company.phone}
                      </CompanyContactItem>

                      {company.whatsapp && (
                        <CompanyContactItem>
                          <Icon.Smartphone size={20} />
                          {company.whatsapp}
                        </CompanyContactItem>
                      )}

                      <CompanyContactItem>
                        <Icon.Mail size={20} />
                        {company.email}
                      </CompanyContactItem>
                      <CompanyContactItem>
                        {company.social_whatsapp && (
                          <LinkSocial
                            href={`https://api.whatsapp.com/send?phone=55${company.social_whatsapp.replace(
                              /[^0-9]+/g,
                              ''
                            )}&text=Olá!`}
                            target="_blank"
                            style={styles}
                            title="WhatsApp"
                          >
                            <FaWhatsapp size={30} color="#0CBB1E" />
                          </LinkSocial>
                        )}

                        {company.social_facebook && (
                          <LinkSocial
                            href={company.social_facebook}
                            target="_blank"
                            style={styles}
                            title="Facebook"
                          >
                            <FaFacebook size={30} color="#072269" />
                          </LinkSocial>
                        )}

                        {company.social_instagram && (
                          <LinkSocial
                            href={company.social_instagram}
                            target="_blank"
                            style={styles}
                            title="Instagram"
                          >
                            <FaInstagram size={30} color="#EF3848" />
                          </LinkSocial>
                        )}
                        {company.web_site && (
                          <LinkSocial
                            href={`${company.web_site}`}
                            target="_blank"
                            style={styles}
                            title="Web Site"
                          >
                            <FaLink size={25} color="#0009" />
                          </LinkSocial>
                        )}
                      </CompanyContactItem>
                    </ul>
                  </CompanyContact>
                </Grid>
              </MaterialContainer>

              {company.banner_url && (
                <DivImg>
                  <Thumbnail src={company.banner_url} alt="Banner" />
                </DivImg>
              )}

              <TitleContainer>
                <Title>Produtos</Title>
                <Divider />
                <SearchInput
                  type="search"
                  name="search"
                  placeholder="Buscar"
                  value={search}
                  onChange={({ target }) => {
                    setSearch(target.value);
                  }}
                />
              </TitleContainer>

              <MaterialContainer>
                <Grid container spacing={5}>
                  {company.products.length ? (
                    company.products
                      .filter(item =>
                        search.length
                          ? item.description
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          : true
                      )
                      .map(product => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                          key={product.id}
                        >
                          <Product
                            product={{
                              id: product.id,
                              thumbnail: product.product_url,
                              thumbnails: product.thumbnails,
                              company_id: company.id,
                              name: product.description,
                              inCash: product.in_cash,
                              allow_sale: product.allow_sale,
                              previous_price: toDinheiro(product.value),
                              current_price:
                                product.coupons.length > 0
                                  ? toDinheiro(
                                      product.value - product.coupons[0].value
                                    )
                                  : toDinheiro(product.value),
                              disabled: !(product.coupons.length > 0),
                              cupons: product.coupons[0] ?? 'undefined',
                              couponId:
                                product.coupons.length > 0
                                  ? product.coupons[0].id
                                  : null,
                            }}
                          />
                        </Grid>
                      ))
                  ) : (
                    <EmptyImg src={ImgEmpty} />
                  )}
                </Grid>
              </MaterialContainer>
            </>
          )}
        </Container>
      )}
      {company.id && <SimpleFooter />}
    </>
  );
}
