import React from 'react';

import { Container, Title, Logo } from './styles';

import logo from '~/assets/images/bg_tecnobit.png';

export default function SimpleFooter() {
  return (
    <Container>
      <Title>Desenvolvido por</Title>
      <Logo src={logo} />
    </Container>
  );
}
