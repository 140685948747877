import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Form, Row, TextLink } from './styles';

import { Input } from '~/components';
import { signInRequest } from '~/store/modules/auth/actions';
import api from '~/services/api';
import { navigateTo } from '~/services/history';
import { cpfMask, telefoneMask } from '~/utils';

export default function Register({ setStep, onChangeModal }) {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit, formState, reset } = useForm();
  const { isSubmitting } = formState;

  const { profile } = useSelector(state => state.auth);

  const [maskCPF, setMaskCPF] = useState('');
  const [maskTelefone, setMaskTelefone] = useState('');

  const onSubmit = async ({ name, email, cpf, phone, password }) => {
    try {
      const response = await api.post('/api/register', {
        name,
        email,
        cpf,
        phone,
        password,
        profile_id: 3,
      });

      if (response.data && !profile) {
        dispatch(signInRequest(email, password));
      }

      reset();
      setMaskCPF('');
      setMaskTelefone('');

      navigateTo(document.location.pathname);

      toast.success('Registro criado, com sucesso!');
      onChangeModal(false);

      // eslint-disable-next-line no-empty
    } catch (error) {
      if (error.response) {
        const { errors: responseErrors } = error.response.data;

        if (responseErrors) {
          toast.error(
            responseErrors.cpf ? responseErrors.cpf[0] : responseErrors.email[0]
          );
        }
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        {/* Email */}
        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="Nome Completo"
          type="text"
          name="name"
          autoFocus
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
          withDefaultStyle
        />

        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="CPF"
          type="text"
          name="cpf"
          value={maskCPF}
          onChange={({ target }) => {
            setMaskCPF(cpfMask(target.value));
          }}
          error={Boolean(errors.cpf)}
          helperText={errors.name?.message}
          withDefaultStyle
        />

        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="Telefone"
          type="telefone"
          name="phone"
          value={maskTelefone}
          onChange={({ target }) => {
            setMaskTelefone(telefoneMask(target.value));
          }}
          error={Boolean(errors.phone)}
          helperText={errors.name?.message}
          withDefaultStyle
        />

        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="E-mail"
          type="email"
          name="email"
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          withDefaultStyle
        />

        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="Senha"
          type="password"
          name="password"
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          withDefaultStyle
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          disabled={isSubmitting}
        >
          Cadastrar
        </Button>

        <Row style={{ margin: 'auto', marginTop: 15 }}>
          <TextLink to="#" onClick={() => setStep(1)}>
            Já possui uma conta?
          </TextLink>
        </Row>
      </Grid>
    </Form>
  );
}

Register.propTypes = {
  setStep: PropTypes.func.isRequired,
  onChangeModal: PropTypes.func,
};

Register.defaultProps = {
  onChangeModal: () => {},
};
