import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Form, Row, TextLink } from '../styles';

// Components
import { Input } from '~/components';
// Services
import api from '~/services/api';

export default function ForgotPasswordForm({ setStep }) {
  const { register, errors, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const onSubmit = async ({ email }) => {
    const target = '/api/password/forgot';

    try {
      const response = await api.post(target, { email });

      if (response.status !== 200) {
        throw response;
      }

      toast.success(`Um link de recuperação foi enviado para ${email}.`);
    } catch {
      toast.error(
        'Não foi possível enviar um link de recuperação para o email informado.'
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        {/* Email */}
        <Input
          inputRef={register({ required: 'Este campo é obrigatório' })}
          label="E-mail"
          type="email"
          placeholder="exemplo@email.com.br"
          name="email"
          autoFocus
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          disabled={isSubmitting}
        >
          Continuar
        </Button>

        <Row style={{ margin: 'auto', marginTop: 15 }}>
          <TextLink to="#" onClick={() => setStep(1)}>
            Já possui uma conta?
          </TextLink>
        </Row>
      </Grid>
    </Form>
  );
}

ForgotPasswordForm.propTypes = {
  setStep: PropTypes.func.isRequired,
};
