import produce from 'immer';

import {
  FETCH_COUPONS_REQUESTED,
  FETCH_COUPONS_SUCCEDED,
  FETCH_COUPONS_FAILED,
  DELETE_COUPON,
  DELETE_COUPON_SUCCEDED,
  DELETE_COUPON_FAILED,
  DELETE_ALL_COUPONS,
  DELETE_ALL_COUPONS_SUCCEDED,
  DELETE_ALL_COUPONS_FAILED,
  CLEAR_COUPON,
} from './types';

const couponInitialState = {
  loading: false,
  error: false,
  coupons: {},
  submitted: false,
};

const Coupon = (state = couponInitialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_COUPONS_REQUESTED:
        draft.loading = true;
        draft.error = false;
        draft.coupons = {};
        break;
      case FETCH_COUPONS_SUCCEDED:
        draft.loading = false;
        draft.error = false;
        draft.coupons = action.payload.coupons;
        draft.submitted = false;
        break;
      case FETCH_COUPONS_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.coupons = {};
        break;
      case DELETE_COUPON:
        draft.loading = true;
        draft.error = false;
        draft.submitted = false;
        break;
      case DELETE_COUPON_SUCCEDED:
        draft.loading = false;
        draft.error = false;
        draft.submitted = action.payload.submitted;
        break;
      case DELETE_COUPON_FAILED:
        draft.loading = false;
        draft.coupons = state.coupons;
        draft.submitted = false;
        break;
      case DELETE_ALL_COUPONS:
        draft.loading = true;
        draft.error = false;
        draft.submitted = false;
        break;
      case DELETE_ALL_COUPONS_SUCCEDED:
        draft.loading = false;
        draft.error = false;
        draft.submitted = true;
        break;
      case DELETE_ALL_COUPONS_FAILED:
        draft.loading = false;
        draft.error = action.payload.error;
        draft.coupons = {};
        draft.submitted = false;
        break;
      case CLEAR_COUPON:
        draft.coupons = couponInitialState.coupon;
        break;
      default:
    }
  });
};

export default Coupon;
