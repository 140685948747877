import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ResourcesPageWithTable from '~/layouts/ResourcesPageWithTable';
import { fetchProfilesRequested } from '~/store/modules/profile/actions';
import { navigateTo } from '~/services/history';

export default function UsersList() {
  const dispatch = useDispatch();
  const { loading, error, profiles } = useSelector(state => state.profile);

  useEffect(() => {
    dispatch(fetchProfilesRequested());
  }, [dispatch]);

  const columns = [
    { title: 'Nome', field: 'name' },
    { title: 'Email', field: 'email' },
    { title: 'Empresa', field: 'company_name' },
    { title: 'Telefone', field: 'phone' },
    { title: 'Criado', field: 'created_at' },
  ];

  const actions = [
    {
      icon: 'edit',
      onClick: (event, user) => navigateTo(`/users/${user.id}/edit`),
    },
    {
      icon: 'arrow_right',
      onClick: (event, user) => navigateTo(`/users/${user.id}`),
    },
  ];

  return (
    <ResourcesPageWithTable
      title="Usuários"
      searchable
      header={{
        action: {
          label: 'Novo usuário',
          props: {
            onClick: () => navigateTo('/users/create'),
            style: {
              backgroundColor: '#5BB60A',
              color: 'white',
            },
          },
        },
      }}
      loading={loading}
      error={error}
      columns={columns}
      rows={profiles.data}
      actions={actions}
      pageSize={profiles.per_page}
      paginable={{
        page: profiles.current_page,
        lastPage: profiles.next_page_url,
        onPageChange: page => dispatch(fetchProfilesRequested(page)),
      }}
      onSearchChange={term =>
        dispatch(fetchProfilesRequested(profiles.current_page, term))
      }
    />
  );
}
