import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, InputAdornment, Button } from '@material-ui/core';
import { Delete, Search as SearchIcon } from 'react-feather';

import Colors from '~/themes/Colors';
import useDebounce from '~/hooks/useDebounce';

const useStyles = makeStyles({
  searchField: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
  },
});

export default function MaterialTableToolbar({ onSearchChange }) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  useEffect(() => {
    if (debouncedSearchTerm.length) {
      onSearchChange(debouncedSearchTerm);
    }
  }, [onSearchChange, debouncedSearchTerm]);

  return (
    <>
      <InputBase
        size="small"
        variant="outlined"
        placeholder="Pesquisar"
        className={classes.searchField}
        onChange={({ target }) => setSearchTerm(target.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon size={18} color="#8e8e8e" style={{ marginRight: 6 }} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="start">
            <Button disableElevation onClick={() => onSearchChange(searchTerm)}>
              <Delete size={22} color={Colors.primary.backgroundColor} />
            </Button>
          </InputAdornment>
        }
        value={searchTerm}
      />
    </>
  );
}

MaterialTableToolbar.propTypes = {
  onSearchChange: PropTypes.func,
};

MaterialTableToolbar.defaultProps = {
  onSearchChange: term => {},
};
